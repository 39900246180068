// src/components/smartFinder/core/UniversalFilterBar.tsx

import React, { useMemo } from 'react';
import { Select, Badge, SelectItem } from '@tremor/react';
import {
  UserGroupIcon,
  BuildingOfficeIcon,
  GlobeAltIcon,
  HashtagIcon,
  CalendarIcon,
  TagIcon
} from '@heroicons/react/24/outline';
import { UniversalFilters, FilterOption } from '../../../types/filters';

// Mock data - In real app, this would come from your data service
const mockFilterOptions = {
  accountManagers: [
    { value: 'john.doe', label: 'John Doe', group: 'Sales' },
    { value: 'jane.smith', label: 'Jane Smith', group: 'Operations' },
    { value: 'mike.jones', label: 'Mike Jones', group: 'Strategic' }
  ],
  verticals: [
    { value: 'automotive', label: 'Automotive' },
    { value: 'retail', label: 'Retail' },
    { value: 'finance', label: 'Finance' },
    { value: 'technology', label: 'Technology' }
  ],
  agencies: {
    holdingCompanies: [
      { value: 'wpp', label: 'WPP' },
      { value: 'omnicom', label: 'Omnicom' },
      { value: 'publicis', label: 'Publicis' }
    ],
    agencyGroups: [
      { value: 'mindshare', label: 'Mindshare', holdingCompany: 'wpp' },
      { value: 'mediacom', label: 'MediaCom', holdingCompany: 'wpp' },
      { value: 'phd', label: 'PHD', holdingCompany: 'omnicom' }
    ]
  },
  regions: [
    { value: 'na', label: 'North America' },
    { value: 'emea', label: 'EMEA' },
    { value: 'apac', label: 'APAC' }
  ],
  quarters: [
    { value: 'Q1-2024', label: 'Q1 2024' },
    { value: 'Q2-2024', label: 'Q2 2024' },
    { value: 'Q3-2024', label: 'Q3 2024' },
    { value: 'Q4-2024', label: 'Q4 2024' }
  ],
  status: [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' }
  ]
};

interface UniversalFilterBarProps {
  filters: UniversalFilters;
  onFilterChange: (filters: UniversalFilters) => void;
  className?: string;
}

export function UniversalFilterBar({
  filters,
  onFilterChange,
  className = ''
}: UniversalFilterBarProps) {
  // Filtered agency groups based on selected holding company
  const availableAgencyGroups = useMemo(() => {
    if (!filters.agency.holdingCompany) return mockFilterOptions.agencies.agencyGroups;
    return mockFilterOptions.agencies.agencyGroups.filter(
      group => group.holdingCompany === filters.agency.holdingCompany
    );
  }, [filters.agency.holdingCompany]);

  // Count active filters
  const activeFilterCount = useMemo(() => {
    let count = 0;
    if (filters.accountManager.length) count += 1;
    if (filters.vertical.length) count += 1;
    if (filters.agency.holdingCompany) count += 1;
    if (filters.agency.agencyGroup) count += 1;
    if (filters.region.length) count += 1;
    if (filters.opportunityId) count += 1;
    if (filters.quarterYear) count += 1;
    if (filters.status.length) count += 1;
    return count;
  }, [filters]);

  return (
    <div className={`p-4 bg-white border-b ${className}`}>
      <div className="flex flex-wrap gap-4">
        {/* Account Manager Select */}
        <div className="w-64">
          <div className="flex items-center space-x-2 mb-1">
            <UserGroupIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Account Manager</span>
          </div>
          <Select
            placeholder="Select managers..."
            value={filters.accountManager.join(',')}
            onValueChange={(value: string) => {
                const newValue = value.split(',');
                onFilterChange({ ...filters, accountManager: newValue });
            }}
            enableClear
            className="mt-1"
            >

            {mockFilterOptions.accountManagers.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
                <span className="text-xs text-gray-500 ml-2">{option.group}</span>
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Vertical Select */}
        <div className="w-64">
          <div className="flex items-center space-x-2 mb-1">
            <BuildingOfficeIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Vertical</span>
          </div>
          <Select
            placeholder="Select verticals..."
            value={filters.vertical.join(',')}
            onValueChange={(value: string) => {
              const newValue = value ? value.split(',') : [];
              onFilterChange({ ...filters, vertical: newValue });
            }}
            enableClear
          >
            {mockFilterOptions.verticals.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Holding Company Select */}
        <div className="w-64">
          <div className="flex items-center space-x-2 mb-1">
            <TagIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Holding Company</span>
          </div>
          <Select
            placeholder="Select company..."
            value={filters.agency.holdingCompany}
            onValueChange={(value: string) => 
              onFilterChange({
                ...filters,
                agency: {
                  ...filters.agency,
                  holdingCompany: value,
                  agencyGroup: undefined // Reset child selection
                }
              })}
            enableClear
          >
            {mockFilterOptions.agencies.holdingCompanies.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Agency Group Select */}
        <div className="w-64">
          <div className="flex items-center space-x-2 mb-1">
            <BuildingOfficeIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Agency</span>
          </div>
          <Select
            placeholder="Select agency..."
            value={filters.agency.agencyGroup}
            onValueChange={(value: string) => 
              onFilterChange({
                ...filters,
                agency: {
                  ...filters.agency,
                  agencyGroup: value
                }
              })}
            enableClear
            disabled={!filters.agency.holdingCompany}
          >
            {availableAgencyGroups.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Region Select */}
        <div className="w-64">
          <div className="flex items-center space-x-2 mb-1">
            <GlobeAltIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Region</span>
          </div>
          <Select
            placeholder="Select regions..."
            value={filters.region.join(',')}
            onValueChange={(value: string) => {
              const newValue = value ? value.split(',') : [];
              onFilterChange({ ...filters, region: newValue });
            }}
            enableClear
          >
            {mockFilterOptions.regions.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>

        {/* Opportunity ID Input */}
        <div className="w-48">
          <div className="flex items-center space-x-2 mb-1">
            <HashtagIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Opportunity ID</span>
          </div>
          <input
            type="text"
            placeholder="Enter ID..."
            className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500"
            value={filters.opportunityId || ''}
            onChange={(e) => 
              onFilterChange({ ...filters, opportunityId: e.target.value })}
          />
        </div>

        {/* Quarter/Year Select */}
        <div className="w-48">
          <div className="flex items-center space-x-2 mb-1">
            <CalendarIcon className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">Quarter</span>
          </div>
          <Select
            placeholder="Select quarter..."
            value={filters.quarterYear}
            onValueChange={(value: string) => 
              onFilterChange({ ...filters, quarterYear: value })}
            enableClear
          >
            {mockFilterOptions.quarters.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>

      {/* Active Filter Count */}
      {activeFilterCount > 0 && (
        <div className="mt-2">
          <Badge color="blue">
            {activeFilterCount} active filter{activeFilterCount !== 1 ? 's' : ''}
          </Badge>
        </div>
      )}
    </div>
  );
}