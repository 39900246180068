// src/components/smartFinder/filters/ColumnCustomizer.tsx

import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ViewColumnsIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { Text, Badge } from '@tremor/react';
import { 
    DragDropContext, 
    Droppable, 
    Draggable,
    DroppableProvided,
    DroppableStateSnapshot,
    DraggableProvided,
    DraggableStateSnapshot
  } from 'react-beautiful-dnd';
import { EntityType } from '../../../types/smartFinder';

interface Column {
  id: string;
  label: string;
  required?: boolean;
  group?: string;
  sortable?: boolean;
  filterable?: boolean;
  width?: number;
}

interface ColumnCustomizerProps {
  columns: Column[];
  visibleColumns: Set<string>;
  onChange: (columns: Set<string>) => void;
  entityType: EntityType;
  onReorder?: (columnIds: string[]) => void; // Make onReorder optional
}

// interface ColumnCustomizerProps {
//   columns: Column[];
//   visibleColumns: Set<string>;
//   onChange: (columns: Set<string>) => void;
//   onReorder?: (columnIds: string[]) => void;
//   entityType: 'deals' | 'audiences' | 'domains';
// }

const presetLayouts = {
  deals: {
    compact: ['name', 'advertiser', 'budget', 'status'],
    performance: ['name', 'performance', 'reach', 'viewability'],
    complete: ['name', 'advertiser', 'type', 'budget', 'performance', 'reach', 'status', 'days']
  },
  audiences: {
    compact: ['name', 'type', 'size', 'match_rate'],
    performance: ['name', 'performance', 'coverage', 'match_rate'],
    complete: ['name', 'type', 'size', 'coverage', 'performance', 'match_rate']
  },
  domains: {
    compact: ['url', 'publisher', 'quality'],
    performance: ['url', 'quality', 'reach', 'viewability'],
    complete: ['url', 'publisher', 'categories', 'quality', 'reach', 'formats', 'price']
  }
};

export function ColumnCustomizer({
  columns,
  visibleColumns,
  onChange,
  onReorder,
  entityType
}: ColumnCustomizerProps) {
  const [orderedColumns, setOrderedColumns] = useState(columns);
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);

  const handlePresetSelect = (preset: string) => {
    setSelectedPreset(preset);
    onChange(new Set(presetLayouts[entityType][preset as keyof typeof presetLayouts[typeof entityType]]));
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(orderedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedColumns(items);
    onReorder?.(items.map(col => col.id));
  };

  return (
    <Popover className="relative">
      <Popover.Button as={Fragment}>
        <Button variant="ghost" size="sm">
          <ViewColumnsIcon className="h-4 w-4 mr-1" />
          Columns
          <Badge color="gray" className="ml-2">
            {visibleColumns.size}
          </Badge>
        </Button>
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-2 w-screen max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <Text className="font-medium">Customize Columns</Text>
              <div className="flex space-x-2">
                {Object.keys(presetLayouts[entityType]).map(preset => (
                  <Button 
                    key={preset}
                    variant={selectedPreset === preset ? 'primary' : 'secondary'}
                    size="sm"
                    onClick={() => handlePresetSelect(preset)}
                  >
                    {preset.charAt(0).toUpperCase() + preset.slice(1)}
                  </Button>
                ))}
              </div>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="columns">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="space-y-2"
            >
            {orderedColumns.map((column, index) => (
                <Draggable
                key={column.id}
                draggableId={column.id}
                index={index}
                isDragDisabled={column.required}
                >
                {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                    <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    className={`
                        flex items-center justify-between p-2 rounded-lg
                        ${dragSnapshot.isDragging ? 'bg-blue-50' : 'hover:bg-gray-50'}
                        ${column.required ? 'opacity-50' : ''}
                    `}
                    >
                            <div className="flex items-center space-x-3">
                              <div {...dragProvided.dragHandleProps}>
                                <ArrowsUpDownIcon className="h-4 w-4 text-gray-400" />
                              </div>
                              <div>
                                <Text>{column.label}</Text>
                                {column.group && (
                                  <Text className="text-xs text-gray-500">
                                    {column.group}
                                  </Text>
                                )}
                              </div>
                            </div>
                            <input
                              type="checkbox"
                              className="rounded border-gray-300"
                              checked={visibleColumns.has(column.id)}
                              onChange={() => {
                                const newColumns = new Set(visibleColumns);
                                if (newColumns.has(column.id)) {
                                  newColumns.delete(column.id);
                                } else {
                                  newColumns.add(column.id);
                                }
                                onChange(newColumns);
                              }}
                              disabled={column.required}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}