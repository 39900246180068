// src/components/deals/management/filters/HierarchyFilter.tsx

import { useState } from 'react';
import { Card, Select, SelectItem, MultiSelect, MultiSelectItem, Text } from '@tremor/react';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

interface FilterOption {
  id: string;
  label: string;
  count?: number;
  children?: FilterOption[];
}

// Sample organizational hierarchy
const organizationalFilters: FilterOption[] = [
  {
    id: 'account_manager',
    label: 'Account Manager',
    children: [
      { id: 'am1', label: 'John Smith', count: 24 },
      { id: 'am2', label: 'Sarah Johnson', count: 18 },
      { id: 'am3', label: 'Mike Chen', count: 32 }
    ]
  },
  {
    id: 'agency',
    label: 'Agency',
    children: [
      { id: 'agency1', label: 'MediaCom', count: 45 },
      { id: 'agency2', label: 'Mindshare', count: 38 },
      { id: 'agency3', label: 'GroupM', count: 42 }
    ]
  },
  {
    id: 'brand',
    label: 'Brand',
    children: [
      { id: 'brand1', label: 'AutoMax Corp', count: 12 },
      { id: 'brand2', label: 'TechGiant Inc', count: 8 },
      { id: 'brand3', label: 'RetailPro', count: 15 }
    ]
  }
];

const metricFilters: FilterOption[] = [
  {
    id: 'health',
    label: 'Health Score',
    children: [
      { id: 'critical', label: 'Critical (<60)', count: 15 },
      { id: 'warning', label: 'Warning (60-79)', count: 28 },
      { id: 'healthy', label: 'Healthy (80+)', count: 42 }
    ]
  },
  {
    id: 'performance',
    label: 'Performance',
    children: [
      { id: 'above_target', label: 'Above Target', count: 35 },
      { id: 'at_target', label: 'At Target', count: 25 },
      { id: 'below_target', label: 'Below Target', count: 18 }
    ]
  },
  {
    id: 'optimization',
    label: 'Optimization Status',
    children: [
      { id: 'needs_opt', label: 'Needs Optimization', count: 12 },
      { id: 'in_progress', label: 'Optimization In Progress', count: 8 },
      { id: 'recently_opt', label: 'Recently Optimized', count: 22 }
    ]
  }
];

const quickFilters = [
  { id: 'my_accounts', label: 'My Accounts', count: 45 },
  { id: 'needs_attention', label: 'Needs Attention', count: 12 },
  { id: 'recent_changes', label: 'Recent Changes', count: 8 },
  { id: 'pending_review', label: 'Pending Review', count: 15 }
];

export function HierarchyFilter() {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});
  const [activeQuickFilter, setActiveQuickFilter] = useState<string | null>(null);

  const handleFilterChange = (category: string, values: string[]) => {
    setSelectedFilters(prev => ({
      ...prev,
      [category]: values
    }));
  };

  const clearFilters = () => {
    setSelectedFilters({});
    setActiveQuickFilter(null);
  };

  return (
    <Card className="p-4">
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <FunnelIcon className="h-5 w-5 text-gray-500" />
            <Text className="font-medium">Filters</Text>
          </div>
          {(Object.keys(selectedFilters).length > 0 || activeQuickFilter) && (
            <Button
              variant="ghost"
              size="sm"
              onClick={clearFilters}
            >
              Clear All
              <XMarkIcon className="h-4 w-4 ml-1" />
            </Button>
          )}
        </div>

        {/* Quick Filters */}
        <div className="space-y-2">
          <Text className="text-sm text-gray-600">Quick Filters</Text>
          <div className="flex flex-wrap gap-2">
            {quickFilters.map(filter => (
              <Button
                key={filter.id}
                variant={activeQuickFilter === filter.id ? 'primary' : 'secondary'}
                size="sm"
                onClick={() => setActiveQuickFilter(
                  activeQuickFilter === filter.id ? null : filter.id
                )}
                className="flex items-center justify-between"
              >
                <span>{filter.label}</span>
                <span className="ml-2 bg-white/20 px-2 py-0.5 rounded-full text-xs">
                  {filter.count}
                </span>
              </Button>
            ))}
          </div>
        </div>

        {/* Organizational Hierarchy */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {organizationalFilters.map(category => (
            <div key={category.id}>
              <Text className="text-sm text-gray-600 mb-2">{category.label}</Text>
              <MultiSelect
                value={selectedFilters[category.id] || []}
                onValueChange={values => handleFilterChange(category.id, values)}
                placeholder={`Select ${category.label}`}
              >
                {category.children?.map(option => (
                  <MultiSelectItem key={option.id} value={option.id}>
                    <div className="flex items-center justify-between">
                      <span>{option.label}</span>
                      <span className="text-gray-500 text-sm">({option.count})</span>
                    </div>
                  </MultiSelectItem>
                ))}
              </MultiSelect>
            </div>
          ))}
        </div>

        {/* Metric Filters */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {metricFilters.map(category => (
            <div key={category.id}>
              <Text className="text-sm text-gray-600 mb-2">{category.label}</Text>
              <MultiSelect
                value={selectedFilters[category.id] || []}
                onValueChange={values => handleFilterChange(category.id, values)}
                placeholder={`Select ${category.label}`}
              >
                {category.children?.map(option => (
                  <MultiSelectItem key={option.id} value={option.id}>
                    <div className="flex items-center justify-between">
                      <span>{option.label}</span>
                      <span className="text-gray-500 text-sm">({option.count})</span>
                    </div>
                  </MultiSelectItem>
                ))}
              </MultiSelect>
            </div>
          ))}
        </div>

        {/* Applied Filters */}
        {(Object.keys(selectedFilters).length > 0 || activeQuickFilter) && (
          <div className="pt-4 border-t">
            <Text className="text-sm text-gray-600 mb-2">Applied Filters</Text>
            <div className="flex flex-wrap gap-2">
              {Object.entries(selectedFilters).map(([category, values]) => (
                values.map(value => (
                  <Button
                    key={`${category}-${value}`}
                    variant="secondary"
                    size="sm"
                    onClick={() => handleFilterChange(
                      category,
                      selectedFilters[category].filter(v => v !== value)
                    )}
                  >
                    {value}
                    <XMarkIcon className="h-4 w-4 ml-1" />
                  </Button>
                ))
              ))}
              {activeQuickFilter && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setActiveQuickFilter(null)}
                >
                  {quickFilters.find(f => f.id === activeQuickFilter)?.label}
                  <XMarkIcon className="h-4 w-4 ml-1" />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}