// src/components/deals/management/alerts/HealthChecks.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  ProgressBar,
  Select,
  SelectItem,
  DonutChart
} from '@tremor/react';
import {
  ShieldCheckIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  BoltIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

interface HealthCheck {
  id: string;
  name: string;
  category: 'deal' | 'system' | 'integration';
  status: 'passed' | 'failed' | 'pending' | 'warning';
  lastRun: string;
  nextRun: string;
  metrics: {
    name: string;
    value: number;
    threshold: number;
    status: 'success' | 'warning' | 'error';
  }[];
  dependencies?: string[];
  duration?: number;
}

const healthChecks: HealthCheck[] = [
  {
    id: 'HC001',
    name: 'Deal Setup Validation',
    category: 'deal',
    status: 'passed',
    lastRun: '2024-03-26T09:00:00Z',
    nextRun: '2024-03-26T21:00:00Z',
    metrics: [
      {
        name: 'Configuration Accuracy',
        value: 98,
        threshold: 95,
        status: 'success'
      },
      {
        name: 'Response Time',
        value: 45,
        threshold: 50,
        status: 'success'
      }
    ],
    duration: 120 // seconds
  },
  {
    id: 'HC002',
    name: 'DSP Integration',
    category: 'integration',
    status: 'warning',
    lastRun: '2024-03-26T08:30:00Z',
    nextRun: '2024-03-26T20:30:00Z',
    metrics: [
      {
        name: 'Connection Status',
        value: 85,
        threshold: 98,
        status: 'warning'
      },
      {
        name: 'Data Sync',
        value: 92,
        threshold: 95,
        status: 'warning'
      }
    ],
    dependencies: ['System Health'],
    duration: 180
  }
];

export function HealthChecks() {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [runningChecks, setRunningChecks] = useState<string[]>([]);

  const triggerHealthCheck = async (checkId: string) => {
    setRunningChecks(prev => [...prev, checkId]);
    // Simulate health check running
    await new Promise(resolve => setTimeout(resolve, 2000));
    setRunningChecks(prev => prev.filter(id => id !== checkId));
  };

  const HealthCheckCard = ({ check }: { check: HealthCheck }) => (
    <Card className={`${
      check.status === 'failed' ? 'border-red-200' :
      check.status === 'warning' ? 'border-yellow-200' :
      check.status === 'passed' ? 'border-green-200' :
      'border-gray-200'
    }`}>
      <div className="p-4">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center space-x-2">
              <ShieldCheckIcon className={`h-5 w-5 ${
                check.status === 'failed' ? 'text-red-500' :
                check.status === 'warning' ? 'text-yellow-500' :
                check.status === 'passed' ? 'text-green-500' :
                'text-gray-500'
              }`} />
              <Text className="font-medium">{check.name}</Text>
            </div>
            <div className="flex items-center space-x-2 mt-1">
              <Badge color="gray">{check.category}</Badge>
              <Text className="text-sm text-gray-500">
                Last run: {new Date(check.lastRun).toLocaleTimeString()}
              </Text>
            </div>
          </div>
          <Badge color={
            check.status === 'failed' ? 'red' :
            check.status === 'warning' ? 'yellow' :
            check.status === 'passed' ? 'green' :
            'gray'
          }>
            {check.status}
          </Badge>
        </div>

        {/* Metrics */}
        <div className="mt-4 space-y-3">
          {check.metrics.map((metric, index) => (
            <div key={index} className="space-y-1">
              <div className="flex justify-between text-sm">
                <Text>{metric.name}</Text>
                <div className="flex items-center space-x-2">
                  <Text>{metric.value}%</Text>
                  <Text className="text-gray-500">
                    vs {metric.threshold}%
                  </Text>
                </div>
              </div>
              <ProgressBar
                value={metric.value}
                color={
                  metric.status === 'success' ? 'green' :
                  metric.status === 'warning' ? 'yellow' :
                  'red'
                }
              />
            </div>
          ))}
        </div>

        {/* Dependencies */}
        {check.dependencies && (
          <div className="mt-4">
            <Text className="text-sm text-gray-600">Dependencies</Text>
            <div className="flex flex-wrap gap-2 mt-1">
              {check.dependencies.map(dep => (
                <Badge key={dep} color="blue">
                  {dep}
                </Badge>
              ))}
            </div>
          </div>
        )}

        {/* Actions */}
        <div className="mt-4 flex justify-between items-center">
          <Text className="text-sm text-gray-500">
            Next run in: {new Date(check.nextRun).toLocaleTimeString()}
          </Text>
          <div className="flex space-x-2">
            <Button 
              variant="secondary" 
              size="sm"
              onClick={() => triggerHealthCheck(check.id)}
              disabled={runningChecks.includes(check.id)}
            >
              {runningChecks.includes(check.id) ? (
                <>
                  <ArrowPathIcon className="h-4 w-4 mr-1 animate-spin" />
                  Running...
                </>
              ) : (
                <>
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Run Check
                </>
              )}
            </Button>
            <Button variant="primary" size="sm">
              View Details
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="space-y-6">
      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {[
          { label: 'Total Checks', value: 12, icon: ShieldCheckIcon, color: 'blue' },
          { label: 'Passed', value: 8, icon: CheckCircleIcon, color: 'green' },
          { label: 'Warnings', value: 3, icon: ExclamationTriangleIcon, color: 'yellow' },
          { label: 'Failed', value: 1, icon: BoltIcon, color: 'red' }
        ].map((stat) => (
          <Card key={stat.label}>
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <stat.icon className={`h-5 w-5 text-${stat.color}-500`} />
                  <Text>{stat.label}</Text>
                </div>
                <Text className="text-2xl font-bold">{stat.value}</Text>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Controls */}
      <div className="flex justify-between items-center">
        <Select 
          value={selectedCategory} 
          onValueChange={setSelectedCategory}
          className="w-48"
        >
          <SelectItem value="all">All Categories</SelectItem>
          <SelectItem value="deal">Deal Checks</SelectItem>
          <SelectItem value="system">System Checks</SelectItem>
          <SelectItem value="integration">Integration Checks</SelectItem>
        </Select>

        <Button variant="primary">
          Configure Health Checks
        </Button>
      </div>

      {/* Health Checks Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {healthChecks
          .filter(check => selectedCategory === 'all' || check.category === selectedCategory)
          .map(check => (
            <HealthCheckCard key={check.id} check={check} />
          ))}
      </div>
    </div>
  );
}