// src/components/deals/management/roles/RoleBasedViews.tsx

import { useState, useEffect } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  TabGroup,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  AreaChart,
  DonutChart
} from '@tremor/react';
import {
  UsersIcon,
  WrenchScrewdriverIcon,
  UserGroupIcon,
  ChartBarIcon,
  SparklesIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { useAlertMonitoring } from '../../../../hooks/useAlertMonitoring';
import { Button } from '../../../ui/Button';

// Define role-specific interfaces


function DealOpsView() {
  const { loading, error, createAlert, runHealthCheck } = useAlertMonitoring({
    autoRefresh: true,
    refreshInterval: 30000
  });

  const [metrics, setMetrics] = useState({
    activeOptimizations: 12,
    pendingSetups: 5,
    avgResponseTime: 4.2,
    healthScore: 88
  });

  return (
    <div className="space-y-6">
      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <SparklesIcon className="h-5 w-5 mr-2" />
            Batch Optimize
          </span>
          <Badge color="blue">8 deals</Badge>
        </Button>
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <ChartBarIcon className="h-5 w-5 mr-2" />
            Health Check
          </span>
          <Badge color="yellow">5 pending</Badge>
        </Button>
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <ClockIcon className="h-5 w-5 mr-2" />
            Setup Review
          </span>
          <Badge color="green">3 new</Badge>
        </Button>
      </div>

      {/* Performance Overview */}
      <Card>
        <div className="p-4">
          <Title>Performance Overview</Title>
          <div className="mt-4 grid grid-cols-2 gap-6">
            <div>
              <Text className="text-gray-600">Active Optimizations</Text>
              <div className="mt-2 flex items-baseline space-x-2">
                <span className="text-2xl font-bold">{metrics.activeOptimizations}</span>
                <Badge color="green">+2 today</Badge>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">Pending Setups</Text>
              <div className="mt-2 flex items-baseline space-x-2">
                <span className="text-2xl font-bold">{metrics.pendingSetups}</span>
                <Badge color="yellow">Need review</Badge>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* AI Recommendations */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="p-4">
          <div className="flex items-start space-x-3">
            <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
            <div>
              <Title className="text-blue-800">Optimization Opportunities</Title>
              <Text className="text-blue-700 mt-1">
                8 deals eligible for batch optimization based on similar patterns
              </Text>
              <div className="mt-4 flex space-x-4">
                <Button variant="secondary" size="sm">
                  View Details
                </Button>
                <Button variant="primary" size="sm">
                  Start Optimization
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

function AccountManagerView() {
  const [clientMetrics, setClientMetrics] = useState({
    activeDeals: 24,
    totalRevenue: 1500000,
    qbrsDue: 3,
    clientSatisfaction: 4.5
  });

  return (
    <div className="space-y-6">
      {/* Client Overview */}
      <Card>
        <div className="p-4">
          <Title>Client Overview</Title>
          <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <Text className="text-gray-600">Active Deals</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{clientMetrics.activeDeals}</span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">Total Revenue</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">
                  ${(clientMetrics.totalRevenue / 1000000).toFixed(1)}M
                </span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">QBRs Due</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{clientMetrics.qbrsDue}</span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">Client Satisfaction</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{clientMetrics.clientSatisfaction}</span>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <ChartBarIcon className="h-5 w-5 mr-2" />
            Prepare QBR
          </span>
          <Badge color="yellow">3 due</Badge>
        </Button>
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <UserGroupIcon className="h-5 w-5 mr-2" />
            Client Reviews
          </span>
          <Badge color="blue">2 pending</Badge>
        </Button>
        <Button variant="secondary" className="justify-between">
          <span className="flex items-center">
            <SparklesIcon className="h-5 w-5 mr-2" />
            Opportunities
          </span>
          <Badge color="green">5 new</Badge>
        </Button>
      </div>
    </div>
  );
}

function SupportView() {
  const [systemMetrics, setSystemMetrics] = useState({
    integrationHealth: 92,
    activeIssues: 5,
    avgResolutionTime: 3.5,
    systemUptime: 99.9
  });

  return (
    <div className="space-y-6">
      {/* System Health */}
      <Card>
        <div className="p-4">
          <Title>System Health</Title>
          <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <Text className="text-gray-600">Integration Health</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{systemMetrics.integrationHealth}%</span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">Active Issues</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{systemMetrics.activeIssues}</span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">Avg Resolution Time</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{systemMetrics.avgResolutionTime}h</span>
              </div>
            </div>
            <div>
              <Text className="text-gray-600">System Uptime</Text>
              <div className="mt-2">
                <span className="text-2xl font-bold">{systemMetrics.systemUptime}%</span>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Active Issues */}
      <Card>
        <div className="p-4">
          <Title>Active Issues</Title>
          <div className="mt-4 space-y-4">
            {/* Add issue cards */}
          </div>
        </div>
      </Card>
    </div>
  );
}

export function RoleBasedContainer() {
  const [currentRole, setCurrentRole] = useState('dealOps');

  return (
    <div className="space-y-6">
      {/* Role Selector */}
      <Card>
        <div className="p-4">
          <TabGroup>
            <TabList>
              <Tab>Deal Operations</Tab>
              <Tab>Account Management</Tab>
              <Tab>Technical Support</Tab>
            </TabList>
          </TabGroup>
        </div>
      </Card>

      {/* Role-Specific View */}
      {currentRole === 'dealOps' && <DealOpsView />}
      {currentRole === 'accountManager' && <AccountManagerView />}
      {currentRole === 'support' && <SupportView />}
    </div>
  );
}