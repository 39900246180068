// src/components/deals/management/views/DealOpsView.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  ProgressBar,
  AreaChart,
  DonutChart,
  Grid,
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel
} from '@tremor/react';
import {
  SparklesIcon,
  ShieldCheckIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample data
const dealOpsData = {
  discardRate: {
    current: 18,
    target: 25,
    trend: -2,
    byPriority: [
      { type: 'Critical', rate: 32, deals: 5 },
      { type: 'Warning', rate: 28, deals: 8 },
      { type: 'Healthy', rate: 15, deals: 32 }
    ],
    history: [
      { date: '2024-01', rate: 22 },
      { date: '2024-02', rate: 20 },
      { date: '2024-03', rate: 18 }
    ]
  },
  healthMetrics: {
    overall: 84,
    byComponent: {
      bidRequests: 92,
      bidResponses: 88,
      winRate: 76,
      delivery: 82
    }
  },
  priorities: [
    {
      id: 'P1',
      title: 'High Discard Rate',
      count: 5,
      impact: 'high',
      action: 'Review bid setup'
    },
    {
      id: 'P2',
      title: 'Setup Validation',
      count: 3,
      impact: 'medium',
      action: 'Validate DSP setup'
    }
  ],
  performance: {
    optimization: {
      active: 12,
      completed: 45,
      success: 92
    },
    setupAccuracy: 94,
    responseTime: 4.2
  }
};

export function DealOpsView() {
  return (
    <div className="space-y-6">
      {/* Top Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card>
          <div className="p-4">
            <Text>Discard Rate</Text>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between">
                <Text className="text-2xl font-bold">{dealOpsData.discardRate.current}%</Text>
                <Badge color={dealOpsData.discardRate.current > dealOpsData.discardRate.target ? 'red' : 'green'}>
                  Target: {dealOpsData.discardRate.target}%
                </Badge>
              </div>
              <ProgressBar 
                value={(dealOpsData.discardRate.current / dealOpsData.discardRate.target) * 100}
                color={dealOpsData.discardRate.current > dealOpsData.discardRate.target ? 'red' : 'green'}
              />
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <Text>Health Score</Text>
            <div className="mt-2">
              <Text className="text-2xl font-bold">{dealOpsData.healthMetrics.overall}</Text>
              <Badge color="green">+2 pts</Badge>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <Text>Active Optimizations</Text>
            <div className="mt-2">
              <Text className="text-2xl font-bold">{dealOpsData.performance.optimization.active}</Text>
              <Badge color="blue">Running</Badge>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <Text>Setup Accuracy</Text>
            <div className="mt-2">
              <Text className="text-2xl font-bold">{dealOpsData.performance.setupAccuracy}%</Text>
              <Badge color="green">Above Target</Badge>
            </div>
          </div>
        </Card>
      </div>

      <TabGroup>
        <TabList>
          <Tab>Health & Discard</Tab>
          <Tab>Optimizations</Tab>
          <Tab>Setup & Configuration</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <div className="mt-4 grid grid-cols-2 gap-6">
              {/* Discard Rate Analysis */}
              <Card>
                <div className="p-4">
                  <Title>Discard Rate Trends</Title>
                  <AreaChart
                    className="mt-4 h-48"
                    data={dealOpsData.discardRate.history}
                    index="date"
                    categories={['rate']}
                    colors={['blue']}
                  />
                </div>
              </Card>

              {/* Health Components */}
              <Card>
                <div className="p-4">
                  <Title>Health Components</Title>
                  <div className="mt-4 space-y-3">
                    {Object.entries(dealOpsData.healthMetrics.byComponent).map(([key, value]) => (
                      <div key={key}>
                        <div className="flex justify-between mb-1">
                          <Text>{key}</Text>
                          <Text>{value}%</Text>
                        </div>
                        <ProgressBar
                          value={value}
                          color={value > 85 ? 'green' : value > 70 ? 'yellow' : 'red'}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>

          <TabPanel>
            {/* Optimization Content */}
          </TabPanel>

          <TabPanel>
            {/* Setup Content */}
          </TabPanel>
        </TabPanels>
      </TabGroup>

      {/* Priority Actions */}
      <Card>
        <div className="p-4">
          <Title>Priority Actions</Title>
          <div className="mt-4 space-y-4">
            {dealOpsData.priorities.map(priority => (
              <Card key={priority.id}>
                <div className="p-4 flex items-center justify-between">
                  <div>
                    <div className="flex items-center space-x-2">
                      <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
                      <Text className="font-medium">{priority.title}</Text>
                      <Badge color={priority.impact === 'high' ? 'red' : 'yellow'}>
                        {priority.count} deals
                      </Badge>
                    </div>
                    <Text className="mt-1 text-gray-600">{priority.action}</Text>
                  </div>
                  <Button variant="secondary" size="sm">
                    Take Action
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}
