// src/components/deals/management/views/BusinessView.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  AreaChart,
  DonutChart,
  Grid
} from '@tremor/react';
import {
  BuildingOfficeIcon,
  ChartBarIcon,
  SparklesIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample business metrics data
const businessMetrics = {
  dealHealth: {
    score: 82,
    trend: 3,
    distribution: {
      healthy: 65,
      warning: 25,
      critical: 10
    },
    changeLog: [
      { date: '2024-01', value: 78 },
      { date: '2024-02', value: 80 },
      { date: '2024-03', value: 82 }
    ]
  },
  dealDistribution: {
    byTier: {
      premium: { count: 156, revenue: 2500000 },
      standard: { count: 245, revenue: 1800000 },
      basic: { count: 89, revenue: 500000 }
    },
    byStatus: {
      active: 328,
      paused: 85,
      pending: 45,
      completed: 32
    }
  },
  performance: {
    revenueImpact: 2450000,
    optimizationSuccess: 92,
    avgResponseTime: 4.2,
    trendsData: [
      {
        date: '2024-01',
        'Revenue Impact': 2100000,
        'Deal Health': 78,
        'Optimization Rate': 88
      },
      {
        date: '2024-02',
        'Revenue Impact': 2300000,
        'Deal Health': 80,
        'Optimization Rate': 90
      },
      {
        date: '2024-03',
        'Revenue Impact': 2450000,
        'Deal Health': 82,
        'Optimization Rate': 92
      }
    ]
  },
  alerts: [
    {
      id: 'ALT-001',
      type: 'Health',
      message: 'Critical health issues detected',
      count: 5,
      severity: 'critical',
      recommendation: 'Review health metrics for Premium tier deals'
    },
    {
      id: 'ALT-002',
      type: 'Performance',
      message: 'Optimization opportunities identified',
      count: 12,
      severity: 'warning',
      recommendation: 'Batch optimize automotive vertical deals'
    },
    {
      id: 'ALT-003',
      type: 'Setup',
      message: 'New deals pending review',
      count: 8,
      severity: 'info',
      recommendation: 'Validate setup for recent launches'
    }
  ]
};

export function BusinessView() {
  const [selectedMetric, setSelectedMetric] = useState('revenue');

  return (
    <div className="space-y-6">
      {/* AI Insights Banner */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50 border-blue-100">
        <div className="flex items-start space-x-4">
          <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
          <div>
            <Title className="text-blue-800">AI-Powered Insights</Title>
            <Text className="text-blue-700 mt-1">
              {businessMetrics.dealHealth.distribution.critical > 15 
                ? "High number of critical deals detected. Recommended: Batch health check for affected accounts."
                : "Deal health improving across portfolio. Consider expanding optimization to warning-state deals."}
            </Text>
            <div className="mt-4 flex space-x-4">
              <Button variant="primary" size="sm">
                Run Analysis
              </Button>
              <Button variant="secondary" size="sm">
                View Recommendations
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Key Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Health Score */}
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Overall Health</Text>
              <Metric>{businessMetrics.dealHealth.score}</Metric>
            </div>
            <Badge 
              color="green"
              icon={ArrowTrendingUpIcon}
            >
              +{businessMetrics.dealHealth.trend}
            </Badge>
          </div>
          <ProgressBar 
            value={businessMetrics.dealHealth.score} 
            color="blue"
            className="mt-3"
          />
          <div className="mt-4 flex justify-between text-sm">
            <Text className="text-gray-500">Previous: 79</Text>
            <Text className="text-gray-500">Target: 85</Text>
          </div>
        </Card>

        {/* Deal Distribution */}
        <Card className="p-6">
          <Text>Deal Tier Distribution</Text>
          <DonutChart
            className="h-28 mt-4"
            data={[
              { name: 'Premium', value: businessMetrics.dealDistribution.byTier.premium.count },
              { name: 'Standard', value: businessMetrics.dealDistribution.byTier.standard.count },
              { name: 'Basic', value: businessMetrics.dealDistribution.byTier.basic.count }
            ]}
            category="value"
            index="name"
            colors={['emerald', 'blue', 'amber']}
          />
          <div className="mt-4 grid grid-cols-3 gap-2 text-center">
            {Object.entries(businessMetrics.dealHealth.distribution).map(([key, value]) => (
              <div key={key}>
                <Text className="text-sm text-gray-500 capitalize">{key}</Text>
                <Text className="font-medium">{value}%</Text>
              </div>
            ))}
          </div>
        </Card>

        {/* Revenue Impact */}
        <Card className="p-6">
          <Text>Revenue Impact</Text>
          <div className="flex items-baseline space-x-2 mt-2">
            <Metric>${(businessMetrics.performance.revenueImpact / 1000000).toFixed(2)}M</Metric>
            <Badge color="green">+12.5%</Badge>
          </div>
          <Text className="text-sm text-gray-500 mt-2">
            From optimizations
          </Text>
          <ProgressBar
            value={businessMetrics.performance.optimizationSuccess}
            color="green"
            className="mt-4"
          />
          <Text className="text-sm text-gray-500 mt-2">
            {businessMetrics.performance.optimizationSuccess}% success rate
          </Text>
        </Card>

        {/* Response Metrics */}
        <Card className="p-6">
          <Text>Response Metrics</Text>
          <Metric>{businessMetrics.performance.avgResponseTime}h</Metric>
          <Text className="text-sm text-gray-500">
            Average response time
          </Text>
          <div className="mt-4 flex justify-between">
            <Badge color="green">92% within SLA</Badge>
            <Badge color="blue">98% resolution</Badge>
          </div>
        </Card>
      </div>

      {/* Performance Trends */}
      <Card>
        <div className="p-6">
          <Title>Performance Trends</Title>
          <AreaChart
            className="h-72 mt-4"
            data={businessMetrics.performance.trendsData}
            index="date"
            categories={['Revenue Impact', 'Deal Health', 'Optimization Rate']}
            colors={['blue', 'emerald', 'amber']}
            valueFormatter={(number: number) =>
              number > 1000000 
                ? `$${(number / 1000000).toFixed(1)}M`
                : `${number}%`
            }
          />
        </div>
      </Card>

      {/* Alerts and Actions */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Critical Alerts */}
        <Card>
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <Title>Critical Alerts</Title>
              <Badge color="red">{
                businessMetrics.alerts.filter(a => a.severity === 'critical').length
              } issues</Badge>
            </div>
            <div className="space-y-4">
              {businessMetrics.alerts.map(alert => (
                <div 
                  key={alert.id}
                  className={`p-4 rounded-lg ${
                    alert.severity === 'critical' 
                      ? 'bg-red-50' 
                      : alert.severity === 'warning'
                      ? 'bg-amber-50'
                      : 'bg-blue-50'
                  }`}
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <Text className="font-medium">{alert.type}</Text>
                      <Text className="text-sm text-gray-600 mt-1">
                        {alert.message}
                      </Text>
                      <div className="flex items-center mt-2 space-x-2">
                        <SparklesIcon className="h-4 w-4 text-blue-500" />
                        <Text className="text-sm text-blue-600">
                          {alert.recommendation}
                        </Text>
                      </div>
                    </div>
                    <Badge color={
                      alert.severity === 'critical' ? 'red' :
                      alert.severity === 'warning' ? 'yellow' : 'blue'
                    }>
                      {alert.count} {alert.severity === 'critical' ? 'deals' : 'items'}
                    </Badge>
                  </div>
                  <div className="mt-4">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="w-full"
                    >
                      Investigate
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>

        {/* Quick Actions */}
        <Card>
          <div className="p-6">
            <Title>Recommended Actions</Title>
            <div className="mt-6 space-y-4">
              <Button 
                variant="secondary"
                className="w-full justify-between"
              >
                <span className="flex items-center">
                  <ShieldCheckIcon className="h-5 w-5 mr-2" />
                  Run Health Check
                </span>
                <Badge color="blue">12 deals</Badge>
              </Button>
              <Button 
                variant="secondary"
                className="w-full justify-between"
              >
                <span className="flex items-center">
                  <SparklesIcon className="h-5 w-5 mr-2" />
                  Batch Optimize
                </span>
                <Badge color="green">8 deals</Badge>
              </Button>
              <Button 
                variant="secondary"
                className="w-full justify-between"
              >
                <span className="flex items-center">
                  <ArrowTrendingUpIcon className="h-5 w-5 mr-2" />
                  Review Performance
                </span>
                <Badge color="yellow">5 accounts</Badge>
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}