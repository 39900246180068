import { useMemo, useState } from 'react';
import { Card, Title, Text, Badge, ProgressBar, AreaChart } from '@tremor/react';
import { 
  ChartBarIcon, 
  GlobeAltIcon, 
  UserGroupIcon, 
  ClockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  EyeIcon,
  ShareIcon,
  ShoppingBagIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { Dialog } from '@headlessui/react'; 
import { MetricsPanel } from '../shared//MetricsPanel';
// import { DealDetails, TargetingParameters, type Deal } from './TargetingMetricsPanel';

interface PreviewData {
  type: 'deal' | 'audience' | 'domain';
  id: string;
  name: string;
  metrics: {
    primary: {
      label: string;
      value: string | number;
      trend?: {
        direction: 'up' | 'down' | 'stable';
        value: number;
      };
    }[];
    performance?: {
      viewability: number;
      ctr?: number;
      completion?: number;
    };
    distribution?: {
      label: string;
      value: number;
    }[];
    trends?: {
      date: string;
      value: number;
    }[];
  };
  details: Record<string, string | number>;
  tags?: string[];
}

const entityTypeMapping = {
    'deal': 'deals',
    'audience': 'audiences',
    'domain': 'domains'
  } as const;
  
  // Add a helper function to transform metrics
  const transformMetrics = (metrics: any) => {
    return {
      primary: metrics.primary.map((metric: any) => ({
        name: metric.label,  // Convert label to name
        value: metric.value,
        change: metric.trend?.value,  // Map trend.value to change
        benchmark: metric.benchmark,
        icon: metric.icon
      })),
      performance: metrics.performance,
      distribution: metrics.distribution,
      trends: metrics.trends
    };
  };

  
interface PreviewPaneProps {
  data: PreviewData | null;
  onClose: () => void;
  onViewDetail: (id: string) => void;
  onAnalyze: (id: string) => void;
  onAddToBasket?: (id: string) => void;
  onShare?: (id: string) => void;
  onMetricsView?: () => void; 
}

export function PreviewPane({
  data,
  onClose,
  onViewDetail,
  onAnalyze,
  onAddToBasket,
  onShare,
  onMetricsView
}: PreviewPaneProps) {
  const iconMap = {
    deal: ChartBarIcon,
    audience: UserGroupIcon,
    domain: GlobeAltIcon
  };

  const [showMetrics, setShowMetrics] = useState(false);
  const Icon = data ? iconMap[data.type] : null;

  // Format trend display
  const formatTrend = (trend?: { direction: 'up' | 'down' | 'stable'; value: number }) => {
    if (!trend || trend.direction === 'stable') return null;
    
    return (
      <Badge
        color={trend.direction === 'up' ? 'emerald' : 'red'}
        className="flex items-center"
      >
        {trend.direction === 'up' ? (
          <ArrowUpIcon className="h-3 w-3 mr-1" />
        ) : (
          <ArrowDownIcon className="h-3 w-3 mr-1" />
        )}
        {trend.value}%
      </Badge>
    );
  };

  if (!data) return null;

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-3">
            {Icon && (
              <div className="p-2 bg-gray-100 rounded-lg">
                <Icon className="h-5 w-5 text-gray-600" />
              </div>
            )}
            <div>
              <Title className="text-lg">{data.name}</Title>
              <Text className="text-gray-500">
                {data.type.charAt(0).toUpperCase() + data.type.slice(1)} Preview
              </Text>
            </div>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <ClockIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-y-auto p-4 space-y-6">
        {/* Primary Metrics */}
        <div className="grid grid-cols-2 gap-4">
          {data.metrics.primary.map((metric, index) => (
            <Card key={index} className="space-y-2">
              <Text className="text-gray-500">{metric.label}</Text>
              <div className="flex items-baseline space-x-2">
                <Text className="text-2xl font-semibold">{metric.value}</Text>
                {formatTrend(metric.trend)}
              </div>
            </Card>
          ))}
        </div>

        {/* Performance Metrics */}
        {data.metrics.performance && (
          <Card>
            <Title>Performance</Title>
            <div className="mt-4 space-y-4">
              {Object.entries(data.metrics.performance).map(([key, value]) => (
                <div key={key} className="space-y-1">
                  <div className="flex justify-between">
                    <Text className="capitalize">{key}</Text>
                    <Text>{typeof value === 'number' && value < 1 ? 
                      `${(value * 100).toFixed(2)}%` : 
                      `${value}%`}
                    </Text>
                  </div>
                  <ProgressBar value={typeof value === 'number' && value < 1 ? 
                    value * 100 : value} 
                    color="blue" 
                  />
                </div>
              ))}
            </div>
          </Card>
        )}

        {/* Distribution Chart */}
        {data.metrics.distribution && (
          <Card>
            <Title>Distribution</Title>
            <div className="mt-4 space-y-2">
              {data.metrics.distribution.map((item) => (
                <div key={item.label} className="flex items-center space-x-2">
                  <div className="w-32 truncate">
                    <Text>{item.label}</Text>
                  </div>
                  <div className="flex-1">
                    <ProgressBar value={item.value} color="blue" />
                  </div>
                  <div className="w-16 text-right">
                    <Text>{item.value}%</Text>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        )}

        {/* Trends Chart */}
        {data.metrics.trends && (
          <Card>
            <Title>Trends</Title>
            <AreaChart
              className="h-48 mt-4"
              data={data.metrics.trends}
              index="date"
              categories={["value"]}
              colors={["blue"]}
              showLegend={false}
            />
          </Card>
        )}

        {/* Details */}
        <Card>
          <Title>Details</Title>
          <div className="mt-4 space-y-2">
            {Object.entries(data.details).map(([key, value]) => (
              <div key={key} className="flex justify-between">
                <Text className="text-gray-500 capitalize">
                  {key.replace(/_/g, ' ')}
                </Text>
                <Text>{value}</Text>
              </div>
            ))}
          </div>
        </Card>

        {/* Tags */}
        {data.tags && data.tags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {data.tags.map((tag) => (
              <Badge key={tag} color="gray">
                {tag}
              </Badge>
            ))}
          </div>
        )}
      </div>

      {/* Footer Actions */}
      <div className="px-4 py-3 border-t border-gray-200">
        <div className="flex justify-end space-x-4">
          
        <div className="flex space-x-2">
            <Button variant="ghost" onClick={() => onShare?.(data.id)}>
              <ShareIcon className="h-4 w-4 mr-1" />
              Share
            </Button>

          </div>
          
          <Button
            variant="secondary"
            onClick={() => onAnalyze(data.id)}
          >
            <ShoppingBagIcon className="h-5 w-5 mr-2" />
            Add to Basket
          </Button>
          <Button variant="ghost" onClick={() => setShowMetrics(true)}>
            <ChartBarIcon className="h-4 w-4 mr-1" />
            View Metrics
          </Button>
        </div>



                {showMetrics && (
        <Dialog
            open={showMetrics}
            onClose={() => setShowMetrics(false)}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-3xl bg-white rounded-xl">
                <MetricsPanel
                metrics={transformMetrics(data.metrics)}
                type={entityTypeMapping[data.type]}
                onClose={() => setShowMetrics(false)}
                />
            </Dialog.Panel>
            </div>
        </Dialog>
        )}
      </div>
    </div>
  );
}