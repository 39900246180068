// src/components/smartFinder/filters/FilterPopover.tsx

import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { 
  FunnelIcon, 
  XMarkIcon,
  PlusIcon 
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { Text, Select, NumberInput, DatePicker, Badge, SelectItem } from '@tremor/react';

type FilterType = 'text' | 'number' | 'date' | 'select' | 'boolean';
type Operator = 'equals' | 'contains' | 'greater' | 'less' | 'between';

interface FilterPopoverProps {
  field: string;
  onFilter: (field: string, filter: any) => void;
  type: FilterType;
  options?: Array<{ value: string; label: string }>;
  currentFilter?: any;
  onRemove?: () => void;
}

const operatorOptions = {
  text: [
    { value: 'equals', label: 'Equals' },
    { value: 'contains', label: 'Contains' }
  ],
  number: [
    { value: 'equals', label: 'Equals' },
    { value: 'greater', label: 'Greater than' },
    { value: 'less', label: 'Less than' },
    { value: 'between', label: 'Between' }
  ],
  date: [
    { value: 'equals', label: 'Equals' },
    { value: 'greater', label: 'After' },
    { value: 'less', label: 'Before' },
    { value: 'between', label: 'Between' }
  ],
  select: [
    { value: 'equals', label: 'Equals' }
  ],
  boolean: [
    { value: 'equals', label: 'Equals' }
  ]
};

export function FilterPopover({
  field,
  onFilter,
  type,
  options = [],
  currentFilter,
  onRemove
}: FilterPopoverProps) {
  const [operator, setOperator] = useState<Operator>(
    currentFilter?.operator || 'equals'
  );
  const [value, setValue] = useState<any>(currentFilter?.value || '');
  const [value2, setValue2] = useState<any>(
    currentFilter?.operator === 'between' ? currentFilter.value[1] : ''
  );

  const handleApply = () => {
    const filterValue = operator === 'between' ? [value, value2] : value;
    onFilter(field, {
      field,
      operator,
      value: filterValue
    });
  };

  const renderValueInput = () => {
    switch (type) {
      case 'text':
        return (
          <input
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter value..."
          />
        );
      
      case 'number':
        return (
          <div className="space-y-2">
            <NumberInput
              value={value}
              onValueChange={setValue}
              placeholder="Enter value..."
              enableStepper
            />
            {operator === 'between' && (
              <NumberInput
                value={value2}
                onValueChange={setValue2}
                placeholder="Enter maximum..."
                enableStepper
              />
            )}
          </div>
        );

      case 'date':
        return (
          <div className="space-y-2">
            <DatePicker
              value={value}
              onValueChange={setValue}
              placeholder="Select date..."
            />
            {operator === 'between' && (
              <DatePicker
                value={value2}
                onValueChange={setValue2}
                placeholder="Select end date..."
              />
            )}
          </div>
        );

      case 'select':
        return (
          <Select
            value={value}
            onValueChange={setValue}
            placeholder="Select option..."
          >
            {options.map((option) => (
                <SelectItem value={option.value}>
                {option.label}
                </SelectItem>
            ))}
          </Select>
        );

      case 'boolean':
        return (
          <Select
            value={value}
            onValueChange={setValue}
            placeholder="Select value..."
          >
            <SelectItem value={"true"}>
                Yes
            </SelectItem>
            <SelectItem value={"false"}>
                No
                </SelectItem>
          </Select>
        );

      default:
        return null;
    }
  };

  return (
    <Popover className="relative">
      <Popover.Button as={Fragment}>
        <Button
          variant="ghost"
          size="sm"
          className={currentFilter ? 'text-blue-600' : 'text-gray-500'}
        >
          <FunnelIcon className="h-4 w-4" />
        </Button>
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-2 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <Text className="font-medium">Filter</Text>
              {currentFilter && onRemove && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={onRemove}
                >
                  <XMarkIcon className="h-4 w-4" />
                </Button>
              )}
            </div>

            <div className="space-y-4">
              {/* Operator Selection */}
              <div>
                <Text className="text-sm text-gray-500 mb-1">Operator</Text>
                <Select
                  value={operator}
                  onValueChange={(value) => setOperator(value as Operator)}
                >
                  {operatorOptions[type].map((op) => (
                    <SelectItem value={op.value}>
                      {op.label}
                    </SelectItem>
                  ))}
                </Select>
              </div>

              {/* Value Input */}
              <div>
                <Text className="text-sm text-gray-500 mb-1">Value</Text>
                {renderValueInput()}
              </div>
            </div>

            {/* Actions */}
            <div className="mt-4 flex justify-end space-x-2">
              <Popover.Button as={Button} variant="secondary">
                Cancel
              </Popover.Button>
              <Button
                variant="primary"
                onClick={handleApply}
                disabled={!value || (operator === 'between' && !value2)}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
