// src/components/discovery/DealsView.tsx

import { FC, useState, Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import {
  Card,
  Badge,
  Text,
  Title,
  ProgressBar,
  Select,
  SelectItem,
  Metric,
  DonutChart,
  AreaChart,
} from '@tremor/react';
import { Button } from '../ui/Button';
import {
  ChartBarIcon,
  CurrencyDollarIcon,
  UsersIcon,
  ArrowTrendingUpIcon,
  ClockIcon,
  AdjustmentsHorizontalIcon,
  PlusIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  ShieldCheckIcon,
  DeviceTabletIcon,
  GlobeAltIcon,
  CheckCircleIcon,
  TableCellsIcon
} from '@heroicons/react/24/outline';
import type { SmartDiscoveryQuery } from '../../types/discovery';

import { DealCreationWizard } from '../deals/DealCreationWizard';
import type { DealConfiguration } from '../../types/dealTypes';
import { HierarchyFilter } from '../deals/management/filters/HierarchyFilter';
import { DealManagementTiles } from '../deals/management/DealManagementTiles';
import { DocumentChartBarIcon } from '@heroicons/react/24/outline';
import { AlertProvider } from '../deals/management/alerts/AlertIntegration'

export interface DealsViewProps {
  query: SmartDiscoveryQuery | null;
}

interface TargetingParameters {
  audience: {
    segments: string[];
    demographics: {
      ageGroups: string[];
      gender: string[];
      income: string[];
    };
    interests: string[];
    behaviors: string[];
  };
  geography: {
    countries: string[];
    regions: string[];
    cities: string[];
    dma: string[];
  };
  inventory: {
    domains: string[];
    categories: string[];
    formats: string[];
    positions: string[];
  };
  devices: {
    types: string[];
    browsers: string[];
    operatingSystems: string[];
  };
  brand_safety: {
    level: 'standard' | 'strict' | 'custom';
    blockedCategories: string[];
    allowedLanguages: string[];
    customRules?: string[];
  };
}

interface DealPerformance {
  daily: {
    date: string;
    impressions: number;
    clicks: number;
    spend: number;
    ctr: number;
    viewability: number;
    completion?: number;
  }[];
  summary: {
    impressions: number;
    clicks: number;
    spend: number;
    ctr: number;
    viewability: number;
    completion?: number;
    avgCPM: number;
    avgCPC: number;
  };
  trends: {
    ctr: number;
    viewability: number;
    completion?: number;
    direction: 'up' | 'down' | 'stable';
  };
}

interface Deal {
  id: string;
  name: string;
  type: 'PMP' | 'Preferred' | 'Programmatic Guaranteed' | 'Private Auction';
  status: 'active' | 'draft' | 'pending' | 'completed' | 'paused';
  advertiser: {
    name: string;
    id: string;
    category: string;
  };
  budget: {
    total: number;
    spent: number;
    remaining: number;
    daily: number;
    pacing: 'ahead' | 'behind' | 'on_track';
  };
  timeline: {
    startDate: string;
    endDate: string;
    daysRemaining: number;
  };
  targeting: TargetingParameters;
  performance: DealPerformance;
  pricing: {
    model: 'CPM' | 'CPC' | 'CPCV';
    rate: number;
    floor?: number;
    ceiling?: number;
  };
  kpis: {
    target: {
      ctr?: number;
      viewability?: number;
      completion?: number;
    };
    current: {
      ctr?: number;
      viewability?: number;
      completion?: number;
    };
  };
  similarityScore?: number;
  lastUpdated: string;
  tags: string[];
}

// Rich mock data with various examples
const mockDeals: Deal[] = [
  {
    id: 'DEAL-001',
    name: 'Premium Sports Video Campaign',
    type: 'PMP',
    status: 'active',
    advertiser: {
      name: 'SportMax Inc',
      id: 'ADV-001',
      category: 'Sports & Recreation'
    },
    budget: {
      total: 500000,
      spent: 285000,
      remaining: 215000,
      daily: 15000,
      pacing: 'on_track'
    },
    timeline: {
      startDate: '2024-01-01',
      endDate: '2024-03-31',
      daysRemaining: 45
    },
    targeting: {
      audience: {
        segments: ['Sports Enthusiasts', 'High Income', 'Athletic Gear Buyers'],
        demographics: {
          ageGroups: ['25-34', '35-44'],
          gender: ['M', 'F'],
          income: ['$100k+']
        },
        interests: ['Sports', 'Fitness', 'Athletic Wear'],
        behaviors: ['Sports Content Consumption', 'Gym Memberships', 'Recent Sport Equipment Purchase']
      },
      geography: {
        countries: ['United States'],
        regions: ['Northeast', 'West Coast'],
        cities: ['New York', 'Los Angeles', 'San Francisco'],
        dma: ['NY DMA', 'LA DMA', 'SF DMA']
      },
      inventory: {
        domains: ['espn.com', 'sports.yahoo.com', 'cbssports.com'],
        categories: ['Sports', 'News/Sports', 'Lifestyle/Fitness'],
        formats: ['Pre-roll Video', 'Mid-roll Video'],
        positions: ['Above Fold', 'In-Content']
      },
      devices: {
        types: ['Desktop', 'Mobile', 'Tablet', 'CTV'],
        browsers: ['Chrome', 'Safari', 'Firefox'],
        operatingSystems: ['iOS', 'Android', 'Windows', 'MacOS']
      },
      brand_safety: {
        level: 'strict',
        blockedCategories: ['Adult Content', 'Gambling', 'Politics'],
        allowedLanguages: ['English'],
        customRules: ['No UGC content']
      }
    },
    performance: {
      daily: [
        {
          date: '2024-01-15',
          impressions: 250000,
          clicks: 750,
          spend: 8750,
          ctr: 0.3,
          viewability: 85,
          completion: 78
        },
        // Add more daily data...
      ],
      summary: {
        impressions: 7500000,
        clicks: 22500,
        spend: 285000,
        ctr: 0.3,
        viewability: 85,
        completion: 78,
        avgCPM: 38.00,
        avgCPC: 12.67
      },
      trends: {
        ctr: 15,
        viewability: 8,
        completion: 5,
        direction: 'up'
      }
    },
    pricing: {
      model: 'CPM',
      rate: 38.00,
      floor: 35.00,
      ceiling: 42.00
    },
    kpis: {
      target: {
        ctr: 0.25,
        viewability: 80,
        completion: 75
      },
      current: {
        ctr: 0.3,
        viewability: 85,
        completion: 78
      }
    },
    similarityScore: 95,
    lastUpdated: '2024-01-15T14:30:00Z',
    tags: ['Premium', 'Video', 'Sports', 'High Performance']
  },
  // Add these additional mock deals to the mockDeals array:

  {
    id: 'DEAL-002',
    name: 'Luxury Auto Display Campaign',
    type: 'Programmatic Guaranteed',
    status: 'active',
    advertiser: {
      name: 'LuxuryCars International',
      id: 'ADV-002',
      category: 'Automotive'
    },
    budget: {
      total: 750000,
      spent: 425000,
      remaining: 325000,
      daily: 25000,
      pacing: 'ahead'
    },
    timeline: {
      startDate: '2024-01-15',
      endDate: '2024-04-15',
      daysRemaining: 60
    },
    targeting: {
      audience: {
        segments: ['Auto Intenders', 'Luxury Shoppers', 'High Net Worth'],
        demographics: {
          ageGroups: ['35-44', '45-54', '55-64'],
          gender: ['M', 'F'],
          income: ['$150k+']
        },
        interests: ['Luxury Cars', 'Premium Lifestyle', 'Financial Services'],
        behaviors: ['Dealer Website Visits', 'Car Configuration Tools', 'Price Comparison']
      },
      geography: {
        countries: ['United States'],
        regions: ['All'],
        cities: ['Top 50 DMAs'],
        dma: ['All Major Markets']
      },
      inventory: {
        domains: ['wsj.com', 'bloomberg.com', 'forbes.com', 'autoblog.com'],
        categories: ['Automotive', 'Business', 'Lifestyle'],
        formats: ['Display', 'Native'],
        positions: ['Above Fold']
      },
      devices: {
        types: ['Desktop', 'Tablet'],
        browsers: ['All'],
        operatingSystems: ['All']
      },
      brand_safety: {
        level: 'strict',
        blockedCategories: ['Adult Content', 'Gambling', 'Politics', 'User Generated Content'],
        allowedLanguages: ['English'],
        customRules: ['Premium Publishers Only']
      }
    },
    performance: {
      daily: [
        {
          date: '2024-01-15',
          impressions: 350000,
          clicks: 875,
          spend: 14875,
          ctr: 0.25,
          viewability: 82
        }
      ],
      summary: {
        impressions: 10500000,
        clicks: 26250,
        spend: 425000,
        ctr: 0.25,
        viewability: 82,
        avgCPM: 40.48,
        avgCPC: 16.19
      },
      trends: {
        ctr: 12,
        viewability: 5,
        direction: 'up'
      }
    },
    pricing: {
      model: 'CPM',
      rate: 40.48
    },
    kpis: {
      target: {
        ctr: 0.20,
        viewability: 80
      },
      current: {
        ctr: 0.25,
        viewability: 82
      }
    },
    similarityScore: 88,
    lastUpdated: '2024-01-15T15:45:00Z',
    tags: ['Luxury', 'Display', 'High Value', 'Premium']
  },
  {
    id: 'DEAL-003',
    name: 'E-commerce Retargeting Campaign',
    type: 'Private Auction',
    status: 'active',
    advertiser: {
      name: 'ShopStream',
      id: 'ADV-003',
      category: 'Retail'
    },
    budget: {
      total: 250000,
      spent: 98000,
      remaining: 152000,
      daily: 8000,
      pacing: 'behind'
    },
    timeline: {
      startDate: '2024-01-10',
      endDate: '2024-03-10',
      daysRemaining: 35
    },
    targeting: {
      audience: {
        segments: ['Cart Abandoners', 'Product Viewers', 'Past Purchasers'],
        demographics: {
          ageGroups: ['18-24', '25-34', '35-44'],
          gender: ['All'],
          income: ['All']
        },
        interests: ['Online Shopping', 'Consumer Electronics', 'Fashion'],
        behaviors: ['Recent Cart Abandon', 'Price Comparison', 'Coupon Usage']
      },
      geography: {
        countries: ['United States', 'Canada'],
        regions: ['All'],
        cities: ['All'],
        dma: ['All']
      },
      inventory: {
        domains: ['All'],
        categories: ['Shopping', 'Technology', 'Lifestyle'],
        formats: ['Display', 'Native', 'Mobile Web'],
        positions: ['All']
      },
      devices: {
        types: ['All'],
        browsers: ['All'],
        operatingSystems: ['All']
      },
      brand_safety: {
        level: 'standard',
        blockedCategories: ['Adult Content', 'Gambling'],
        allowedLanguages: ['English', 'Spanish', 'French']
      }
    },
    performance: {
      daily: [
        {
          date: '2024-01-15',
          impressions: 450000,
          clicks: 2250,
          spend: 6750,
          ctr: 0.5,
          viewability: 75
        }
      ],
      summary: {
        impressions: 13500000,
        clicks: 67500,
        spend: 98000,
        ctr: 0.5,
        viewability: 75,
        avgCPM: 7.26,
        avgCPC: 1.45
      },
      trends: {
        ctr: 8,
        viewability: -2,
        direction: 'down'
      }
    },
    pricing: {
      model: 'CPM',
      rate: 7.26,
      floor: 5.00
    },
    kpis: {
      target: {
        ctr: 0.45,
        viewability: 70
      },
      current: {
        ctr: 0.50,
        viewability: 75
      }
    },
    similarityScore: 82,
    lastUpdated: '2024-01-15T16:20:00Z',
    tags: ['Retail', 'Performance', 'Retargeting']
  }
];


// Add this to the same file or create a new component file

interface DealDetailsProps {
    deal: Deal;
    onClose: () => void;
  }
  
  const DealDetails: FC<DealDetailsProps> = ({ deal, onClose }) => {
    const [activeTab, setActiveTab] = useState<'overview' | 'performance' | 'budget'>('overview');
  
    return (
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div>
            <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
              {deal.name}
            </Dialog.Title>
            <Text className="text-gray-500 mt-1">{deal.advertiser.name}</Text>
          </div>
          <div className="flex items-center space-x-4">
            <Badge color={
              deal.status === 'active' ? 'emerald' :
              deal.status === 'pending' ? 'yellow' :
              'gray'
            }>
              {deal.status}
            </Badge>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
  
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['overview', 'performance', 'budget'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab as typeof activeTab)}
                className={`
                  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>
  
        {/* Content */}
        <div className="space-y-6">
          {activeTab === 'overview' && (
            <>
              {/* Basic Info */}
              <div className="grid grid-cols-2 gap-6">
                <Card>
                  <Title>Deal Information</Title>
                  <div className="mt-4 space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Text className="text-sm text-gray-500">Type</Text>
                        <Text className="font-medium">{deal.type}</Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Pricing Model</Text>
                        <Text className="font-medium">{deal.pricing.model}</Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Rate</Text>
                        <Text className="font-medium">${deal.pricing.rate.toFixed(2)}</Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Category</Text>
                        <Text className="font-medium">{deal.advertiser.category}</Text>
                      </div>
                    </div>
                  </div>
                </Card>
  
                <Card>
                  <Title>Timeline</Title>
                  <div className="mt-4 space-y-4">
                    <div>
                      <Text className="text-sm text-gray-500">Duration</Text>
                      <div className="flex items-center justify-between mt-1">
                        <Text>{new Date(deal.timeline.startDate).toLocaleDateString()}</Text>
                        <Text>to</Text>
                        <Text>{new Date(deal.timeline.endDate).toLocaleDateString()}</Text>
                      </div>
                    </div>
                    <div>
                      <Text className="text-sm text-gray-500">Days Remaining</Text>
                      <Text className="font-medium">{deal.timeline.daysRemaining} days</Text>
                    </div>
                  </div>
                </Card>
              </div>
  
              {/* KPIs */}
              <Card>
                <Title>Key Performance Indicators</Title>
                <div className="mt-4 grid grid-cols-3 gap-6">
                  {Object.entries(deal.kpis.current).map(([metric, value]) => (
                    <div key={metric}>
                      <Text className="text-sm text-gray-500 capitalize">{metric}</Text>
                      <div className="mt-2 space-y-2">
                        <div className="flex justify-between">
                          <Text>Current</Text>
                          <Text className="font-medium">
                            {typeof value === 'number' ? value.toFixed(2) : value}%
                          </Text>
                        </div>
                        <ProgressBar
                          value={Number(value)}
                          color={
                            Number(value) >= Number(deal.kpis.target[metric as keyof typeof deal.kpis.target])
                              ? 'emerald'
                              : 'yellow'
                          }
                        />
                        <div className="flex justify-between text-sm">
                          <Text className="text-gray-500">Target</Text>
                          <Text>{deal.kpis.target[metric as keyof typeof deal.kpis.target]}%</Text>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            </>
          )}
  
          {activeTab === 'performance' && (
            <>
              {/* Performance Metrics */}
              <div className="grid grid-cols-3 gap-6">
                <Card>
                  <Text>Total Impressions</Text>
                  <Metric>{deal.performance.summary.impressions.toLocaleString()}</Metric>
                  <AreaChart
                    className="mt-4 h-28"
                    data={deal.performance.daily}
                    index="date"
                    categories={["impressions"]}
                    colors={["blue"]}
                    showXAxis={false}
                    showYAxis={false}
                    showLegend={false}
                  />
                </Card>
                <Card>
                  <Text>Click-Through Rate</Text>
                  <Metric>{(deal.performance.summary.ctr * 100).toFixed(2)}%</Metric>
                  <AreaChart
                    className="mt-4 h-28"
                    data={deal.performance.daily}
                    index="date"
                    categories={["ctr"]}
                    colors={["emerald"]}
                    showXAxis={false}
                    showYAxis={false}
                    showLegend={false}
                  />
                </Card>
                <Card>
                  <Text>Viewability</Text>
                  <Metric>{deal.performance.summary.viewability}%</Metric>
                  <AreaChart
                    className="mt-4 h-28"
                    data={deal.performance.daily}
                    index="date"
                    categories={["viewability"]}
                    colors={["violet"]}
                    showXAxis={false}
                    showYAxis={false}
                    showLegend={false}
                  />
                </Card>
              </div>
  
              {/* Detailed Performance Chart */}
              <Card>
                <Title>Performance Trends</Title>
                <AreaChart
                  className="mt-4 h-80"
                  data={deal.performance.daily}
                  index="date"
                  categories={["impressions", "clicks", "viewability"]}
                  colors={["blue", "emerald", "violet"]}
                  valueFormatter={(value) => value.toLocaleString()}
                />
              </Card>
            </>
          )}
  
          {activeTab === 'budget' && (
            <>
              {/* Budget Overview */}
              <div className="grid grid-cols-2 gap-6">
                <Card>
                  <Title>Budget Utilization</Title>
                  <div className="mt-4 space-y-4">
                    <div>
                      <div className="flex justify-between mb-2">
                        <Text className="font-medium">
                          ${deal.budget.spent.toLocaleString()}
                        </Text>
                        <Text className="text-gray-500">
                          of ${deal.budget.total.toLocaleString()}
                        </Text>
                      </div>
                      <ProgressBar
                        value={(deal.budget.spent / deal.budget.total) * 100}
                        color={
                          deal.budget.pacing === 'ahead' ? 'yellow' :
                          deal.budget.pacing === 'behind' ? 'red' :
                          'blue'
                        }
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Text className="text-sm text-gray-500">Daily Budget</Text>
                        <Text className="font-medium">
                          ${deal.budget.daily.toLocaleString()}
                        </Text>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Remaining</Text>
                        <Text className="font-medium">
                          ${deal.budget.remaining.toLocaleString()}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Card>
  
                <Card>
                  <Title>Spend Efficiency</Title>
                  <div className="mt-4 space-y-4">
                    <div>
                      <Text className="text-sm text-gray-500">Average CPM</Text>
                      <Text className="font-medium">
                        ${deal.performance.summary.avgCPM.toFixed(2)}
                      </Text>
                    </div>
                    <div>
                      <Text className="text-sm text-gray-500">Average CPC</Text>
                      <Text className="font-medium">
                        ${deal.performance.summary.avgCPC.toFixed(2)}
                      </Text>
                    </div>
                  </div>
                </Card>
              </div>
  
              {/* Daily Spend Chart */}
              <Card>
                <Title>Daily Spend</Title>
                <AreaChart
                  className="mt-4 h-80"
                  data={deal.performance.daily}
                  index="date"
                  categories={["spend"]}
                  colors={["blue"]}
                  valueFormatter={(value) => `$${value.toLocaleString()}`}
                />
              </Card>
            </>
          )}
        </div>
  
        {/* Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary">
            Edit Deal
          </Button>
        </div>
      </div>
    );
  };


  interface TargetingParametersProps {
    deal: Deal;
    onClose: () => void;
  }
  
  const TargetingParameters: FC<TargetingParametersProps> = ({ deal, onClose }) => {
    const [activeSection, setActiveSection] = useState<string>('audience');
  
    const TargetingSection: FC<{
      title: string;
      children: React.ReactNode;
      icon: React.ComponentType<any>;
    }> = ({ title, children, icon: Icon }) => (
      <Card>
        <div className="flex items-center space-x-2 mb-4">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Icon className="h-5 w-5 text-blue-500" />
          </div>
          <Title>{title}</Title>
        </div>
        {children}
      </Card>
    );
  
    return (
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div>
            <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
              Targeting Parameters
            </Dialog.Title>
            <Text className="text-gray-500 mt-1">
              {deal.name}
            </Text>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
  
        {/* Navigation */}
        <div className="flex space-x-4 overflow-x-auto pb-2">
          {[
            { id: 'audience', name: 'Audience', icon: UsersIcon },
            { id: 'geography', name: 'Geography', icon: GlobeAltIcon },
            { id: 'inventory', name: 'Inventory', icon: ChartBarIcon },
            { id: 'devices', name: 'Devices', icon: DeviceTabletIcon },
            { id: 'brand_safety', name: 'Brand Safety', icon: ShieldCheckIcon },
          ].map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`
                flex items-center space-x-2 px-4 py-2 rounded-lg whitespace-nowrap
                ${activeSection === section.id
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-50'
                }
              `}
            >
              <section.icon className="h-5 w-5" />
              <span>{section.name}</span>
            </button>
          ))}
        </div>
  
        {/* Content */}
        <div className="space-y-6">
          {activeSection === 'audience' && (
            <>
              {/* Audience Segments */}
              <TargetingSection title="Audience Segments" icon={UsersIcon}>
                <div className="space-y-4">
                  <div className="flex flex-wrap gap-2">
                    {deal.targeting.audience.segments.map((segment) => (
                      <Badge key={segment} color="blue">
                        {segment}
                      </Badge>
                    ))}
                  </div>
                  
                  {/* Demographics */}
                  <div className="mt-4">
                    <Text className="font-medium mb-2">Demographics</Text>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <Text className="text-sm text-gray-500">Age Groups</Text>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {deal.targeting.audience.demographics.ageGroups.map((age) => (
                            <Badge key={age} color="gray" size="sm">
                              {age}
                            </Badge>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Gender</Text>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {deal.targeting.audience.demographics.gender.map((g) => (
                            <Badge key={g} color="gray" size="sm">
                              {g === 'M' ? 'Male' : 'Female'}
                            </Badge>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Text className="text-sm text-gray-500">Income</Text>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {deal.targeting.audience.demographics.income.map((income) => (
                            <Badge key={income} color="gray" size="sm">
                              {income}
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
  
                  {/* Interests & Behaviors */}
                  <div className="grid grid-cols-2 gap-6 mt-4">
                    <div>
                      <Text className="font-medium mb-2">Interests</Text>
                      <div className="space-y-2">
                        {deal.targeting.audience.interests.map((interest) => (
                          <div key={interest} className="flex items-center space-x-2">
                            <CheckCircleIcon className="h-4 w-4 text-green-500" />
                            <Text>{interest}</Text>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <Text className="font-medium mb-2">Behaviors</Text>
                      <div className="space-y-2">
                        {deal.targeting.audience.behaviors.map((behavior) => (
                          <div key={behavior} className="flex items-center space-x-2">
                            <CheckCircleIcon className="h-4 w-4 text-green-500" />
                            <Text>{behavior}</Text>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </TargetingSection>
            </>
          )}
  
          {activeSection === 'geography' && (
            <TargetingSection title="Geographic Targeting" icon={GlobeAltIcon}>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <Text className="font-medium mb-2">Countries</Text>
                  <div className="space-y-2">
                    {deal.targeting.geography.countries.map((country) => (
                      <div key={country} className="flex items-center space-x-2">
                        <GlobeAltIcon className="h-4 w-4 text-blue-500" />
                        <Text>{country}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">Regions</Text>
                  <div className="space-y-2">
                    {deal.targeting.geography.regions.map((region) => (
                      <div key={region} className="flex items-center space-x-2">
                        <CheckCircleIcon className="h-4 w-4 text-green-500" />
                        <Text>{region}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">Cities</Text>
                  <div className="space-y-2">
                    {deal.targeting.geography.cities.map((city) => (
                      <div key={city} className="flex items-center space-x-2">
                        <CheckCircleIcon className="h-4 w-4 text-green-500" />
                        <Text>{city}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">DMAs</Text>
                  <div className="space-y-2">
                    {deal.targeting.geography.dma.map((dma) => (
                      <div key={dma} className="flex items-center space-x-2">
                        <CheckCircleIcon className="h-4 w-4 text-green-500" />
                        <Text>{dma}</Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </TargetingSection>
          )}
  
          {activeSection === 'inventory' && (
            <TargetingSection title="Inventory Targeting" icon={ChartBarIcon}>
              <div className="space-y-6">
                <div>
                  <Text className="font-medium mb-2">Domains</Text>
                  <div className="flex flex-wrap gap-2">
                    {deal.targeting.inventory.domains.map((domain) => (
                      <Badge key={domain} color="blue">
                        {domain}
                      </Badge>
                    ))}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <Text className="font-medium mb-2">Categories</Text>
                    <div className="space-y-2">
                      {deal.targeting.inventory.categories.map((category) => (
                        <div key={category} className="flex items-center space-x-2">
                          <CheckCircleIcon className="h-4 w-4 text-green-500" />
                          <Text>{category}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Text className="font-medium mb-2">Formats</Text>
                    <div className="space-y-2">
                      {deal.targeting.inventory.formats.map((format) => (
                        <div key={format} className="flex items-center space-x-2">
                          <CheckCircleIcon className="h-4 w-4 text-green-500" />
                          <Text>{format}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Text className="font-medium mb-2">Positions</Text>
                    <div className="space-y-2">
                      {deal.targeting.inventory.positions.map((position) => (
                        <div key={position} className="flex items-center space-x-2">
                          <CheckCircleIcon className="h-4 w-4 text-green-500" />
                          <Text>{position}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </TargetingSection>
          )}
  
          {activeSection === 'devices' && (
            <TargetingSection title="Device Targeting" icon={DeviceTabletIcon}>
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <Text className="font-medium mb-2">Device Types</Text>
                  <div className="space-y-2">
                    {deal.targeting.devices.types.map((type) => (
                      <div key={type} className="flex items-center space-x-2">
                        <DeviceTabletIcon className="h-4 w-4 text-blue-500" />
                        <Text>{type}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">Browsers</Text>
                  <div className="space-y-2">
                    {deal.targeting.devices.browsers.map((browser) => (
                      <div key={browser} className="flex items-center space-x-2">
                        <CheckCircleIcon className="h-4 w-4 text-green-500" />
                        <Text>{browser}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">Operating Systems</Text>
                  <div className="space-y-2">
                    {deal.targeting.devices.operatingSystems.map((os) => (
                      <div key={os} className="flex items-center space-x-2">
                        <CheckCircleIcon className="h-4 w-4 text-green-500" />
                        <Text>{os}</Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </TargetingSection>
          )}
  
          {activeSection === 'brand_safety' && (
            <TargetingSection title="Brand Safety" icon={ShieldCheckIcon}>
              <div className="space-y-6">
                <div>
                  <Text className="font-medium mb-2">Safety Level</Text>
                  <Badge color="emerald" size="lg">
                    {deal.targeting.brand_safety.level.toUpperCase()}
                  </Badge>
                </div>
                <div>
                  <Text className="font-medium mb-2">Blocked Categories</Text>
                  <div className="flex flex-wrap gap-2">
                    {deal.targeting.brand_safety.blockedCategories.map((category) => (
                      <Badge key={category} color="red">
                        {category}
                      </Badge>
                    ))}
                  </div>
                </div>
                <div>
                  <Text className="font-medium mb-2">Allowed Languages</Text>
                  <div className="flex flex-wrap gap-2">
                    {deal.targeting.brand_safety.allowedLanguages.map((language) => (
                      <Badge key={language} color="blue">
                        {language}
                      </Badge>
                    ))}
                  </div>
                </div>
                {deal.targeting.brand_safety.customRules && (
                  <div>
                    <Text className="font-medium mb-2">Custom Rules</Text>
                    <div className="space-y-2">
                      {deal.targeting.brand_safety.customRules.map((rule) => (
                        <div key={rule} className="flex items-center space-x-2">
                          <ShieldCheckIcon className="h-4 w-4 text-blue-500" />
                          <Text>{rule}</Text>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </TargetingSection>
          )}
        </div>
  
        {/* Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary">
            Edit Targeting
          </Button>
        </div>
      </div>
    );
  };
  
  
export const DealsView: FC<DealsViewProps> = ({ query }) => {
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
    const [sortBy, setSortBy] = useState<string>('similarity');
    const [filterStatus, setFilterStatus] = useState<string>('all');
    const [showDealDetails, setShowDealDetails] = useState<string | null>(null);
    const [showTargeting, setShowTargeting] = useState<string | null>(null);
    const [showDealWizard, setShowDealWizard] = useState(false);
    const [showManagementView, setShowManagementView] = useState(false);
    const [showReportView, setShowReportView] = useState(false);
    const [selectedDealForReport, setSelectedDealForReport] = useState<string | null>(null);
    const [showManagementTiles, setShowManagementTiles] = useState(false);

    
    const handleDealCreationComplete = (configuration: DealConfiguration) => {
      console.log('Deal created:', configuration);
      setShowDealWizard(false);
      // Here you would typically integrate with your backend
    };

    
    // Metrics calculations
    const metrics = {
      totalBudget: mockDeals.reduce((acc, deal) => acc + deal.budget.total, 0),
      activeDeals: mockDeals.filter(d => d.status === 'active').length,
      avgViewability: mockDeals.reduce((acc, deal) => acc + deal.performance.summary.viewability, 0) / mockDeals.length,
      totalImpressions: mockDeals.reduce((acc, deal) => acc + deal.performance.summary.impressions, 0),
    };
  
    const handleDealSelection = (dealId: string) => {
      setSelectedDeals(prev => 
        prev.includes(dealId) 
          ? prev.filter(id => id !== dealId)
          : [...prev, dealId]
      );
    };
  
    const handleCreateFromSelected = () => {
      // Implementation for creating new deal from selected deals
      console.log('Creating new deal from:', selectedDeals);
    };
  
    // Deal Card Component
    const DealCard: FC<{ deal: Deal }> = ({ deal }) => (
      <motion.div
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <Card className={`cursor-pointer transition-all duration-200 ${
          selectedDeals.includes(deal.id) ? 'ring-2 ring-blue-500' : ''
        }`}>
          {/* Selection Checkbox */}
          <div className="absolute top-3 left-3">
            <input
              type="checkbox"
              checked={selectedDeals.includes(deal.id)}
              onChange={() => handleDealSelection(deal.id)}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
  
          {/* Similarity Score */}
          {deal.similarityScore && (
            <div className="absolute top-3 right-3">
              <Badge
                color={deal.similarityScore > 90 ? 'emerald' : 'blue'}
                size="lg"
              >
                {deal.similarityScore}% Match
              </Badge>
            </div>
          )}
  
          {/* Main Content */}
          <div className="space-y-4 pt-10">
            {/* Header */}
            <div>
              <div className="flex items-start justify-between">
                <div>
                  <Title>{deal.name}</Title>
                  <Text className="text-gray-500">{deal.advertiser.name}</Text>
                </div>
                <Badge color={
                  deal.status === 'active' ? 'emerald' :
                  deal.status === 'pending' ? 'yellow' :
                  deal.status === 'completed' ? 'gray' :
                  'red'
                }>
                  {deal.status}
                </Badge>
              </div>
              <div className="flex items-center space-x-2 mt-2">
                <Badge color="blue">{deal.type}</Badge>
                <Badge color="gray">{deal.pricing.model}</Badge>
              </div>
            </div>
  
            {/* Budget */}
            <div className="space-y-2">
              <Text className="text-sm text-gray-500">Budget Utilization</Text>
              <div className="flex justify-between items-center">
                <Text className="font-medium">
                  ${deal.budget.spent.toLocaleString()}
                </Text>
                <Text className="text-gray-500">
                  of ${deal.budget.total.toLocaleString()}
                </Text>
              </div>
              <ProgressBar
                value={(deal.budget.spent / deal.budget.total) * 100}
                color={
                  deal.budget.pacing === 'ahead' ? 'yellow' :
                  deal.budget.pacing === 'behind' ? 'red' :
                  'blue'
                }
                className="h-2"
              />
              <Text className="text-xs text-gray-500">
                {deal.budget.pacing === 'ahead' ? 'Pacing ahead' :
                 deal.budget.pacing === 'behind' ? 'Pacing behind' :
                 'On track'}
              </Text>
            </div>
  
            {/* Performance Metrics */}
            <div className="grid grid-cols-3 gap-4">
              <div>
                <Text className="text-xs text-gray-500">CTR</Text>
                <div className="flex items-baseline space-x-1">
                  <Text className="font-medium">
                    {(deal.performance.summary.ctr * 100).toFixed(2)}%
                  </Text>
                  {deal.performance.trends.direction === 'up' && (
                    <Text className="text-xs text-green-600">
                      +{deal.performance.trends.ctr}%
                    </Text>
                  )}
                </div>
              </div>
              <div>
                <Text className="text-xs text-gray-500">Viewability</Text>
                <div className="flex items-baseline space-x-1">
                  <Text className="font-medium">
                    {deal.performance.summary.viewability}%
                  </Text>
                  {deal.performance.trends.viewability > 0 && (
                    <Text className="text-xs text-green-600">
                      +{deal.performance.trends.viewability}%
                    </Text>
                  )}
                </div>
              </div>
              <div>
                <Text className="text-xs text-gray-500">
                  {deal.pricing.model === 'CPM' ? 'CPM' : 'CPC'}
                </Text>
                <Text className="font-medium">
                  ${deal.pricing.rate.toFixed(2)}
                </Text>
              </div>
            </div>
  
            {/* Actions */}
            <div className="border-t pt-4 flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowDealDetails(deal.id)}
                >
                  <EyeIcon className="h-4 w-4 mr-1" />
                  Details
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowTargeting(deal.id)}
                >
                  <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1" />
                  Targeting
                </Button>
              </div>
              <Text className="text-xs text-gray-500">
                {deal.timeline.daysRemaining} days remaining
              </Text>
            </div>
          </div>
        </Card>
      </motion.div>
    );
  

    return (
      <div className="space-y-6">
        {/* Metrics Overview */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card>
            <Text>Total Budget</Text>
            <Metric>${(metrics.totalBudget / 1000000).toFixed(1)}M</Metric>
            <Text className="text-sm text-gray-500">
              Across {mockDeals.length} deals
            </Text>
          </Card>
          <Card>
            <Text>Active Deals</Text>
            <Metric>{metrics.activeDeals}</Metric>
            <Text className="text-sm text-gray-500">
              {((metrics.activeDeals / mockDeals.length) * 100).toFixed(0)}% of total
            </Text>
          </Card>
          <Card>
            <Text>Avg. Viewability</Text>
            <Metric>{metrics.avgViewability.toFixed(1)}%</Metric>
            <Text className="text-sm text-gray-500">
              Platform average
            </Text>
          </Card>
          <Card>
            <Text>Total Impressions</Text>
            <Metric>{(metrics.totalImpressions / 1000000).toFixed(1)}M</Metric>
            <Text className="text-sm text-gray-500">
              Lifetime delivery
            </Text>
          </Card>
        </div>
  
{/* Controls */}
<div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div className="flex items-center space-x-4">
          <Select 
            value={viewMode} 
            onValueChange={(value: string) => setViewMode(value as 'grid' | 'list')}
          >
            <SelectItem value="grid">Grid View</SelectItem>
            <SelectItem value="list">List View</SelectItem>
          </Select>

          <Select 
            value={sortBy} 
            onValueChange={(value: string) => setSortBy(value)}
          >
            <SelectItem value="similarity">Sort by Match</SelectItem>
            <SelectItem value="budget">Sort by Budget</SelectItem>
            <SelectItem value="performance">Sort by Performance</SelectItem>
            <SelectItem value="recent">Sort by Recent</SelectItem>
          </Select>

          <Select 
            value={filterStatus} 
            onValueChange={(value: string) => setFilterStatus(value)}
          >
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="active">Active Only</SelectItem>
            <SelectItem value="pending">Pending</SelectItem>
            <SelectItem value="paused">Paused</SelectItem>
            <SelectItem value="completed">Completed</SelectItem>
          </Select>
        </div>

        <div className="flex items-center space-x-4">
          {selectedDeals.length > 0 && (
            <>
              <Button variant="secondary" size="sm" onClick={handleCreateFromSelected}>
                <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                Create from Selected ({selectedDeals.length})
              </Button>

              <Button variant="secondary" onClick={() => setShowManagementTiles(true)}>
                <TableCellsIcon className="h-5 w-5 mr-2" />
                Reporting
              </Button>
              <Button variant="secondary" size="sm">
                <ChartBarIcon className="h-5 w-5 mr-2" />
                Compare
              </Button>
            </>
          )}
          <Button 
            variant="primary" 
            size="sm"
            onClick={() => setShowDealWizard(true)}
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Plan Deal
          </Button>
        </div>
      </div>

      {/* Deal Creation Wizard */}
      <DealCreationWizard
        isOpen={showDealWizard}
        onClose={() => setShowDealWizard(false)}
        onComplete={handleDealCreationComplete}
      />

      {/* Search Query Indicator */}
      {query && (
        <Card className="bg-blue-50 border-blue-100">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <CurrencyDollarIcon className="h-5 w-5 text-blue-500" />
              <div>
                <Text className="font-medium text-blue-900">
                  Showing deals matching:
                </Text>
                <Text className="text-blue-700">
                  {query.keywords.join(' ')}
                </Text>
              </div>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setSelectedDeals([])}
            >
              Clear Selection
            </Button>
          </div>
        </Card>
      )}

      {/* Deals Grid/List */}
      <div className={
        viewMode === 'grid'
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-4'
      }>
        <AnimatePresence>
          {mockDeals
            .filter(deal => filterStatus === 'all' || deal.status === filterStatus)
            .sort((a, b) => {
              switch (sortBy) {
                case 'similarity':
                  return (b.similarityScore || 0) - (a.similarityScore || 0);
                case 'budget':
                  return b.budget.total - a.budget.total;
                case 'performance':
                  return b.performance.summary.viewability - a.performance.summary.viewability;
                case 'recent':
                  return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
                default:
                  return 0;
              }
            })
            .map((deal) => (
              <DealCard 
                key={deal.id} 
                deal={deal}
              />
            ))}
        </AnimatePresence>
      </div>

      {/* Deal Details Modal */}
      <Transition appear show={!!showDealDetails} as={Fragment}>
        <Dialog 
          as="div" 
          className="relative z-50"
          onClose={() => setShowDealDetails(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                  {showDealDetails && (
                    <DealDetails 
                      deal={mockDeals.find(d => d.id === showDealDetails)!}
                      onClose={() => setShowDealDetails(null)}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Targeting Parameters Modal */}
      <Transition appear show={!!showTargeting} as={Fragment}>
        <Dialog 
          as="div" 
          className="relative z-50"
          onClose={() => setShowTargeting(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                  {showTargeting && (
                    <TargetingParameters 
                      deal={mockDeals.find(d => d.id === showTargeting)!}
                      onClose={() => setShowTargeting(null)}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

{/* Management Tiles Modal */}
<Transition appear show={!!showManagementTiles} as={Fragment}>
  <Dialog 
    as="div" 
    className="relative z-[60]"
    onClose={() => setShowManagementTiles(false)}
  >
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
            <Dialog.Panel className="w-[80%] max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
              <AlertProvider>
                <DealManagementTiles
                  onViewDetail={() => {}}
                  onHealthCheck={() => {}}
                  onOptimize={() => {}}
                  selectedDealId={selectedDeals[0]}
                />
              </AlertProvider>
              <Button
                variant="ghost"
                size="sm"
                className="absolute top-4 right-4"
                onClick={() => setShowManagementTiles(false)}
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

    </div>
  );
};