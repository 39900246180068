// src/components/deals/management/views/AccountView.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  AreaChart,
  DonutChart,
  BarChart,
  Grid,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@tremor/react';
import {
  BuildingOfficeIcon,
  ChartBarIcon,
  SparklesIcon,
  ClockIcon,
  UserGroupIcon,
  DocumentCheckIcon,
  ArrowTrendingUpIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample account metrics data
const accountMetrics = {
  summary: {
    activeDeals: 48,
    totalRevenue: 3200000,
    averageHealth: 84,
    qbrStatus: {
      completed: 12,
      pending: 4,
      upcoming: 8
    }
  },
  topAccounts: [
    {
      id: 'ACC001',
      name: 'MediaCom',
      type: 'Agency',
      activeDeals: 24,
      dealHealth: 88,
      revenue: 1500000,
      trend: 12,
      nextQBR: '2024-04-15',
      performance: {
        deals: [85, 88, 92, 90, 88, 92],
        revenue: [1200000, 1300000, 1400000, 1450000, 1500000],
      }
    },
    {
      id: 'ACC002',
      name: 'Mindshare',
      type: 'Agency',
      activeDeals: 18,
      dealHealth: 82,
      revenue: 1200000,
      trend: 8,
      nextQBR: '2024-04-22',
      performance: {
        deals: [80, 82, 85, 82, 84, 82],
        revenue: [1000000, 1100000, 1150000, 1200000, 1200000],
      }
    },
    // More accounts...
  ],
  performance: {
    efficiency: {
      launchSuccess: 92,
      optimizationResponse: 88,
      clientSatisfaction: 4.5
    },
    trends: [
      {
        date: '2024-01',
        'Deal Health': 80,
        'Revenue': 2800000,
        'Success Rate': 88
      },
      {
        date: '2024-02',
        'Deal Health': 82,
        'Revenue': 3000000,
        'Success Rate': 90
      },
      {
        date: '2024-03',
        'Deal Health': 84,
        'Revenue': 3200000,
        'Success Rate': 92
      }
    ],
    qbr: {
      completed: [
        {
          date: '2024-03-01',
          account: 'MediaCom',
          metrics: {
            health: 88,
            revenue: 1500000,
            optimization: 92
          },
          actions: 5,
          status: 'completed'
        }
        // More QBR records...
      ],
      upcoming: [
        {
          date: '2024-04-15',
          account: 'Mindshare',
          metrics: {
            health: 82,
            revenue: 1200000,
            optimization: 85
          },
          actions: 0,
          status: 'scheduled'
        }
        // More upcoming QBRs...
      ]
    }
  },
  recommendations: [
    {
      id: 'REC001',
      type: 'QBR Preparation',
      account: 'MediaCom',
      description: 'Review performance trends before upcoming QBR',
      impact: 'high',
      aiSuggestion: 'Focus on revenue growth from automotive segment'
    },
    {
      id: 'REC002',
      type: 'Health Check',
      account: 'Mindshare',
      description: 'Schedule health check for underperforming deals',
      impact: 'medium',
      aiSuggestion: 'Batch optimize retail vertical deals'
    }
    // More recommendations...
  ]
};

export function AccountView() {
  const [selectedAccount, setSelectedAccount] = useState<string>(accountMetrics.topAccounts[0].id);
  const [selectedTab, setSelectedTab] = useState<string>('overview');
  
  return (
    <div className="space-y-6">
      {/* Account Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Active Deals</Text>
              <Metric>{accountMetrics.summary.activeDeals}</Metric>
              <Text className="text-sm text-gray-500">Across all accounts</Text>
            </div>
            <Badge color="blue">+8% MoM</Badge>
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Total Revenue</Text>
              <Metric>${(accountMetrics.summary.totalRevenue / 1000000).toFixed(1)}M</Metric>
              <Text className="text-sm text-gray-500">Current quarter</Text>
            </div>
            <Badge color="green">+12% QoQ</Badge>
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Average Health</Text>
              <Metric>{accountMetrics.summary.averageHealth}</Metric>
              <Text className="text-sm text-gray-500">Portfolio score</Text>
            </div>
            <Badge color="green">↑ 3 pts</Badge>
          </div>
          <ProgressBar
            value={accountMetrics.summary.averageHealth}
            color="blue"
            className="mt-3"
          />
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>QBR Status</Text>
              <div className="mt-2 space-y-2">
                <div className="flex justify-between">
                  <Text className="text-sm">Completed</Text>
                  <Badge color="green">{accountMetrics.summary.qbrStatus.completed}</Badge>
                </div>
                <div className="flex justify-between">
                  <Text className="text-sm">Pending</Text>
                  <Badge color="yellow">{accountMetrics.summary.qbrStatus.pending}</Badge>
                </div>
                <div className="flex justify-between">
                  <Text className="text-sm">Upcoming</Text>
                  <Badge color="blue">{accountMetrics.summary.qbrStatus.upcoming}</Badge>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {/* Account Performance */}
      <Card>
        <div className="p-6">
          <TabGroup>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>QBR Planning</Tab>
              <Tab>Recommendations</Tab>
            </TabList>
            
            <TabPanels>
              {/* Overview Panel */}
              <TabPanel>
                <div className="mt-6 space-y-6">
                  {/* Account List */}
                  <div className="space-y-4">
                    {accountMetrics.topAccounts.map((account) => (
                      <Card 
                        key={account.id}
                        className={`cursor-pointer transition-all duration-200 ${
                          selectedAccount === account.id ? 'ring-2 ring-blue-500' : ''
                        }`}
                        onClick={() => setSelectedAccount(account.id)}
                      >
                        <div className="p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <div className="flex items-center space-x-2">
                                <BuildingOfficeIcon className="h-5 w-5 text-gray-500" />
                                <Title className="text-lg">{account.name}</Title>
                                <Badge color="gray">{account.type}</Badge>
                              </div>
                              <Text className="text-gray-500 mt-1">
                                {account.activeDeals} active deals
                              </Text>
                            </div>
                            <div className="text-right">
                              <div className="flex items-baseline space-x-2">
                                <Metric>${(account.revenue / 1000000).toFixed(1)}M</Metric>
                                <Badge color={account.trend > 0 ? 'green' : 'red'}>
                                  {account.trend > 0 ? '+' : ''}{account.trend}%
                                </Badge>
                              </div>
                              <Text className="text-sm text-gray-500">
                                Next QBR: {new Date(account.nextQBR).toLocaleDateString()}
                              </Text>
                            </div>
                          </div>

                          <div className="mt-4 grid grid-cols-2 gap-4">
                            <div>
                              <Text className="text-sm text-gray-600">Deal Health Trend</Text>
                              <AreaChart
                                className="h-20 mt-2"
                                data={account.performance.deals.map((value, index) => ({
                                  date: index.toString(),
                                  Health: value
                                }))}
                                index="date"
                                categories={['Health']}
                                colors={['blue']}
                                showXAxis={false}
                                showYAxis={false}
                                showLegend={false}
                              />
                            </div>
                            <div>
                              <Text className="text-sm text-gray-600">Revenue Trend</Text>
                              <AreaChart
                                className="h-20 mt-2"
                                data={account.performance.revenue.map((value, index) => ({
                                  date: index.toString(),
                                  Revenue: value
                                }))}
                                index="date"
                                categories={['Revenue']}
                                colors={['green']}
                                showXAxis={false}
                                showYAxis={false}
                                showLegend={false}
                              />
                            </div>
                          </div>

                          <div className="mt-4 flex space-x-2">
                            <Button variant="secondary" size="sm">
                              <ChartBarIcon className="h-4 w-4 mr-1" />
                              View Details
                            </Button>
                            <Button variant="secondary" size="sm">
                              <SparklesIcon className="h-4 w-4 mr-1" />
                              Optimize
                            </Button>
                            <Button variant="primary" size="sm">
                              <CalendarIcon className="h-4 w-4 mr-1" />
                              Schedule QBR
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </TabPanel>

              {/* QBR Planning Panel */}
              <TabPanel>
                <div className="mt-6 space-y-6">
                  {/* Completed QBRs */}
                  <div>
                    <Title>Completed Reviews</Title>
                    <div className="mt-4 space-y-4">
                      {accountMetrics.performance.qbr.completed.map((qbr, index) => (
                        <Card key={index}>
                          <div className="p-4">
                            <div className="flex items-center justify-between">
                              <div>
                                <Text>{qbr.account}</Text>
                                <Text className="text-sm text-gray-500">
                                  {new Date(qbr.date).toLocaleDateString()}
                                </Text>
                              </div>
                              <Badge color="green">
                                {qbr.actions} actions completed
                              </Badge>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>

                  {/* Upcoming QBRs */}
                  <div>
                    <Title>Upcoming Reviews</Title>
                    <div className="mt-4 space-y-4">
                      {accountMetrics.performance.qbr.upcoming.map((qbr, index) => (
                        <Card key={index}>
                          <div className="p-4">
                            <div className="flex items-center justify-between">
                              <div>
                                <Text>{qbr.account}</Text>
                                <Text className="text-sm text-gray-500">
                                  {new Date(qbr.date).toLocaleDateString()}
                                </Text>
                              </div>
                              <Button variant="secondary" size="sm">
                                Prepare Review
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Recommendations Panel */}
              <TabPanel>
                <div className="mt-6 space-y-4">
                  {accountMetrics.recommendations.map((rec) => (
                    <Card key={rec.id}>
                      <div className="p-4">
                        <div className="flex items-start justify-between">
                          <div>
                            <div className="flex items-center space-x-2">
                              <Text className="font-medium">{rec.type}</Text>
                              <Badge 
                                color={
                                  rec.impact === 'high' ? 'red' :
                                  rec.impact === 'medium' ? 'yellow' : 'blue'
                                }
                              >
                                {rec.impact} impact
                              </Badge>
                            </div>
                            <Text className="text-gray-500 mt-1">
                              {rec.description}
                            </Text>
                            <div className="mt-2 flex items-center space-x-2 text-blue-600">
                              <SparklesIcon className="h-4 w-4" />
                              <Text className="text-sm">{rec.aiSuggestion}</Text>
                            </div>
                          </div>
                          <Button variant="secondary" size="sm">
                            Take Action
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </Card>
    </div>
  );
}