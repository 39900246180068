// src/components/smartFinder/core/NavigationTree.tsx

import { useState, useEffect, useMemo } from 'react';
import { 
  ChevronRightIcon, 
  ChevronDownIcon,
  FolderIcon,
  BuildingOfficeIcon,
  UsersIcon,
  GlobeAltIcon,
  TagIcon,
  BriefcaseIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';
import { Text, Badge } from '@tremor/react';
import { EntityType } from '../../../types/smartFinder';

interface NavigationNode {
  id: string;
  label: string;
  type: 'folder' | 'item';
  count?: number;
  icon?: React.ComponentType<any>;
  children?: NavigationNode[];
  metrics?: {
    reach?: number;
    performance?: number;
  };
}

interface NavigationTreeProps {
  entityType: EntityType;
  currentPath: string[];
  onNavigate: (path: string[]) => void;
  selectedIds: string[];
  onSelectionChange: (id: string) => void;
}

// Entity-specific navigation structures
const dealNavigation: NavigationNode[] = [
  {
    id: 'status',
    label: 'By Status',
    type: 'folder',
    icon: TagIcon,
    children: [
      { 
        id: 'active',
        label: 'Active Deals',
        type: 'folder',
        count: 45,
        metrics: { reach: 25000000, performance: 82 }
      },
      { 
        id: 'pending',
        label: 'Pending Approval',
        type: 'folder',
        count: 12
      },
      { 
        id: 'completed',
        label: 'Completed',
        type: 'folder',
        count: 78
      }
    ]
  },
  {
    id: 'performance',
    label: 'By Performance',
    type: 'folder',
    icon: ChartBarIcon,
    children: [
      { 
        id: 'high-performing',
        label: 'High Performing',
        type: 'folder',
        count: 28,
        metrics: { performance: 92 }
      },
      { 
        id: 'at-risk',
        label: 'At Risk',
        type: 'folder',
        count: 15,
        metrics: { performance: 65 }
      },
      { 
        id: 'needs-attention',
        label: 'Needs Attention',
        type: 'folder',
        count: 8
      }
    ]
  },
  {
    id: 'type',
    label: 'By Type',
    type: 'folder',
    icon: FolderIcon,
    children: [
      { 
        id: 'pmp',
        label: 'PMP',
        type: 'folder',
        count: 65
      },
      { 
        id: 'programmatic',
        label: 'Programmatic Guaranteed',
        type: 'folder',
        count: 35
      },
      { 
        id: 'preferred',
        label: 'Preferred',
        type: 'folder',
        count: 25
      }
    ]
  }
];

const audienceNavigation: NavigationNode[] = [
    {
      id: 'demographics',
      label: 'Demographics',
      type: 'folder',
      icon: UsersIcon,
      children: [
        { id: 'age', label: 'Age Groups', type: 'folder', count: 8 },
        { id: 'income', label: 'Income Levels', type: 'folder', count: 6 }
      ]
    },
    {
      id: 'interests',
      label: 'Interests',
      type: 'folder',
      icon: TagIcon,
      children: [
        { id: 'auto', label: 'Automotive', type: 'folder', count: 15 },
        { id: 'tech', label: 'Technology', type: 'folder', count: 18 }
      ]
    }
  ];
  
  const domainNavigation: NavigationNode[] = [
    {
      id: 'categories',
      label: 'Categories',
      type: 'folder',
      icon: GlobeAltIcon,
      children: [
        { 
          id: 'news',
          label: 'News',
          type: 'folder',
          count: 250,
          metrics: { reach: 45000000, performance: 85 },
          children: [
            { id: 'top-tier', label: 'Top Tier', type: 'folder', count: 45 },
            { id: 'regional', label: 'Regional', type: 'folder', count: 125 }
          ]
        },
        {
          id: 'sports',
          label: 'Sports',
          type: 'folder',
          count: 180,
          metrics: { reach: 32000000, performance: 82 },
          children: [
            { id: 'premium', label: 'Premium', type: 'folder', count: 35 },
            { id: 'niche', label: 'Niche', type: 'folder', count: 85 }
          ]
        }
      ]
    }
  ];

export function NavigationTree({ 
  entityType,
  currentPath,
  onNavigate,
  selectedIds,
  onSelectionChange
}: NavigationTreeProps) {
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const [partiallySelectedNodes, setPartiallySelectedNodes] = useState<Set<string>>(new Set());
  
  // Get the appropriate navigation structure based on entity type
  const getNavigation = () => {
    switch (entityType) {
      case 'deals':
        return dealNavigation;
      case 'audiences':
        return audienceNavigation;
      case 'domains':
        return domainNavigation;
      default:
        return [];
    }
  };

  // Calculate partially selected nodes based on selection state
  useEffect(() => {
    const partial = new Set<string>();
    const nav = getNavigation();

    const checkNode = (node: NavigationNode): boolean => {
      if (!node.children) return false;

      const childStates = node.children.map(child => {
        if (child.children) {
          return checkNode(child);
        }
        return selectedIds.includes(child.id);
      });

      const allSelected = childStates.every(Boolean);
      const someSelected = childStates.some(Boolean);

      if (someSelected && !allSelected) {
        partial.add(node.id);
      }

      return allSelected;
    };

    nav.forEach(checkNode);
    setPartiallySelectedNodes(partial);
  }, [selectedIds, entityType]);

  // Toggle folder expansion
  const toggleNode = (nodeId: string) => {
    const newExpanded = new Set(expandedNodes);
    if (newExpanded.has(nodeId)) {
      newExpanded.delete(nodeId);
    } else {
      newExpanded.add(nodeId);
    }
    setExpandedNodes(newExpanded);
  };

  // Handle folder selection (selects all children)
  const handleFolderSelect = (node: NavigationNode) => {
    if (!node.children) return;

    const allChildIds = new Set<string>();
    const getAllChildIds = (n: NavigationNode) => {
      if (n.type === 'item') {
        allChildIds.add(n.id);
      }
      n.children?.forEach(getAllChildIds);
    };
    getAllChildIds(node);

    // Check if all children are already selected
    const allSelected = Array.from(allChildIds).every(id => selectedIds.includes(id));

    if (allSelected) {
      // Deselect all children
      allChildIds.forEach(id => onSelectionChange(id));
    } else {
      // Select all children
      allChildIds.forEach(id => {
        if (!selectedIds.includes(id)) {
          onSelectionChange(id);
        }
      });
    }
  };

  // Render a single node
  const renderNode = (node: NavigationNode, level: number = 0, parentPath: string[] = []) => {
    const isExpanded = expandedNodes.has(node.id);
    const isSelected = selectedIds.includes(node.id);
    const isPartiallySelected = partiallySelectedNodes.has(node.id);
    const currentNodePath = [...parentPath, node.id];
    const Icon = node.icon || FolderIcon;

    return (
      <div key={node.id}>
        <button
          className={`
            w-full flex items-center px-2 py-1.5 text-sm
            ${isSelected ? 'bg-blue-50 text-blue-700' : 'hover:bg-gray-50'}
          `}
          style={{ paddingLeft: `${(level + 1) * 12}px` }}
          onClick={() => {
            if (node.type === 'folder') {
              toggleNode(node.id);
              handleFolderSelect(node);
            }
            onNavigate(currentNodePath);
          }}
        >
          {/* Checkbox (new) */}
          <div className="mr-2">
            <input
              type="checkbox"
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              checked={isSelected}
              onChange={() => handleFolderSelect(node)}
              aria-label={`Select ${node.label}`}
            />
          </div>

          {/* Expand/Collapse arrow */}
          {node.children && node.children.length > 0 ? (
            <span className="w-5">
              {isExpanded ? (
                <ChevronDownIcon className="h-4 w-4 text-gray-400" />
              ) : (
                <ChevronRightIcon className="h-4 w-4 text-gray-400" />
              )}
            </span>
          ) : (
            <span className="w-5" />
          )}

          {/* Icon */}
          <Icon className="h-5 w-5 text-gray-400 mr-2" />

          {/* Label and count */}
          <span className="flex-1 truncate">{node.label}</span>
          {node.count !== undefined && (
            <Badge color="gray" size="sm">
              {node.count}
            </Badge>
          )}

          {/* Partial selection indicator */}
          {isPartiallySelected && (
            <div className="h-2 w-2 bg-blue-500 rounded-full ml-2" />
          )}
        </button>

        {/* Metrics (if available) */}
        {node.metrics && (isSelected || isPartiallySelected) && (
          <div className="ml-8 mt-1 mb-2 text-xs text-gray-500">
            {node.metrics.reach && (
              <div>Reach: {(node.metrics.reach / 1000000).toFixed(1)}M</div>
            )}
            {node.metrics.performance && (
              <div>Performance: {node.metrics.performance}%</div>
            )}
          </div>
        )}

        {/* Children */}
        {isExpanded && node.children && (
          <div className="ml-2">
            {node.children.map(child => 
              renderNode(child, level + 1, currentNodePath)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-2">
      {/* Tree navigation */}
      <div className="space-y-0.5">
        {getNavigation().map(node => renderNode(node))}
      </div>
    </div>
  );
}