// src/components/smartFinder/basket/BasketPanel.tsx

import { useMemo, useState } from 'react';
import {
  ShoppingBagIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
  ShareIcon,
  TagIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { Card, Title, Text, Badge, Tab, TabGroup, TabList, TabPanels, TabPanel } from '@tremor/react';
import { Button } from '../../ui/Button';
import { EntityType } from '../../../types/smartFinder';

interface BasketItem {
  id: string;
  type: EntityType;
  name: string;
  metrics: Record<string, any>;
}

interface BasketPanelProps {
  selectedItems: BasketItem[];
  entityType: EntityType;
  onClose: () => void;
  onRemove: (id: string) => void;
  onSave?: () => void;
  onShare?: () => void;
  onCreateBundle?: (items: BasketItem[]) => void;
}

export function BasketPanel({
  selectedItems,
  onClose,
  onRemove,
  onSave,
  onShare,
  onCreateBundle
}: BasketPanelProps) {
  const [groupBy, setGroupBy] = useState<'type' | 'none'>('type');
  const [collectionName, setCollectionName] = useState('');

  // Group and calculate metrics
  const { groupedItems, totalMetrics } = useMemo(() => {
    const groups: Record<string, BasketItem[]> = {
      deals: [],
      audiences: [],
      domains: []
    };

    const totals = {
      reach: 0,
      budget: 0,
      viewability: 0
    };

    selectedItems.forEach(item => {
      // Group items
      groups[item.type].push(item);

      // Aggregate metrics
      totals.reach += item.metrics.reach || 0;
      totals.budget += item.metrics.budget || 0;
      totals.viewability += item.metrics.viewability || 0;
    });

    // Average viewability
    if (selectedItems.length > 0) {
      totals.viewability /= selectedItems.length;
    }

    return {
      groupedItems: groups,
      totalMetrics: totals
    };
  }, [selectedItems]);

  if (!selectedItems?.length) {
    return (
      <div className="text-center p-8">
        <ShoppingBagIcon className="h-12 w-12 mx-auto text-gray-400" />
        <Text className="mt-4">Your basket is empty</Text>
      </div>
    );
  }
  
  const renderMetricsSummary = () => (
    <Card className="mt-4">
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Text className="text-gray-500">Total Reach</Text>
            <Text className="text-lg font-medium">
              {(totalMetrics.reach / 1000000).toFixed(1)}M
            </Text>
          </div>
          <div>
            <Text className="text-gray-500">Total Budget</Text>
            <Text className="text-lg font-medium">
              ${(totalMetrics.budget / 1000).toFixed(1)}K
            </Text>
          </div>
        </div>
        <div>
          <Text className="text-gray-500">Avg. Viewability</Text>
          <Text className="text-lg font-medium">
            {totalMetrics.viewability.toFixed(1)}%
          </Text>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <ShoppingBagIcon className="h-5 w-5 text-gray-500" />
            <Title>Selection Basket</Title>
            <Badge color="blue">
              {selectedItems.length} item{selectedItems.length !== 1 ? 's' : ''}
            </Badge>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-y-auto p-4">
        {selectedItems.length === 0 ? (
          <div className="text-center text-gray-500 mt-8">
            <ShoppingBagIcon className="h-12 w-12 mx-auto mb-4" />
            <Text>Your basket is empty</Text>
            <Text className="text-sm mt-2">
              Select items to add them to your basket
            </Text>
          </div>
        ) : (
          <div className="space-y-4">
            {/* Metrics Summary */}
            {renderMetricsSummary()}

            {/* Save Collection Input */}
            <div className="flex space-x-2">
              <input
                type="text"
                className="flex-1 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Collection name..."
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
              <Button
                variant="secondary"
                disabled={!collectionName}
                onClick={() => onSave?.()}
              >
                <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
                Save
              </Button>
            </div>

            {/* Group By Toggle */}
            <div className="flex justify-end">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setGroupBy(prev => prev === 'type' ? 'none' : 'type')}
              >
                <TagIcon className="h-4 w-4 mr-2" />
                {groupBy === 'type' ? 'Show All' : 'Group by Type'}
              </Button>
            </div>

            {/* Items List */}
            {groupBy === 'type' ? (
              <TabGroup>
                <TabList>
                  <Tab>Deals ({groupedItems.deals.length})</Tab>
                  <Tab>Audiences ({groupedItems.audiences.length})</Tab>
                  <Tab>Domains ({groupedItems.domains.length})</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <div className="space-y-2 mt-4">
                      {groupedItems.deals.map(item => (
                        <BasketItemCard
                          key={item.id}
                          item={item}
                          onRemove={() => onRemove(item.id)}
                        />
                      ))}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="space-y-2 mt-4">
                      {groupedItems.audiences.map(item => (
                        <BasketItemCard
                          key={item.id}
                          item={item}
                          onRemove={() => onRemove(item.id)}
                        />
                      ))}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="space-y-2 mt-4">
                      {groupedItems.domains.map(item => (
                        <BasketItemCard
                          key={item.id}
                          item={item}
                          onRemove={() => onRemove(item.id)}
                        />
                      ))}
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            ) : (
              <div className="space-y-2">
                {selectedItems.map(item => (
                  <BasketItemCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item.id)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Footer Actions */}
      {selectedItems.length > 0 && (
        <div className="p-4 border-t border-gray-200">
          <div className="grid grid-cols-2 gap-3">
            <Button 
              variant="secondary"
              onClick={onShare}
            >
              <ShareIcon className="h-4 w-4 mr-2" />
              Share
            </Button>
            <Button
              variant="primary"
              onClick={() => onCreateBundle?.(selectedItems)}
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              Create Bundle
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

// Basket Item Card Component
interface BasketItemCardProps {
  item: BasketItem;
  onRemove: () => void;
}

function BasketItemCard({ item, onRemove }: BasketItemCardProps) {
  return (
    <Card className="!p-3">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="flex items-center space-x-2">
            <Text className="font-medium truncate">{item.name}</Text>
            <Badge color={
              item.type === 'deals' ? 'blue' :
              item.type === 'audiences' ? 'purple' : 'emerald'
            } size="sm">
              {item.type}
            </Badge>
          </div>
          <div className="mt-1 text-sm text-gray-500">
            {item.type === 'deals' && (
              <div className="flex space-x-4">
                <span>${(item.metrics.budget / 1000).toFixed(1)}K</span>
                <span>{item.metrics.viewability}% Viewability</span>
              </div>
            )}
            {item.type === 'audiences' && (
              <div className="flex space-x-4">
                <span>{(item.metrics.size / 1000000).toFixed(1)}M Users</span>
                <span>{item.metrics.matchRate}% Match Rate</span>
              </div>
            )}
            {item.type === 'domains' && (
              <div className="flex space-x-4">
                <span>{(item.metrics.reach / 1000000).toFixed(1)}M Reach</span>
                <span>{item.metrics.quality}% Quality</span>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={onRemove}
        >
          <XMarkIcon className="h-4 w-4" />
        </Button>
      </div>
    </Card>
  );
}