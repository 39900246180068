// src/components/deals/management/metrics/DealHealthMetrics.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  TabGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  AreaChart,
} from '@tremor/react';
import {
  ShieldCheckIcon,
  ExclamationTriangleIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  SignalIcon,
  CurrencyDollarIcon,
  BuildingStorefrontIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

// Define health metric thresholds
const healthThresholds = {
  bidRequest: { min: 1000, target: 5000, critical: 500 },
  bidResponse: { min: 60, target: 80, critical: 40 }, // percentage
  discardRate: { max: 25, target: 15, critical: 30 }, // percentage
  winRate: { min: 15, target: 25, critical: 10 }, // percentage
  spendRate: { min: 80, target: 100, critical: 60 }, // percentage of target
  domainDelivery: { min: 70, target: 90, critical: 50 } // percentage
};

// Sample metrics data
const metricsData = {
  current: {
    bidRequest: 4200,
    bidResponse: 72,
    discardRate: 18,
    winRate: 22,
    spendRate: 85,
    domainDelivery: 82,
    healthScore: 84
  },
  history: [
    {
      date: '2024-01',
      'Health Score': 80,
      'Bid Response': 68,
      'Win Rate': 18,
      'Discard Rate': 22
    },
    {
      date: '2024-02',
      'Health Score': 82,
      'Bid Response': 70,
      'Win Rate': 20,
      'Discard Rate': 20
    },
    {
      date: '2024-03',
      'Health Score': 84,
      'Bid Response': 72,
      'Win Rate': 22,
      'Discard Rate': 18
    }
  ]
};

// Health score calculation helper
const getHealthStatus = (metric: string, value: number) => {
  const threshold = healthThresholds[metric as keyof typeof healthThresholds];
  if (!threshold) return { status: 'neutral', color: 'gray' };

  if ('critical' in threshold && value <= threshold.critical) {
    return { status: 'critical', color: 'red' };
  }
  
  if ('max' in threshold) {
    if (value <= threshold.target) return { status: 'healthy', color: 'green' };
    if (value <= threshold.max) return { status: 'warning', color: 'yellow' };
    return { status: 'critical', color: 'red' };
  }
  
  if (value >= threshold.target) return { status: 'healthy', color: 'green' };
  if (value >= threshold.min) return { status: 'warning', color: 'yellow' };
  return { status: 'critical', color: 'red' };
};

export function DealHealthMetrics() {
  const [selectedMetric, setSelectedMetric] = useState('overall');

  const MetricCard = ({ 
    title, 
    value, 
    target, 
    trend, 
    icon: Icon,
    metric
  }: {
    title: string;
    value: number;
    target: number;
    trend?: number;
    icon: any;
    metric: string;
  }) => {
    const { status, color } = getHealthStatus(metric, value);
    
    return (
      <Card className="p-4">
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center space-x-2">
              <Icon className="h-5 w-5 text-gray-500" />
              <Text>{title}</Text>
            </div>
            <div className="mt-2 flex items-baseline space-x-2">
              <Metric>{value}%</Metric>
              {trend && (
                <Badge 
                  color={trend > 0 ? 'green' : 'red'}
                  icon={trend > 0 ? ArrowTrendingUpIcon : ArrowTrendingDownIcon}
                >
                  {trend > 0 ? '+' : ''}{trend}%
                </Badge>
              )}
            </div>
          </div>
          <Badge color={color as any}>{status}</Badge>
        </div>
        <div className="mt-4">
          <div className="flex justify-between text-sm mb-1">
            <Text>Progress</Text>
            <Text>Target: {target}%</Text>
          </div>
          <ProgressBar
            value={(value / target) * 100}
            color={color as any}
          />
        </div>
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      {/* Overall Health Score */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <ShieldCheckIcon className="h-6 w-6 text-blue-600" />
              <Title>Overall Health Score</Title>
            </div>
            <Badge color="green">Trending Up</Badge>
          </div>
          
          <div className="mt-4 flex items-baseline space-x-4">
            <Metric>{metricsData.current.healthScore}</Metric>
            <div className="flex items-center space-x-2">
              <Badge color="green" icon={ArrowTrendingUpIcon}>
                +4 pts
              </Badge>
              <Text className="text-gray-500">vs last period</Text>
            </div>
          </div>

          <AreaChart
            className="h-28 mt-4"
            data={metricsData.history}
            index="date"
            categories={['Health Score']}
            colors={['blue']}
            showXAxis={false}
            showYAxis={false}
            showLegend={false}
          />
        </div>
      </Card>

      {/* Health Components */}
      <Card>
        <div className="p-4">
          <TabGroup>
            <TabList>
              <Tab>Key Metrics</Tab>
              <Tab>Trends</Tab>
              <Tab>Analysis</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <MetricCard
                    title="Bid Response Rate"
                    value={metricsData.current.bidResponse}
                    target={healthThresholds.bidResponse.target}
                    trend={2}
                    icon={SignalIcon}
                    metric="bidResponse"
                  />
                  <MetricCard
                    title="Win Rate"
                    value={metricsData.current.winRate}
                    target={healthThresholds.winRate.target}
                    trend={4}
                    icon={ArrowTrendingUpIcon}
                    metric="winRate"
                  />
                  <MetricCard
                    title="Discard Rate"
                    value={metricsData.current.discardRate}
                    target={healthThresholds.discardRate.target}
                    trend={-3}
                    icon={ExclamationTriangleIcon}
                    metric="discardRate"
                  />
                  <MetricCard
                    title="Spend Rate"
                    value={metricsData.current.spendRate}
                    target={healthThresholds.spendRate.target}
                    trend={5}
                    icon={CurrencyDollarIcon}
                    metric="spendRate"
                  />
                  <MetricCard
                    title="Domain Delivery"
                    value={metricsData.current.domainDelivery}
                    target={healthThresholds.domainDelivery.target}
                    trend={2}
                    icon={BuildingStorefrontIcon}
                    metric="domainDelivery"
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <div className="mt-4">
                  <Card>
                    <Title>Performance Trends</Title>
                    <AreaChart
                      className="h-80 mt-4"
                      data={metricsData.history}
                      index="date"
                      categories={['Bid Response', 'Win Rate', 'Discard Rate']}
                      colors={['blue', 'green', 'red']}
                    />
                  </Card>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="mt-4 space-y-4">
                  {/* Add analysis content */}
                  <Card className="p-4">
                    <div className="flex items-start space-x-3">
                      <SparklesIcon className="h-5 w-5 text-blue-500 mt-1" />
                      <div>
                        <Text className="font-medium">AI Analysis</Text>
                        <Text className="text-gray-600 mt-1">
                          Health score shows positive trend with notable improvements in bid response rate.
                          Consider optimizing discard rate which is trending slightly above target.
                        </Text>
                      </div>
                    </div>
                  </Card>
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </Card>
    </div>
  );
}