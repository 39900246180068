// src/components/deals/management/alerts/AlertNotification.tsx

import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import {
  Card,
  Text,
  Badge
} from '@tremor/react';
import {
  BellIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

interface AlertNotificationProps {
  id: string;
  type: 'health' | 'monitoring' | 'system';
  severity: 'critical' | 'high' | 'medium' | 'low';
  title: string;
  message: string;
  timestamp: string;
  onDismiss: (id: string) => void;
  onAction?: () => void;
  actionLabel?: string;
}

export function AlertNotification({
  id,
  type,
  severity,
  title,
  message,
  timestamp,
  onDismiss,
  onAction,
  actionLabel
}: AlertNotificationProps) {
  const [isShowing, setIsShowing] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowing(false);
      onDismiss(id);
    }, 5000);

    return () => clearTimeout(timer);
  }, [id, onDismiss]);

  if (!isShowing) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <Card className={`w-96 shadow-lg ${
        severity === 'critical' ? 'border-red-500' :
        severity === 'high' ? 'border-orange-500' :
        severity === 'medium' ? 'border-yellow-500' :
        'border-blue-500'
      }`}>
        <div className="p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-start space-x-3">
              {severity === 'critical' ? (
                <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
              ) : (
                <BellIcon className="h-5 w-5 text-blue-500" />
              )}
              <div>
                <Text className="font-medium">{title}</Text>
                <Text className="text-sm text-gray-600 mt-1">
                  {message}
                </Text>
              </div>
            </div>
            <button
              onClick={() => {
                setIsShowing(false);
                onDismiss(id);
              }}
              className="text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          {onAction && actionLabel && (
            <div className="mt-4 flex justify-end space-x-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  setIsShowing(false);
                  onDismiss(id);
                }}
              >
                Dismiss
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={onAction}
              >
                {actionLabel}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}