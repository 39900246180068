// src/components/deals/management/alerts/MonitoringAlerts.tsx

import { useState, useEffect } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  ProgressBar,
  AreaChart,
  TabGroup,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Select,
  SelectItem,
  Switch
} from '@tremor/react';
import {
  SignalIcon,
  ShieldCheckIcon,
  ArrowPathIcon,
  BoltIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Define monitoring interfaces
interface MonitoringCheck {
  id: string;
  type: 'spend' | 'response' | 'integration' | 'performance';
  entity: {
    type: string;
    id: string;
    name: string;
  };
  thresholds: {
    warning: number;
    critical: number;
  };
  currentValue: number;
  status: 'healthy' | 'warning' | 'critical';
  lastChecked: string;
  history: Array<{
    timestamp: string;
    value: number;
  }>;
}

interface MonitoringRule {
  id: string;
  name: string;
  description: string;
  type: string;
  threshold: {
    warning: number;
    critical: number;
  };
  enabled: boolean;
  entities: string[];
}

// Sample monitoring data
const monitoringChecks: MonitoringCheck[] = [
  {
    id: 'CHK001',
    type: 'spend',
    entity: {
      type: 'deal',
      id: 'DEAL123',
      name: 'Premium Auto Campaign'
    },
    thresholds: {
      warning: 1000,
      critical: 500
    },
    currentValue: 450,
    status: 'critical',
    lastChecked: '2024-03-26T10:00:00Z',
    history: [
      { timestamp: '2024-03-26T09:00:00Z', value: 1200 },
      { timestamp: '2024-03-26T09:30:00Z', value: 800 },
      { timestamp: '2024-03-26T10:00:00Z', value: 450 }
    ]
  },
  // Add more checks...
];

const monitoringRules: MonitoringRule[] = [
  {
    id: 'RULE001',
    name: 'Zero Spend Detection',
    description: 'Alert when spend drops to zero for more than 48 hours',
    type: 'spend',
    threshold: {
      warning: 48,
      critical: 72
    },
    enabled: true,
    entities: ['all']
  },
  // Add more rules...
];

export function MonitoringAlerts() {
  const [activeChecks, setActiveChecks] = useState(monitoringChecks);
  const [rules, setRules] = useState(monitoringRules);
  const [selectedView, setSelectedView] = useState('active');
  const [timeRange, setTimeRange] = useState('24h');

  const MonitoringCard = ({ check }: { check: MonitoringCheck }) => (
    <Card className={`${
      check.status === 'critical' ? 'border-red-200' :
      check.status === 'warning' ? 'border-yellow-200' :
      'border-green-200'
    }`}>
      <div className="p-4">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center space-x-2">
              <SignalIcon className={`h-5 w-5 ${
                check.status === 'critical' ? 'text-red-500' :
                check.status === 'warning' ? 'text-yellow-500' :
                'text-green-500'
              }`} />
              <Text className="font-medium capitalize">{check.type} Monitor</Text>
            </div>
            <Text className="text-sm text-gray-600 mt-1">
              {check.entity.name}
            </Text>
          </div>
          <Badge color={
            check.status === 'critical' ? 'red' :
            check.status === 'warning' ? 'yellow' :
            'green'
          }>
            {check.status}
          </Badge>
        </div>

        {/* Metrics */}
        <div className="mt-4">
          <div className="flex justify-between items-baseline mb-2">
            <Text className="text-sm text-gray-600">Current Value</Text>
            <div className="flex items-baseline space-x-2">
              <Text className="font-medium">{check.currentValue}</Text>
              <Text className="text-sm text-gray-500">
                vs {check.thresholds.critical} critical
              </Text>
            </div>
          </div>
          <ProgressBar
            value={(check.currentValue / check.thresholds.critical) * 100}
            color={
              check.status === 'critical' ? 'red' :
              check.status === 'warning' ? 'yellow' :
              'green'
            }
          />
        </div>

        {/* History Chart */}
        <div className="mt-4">
          <Text className="text-sm text-gray-600 mb-2">Trend</Text>
          <AreaChart
            className="h-24"
            data={check.history.map(h => ({
              date: new Date(h.timestamp).toLocaleTimeString(),
              value: h.value
            }))}
            index="date"
            categories={['value']}
            colors={[
              check.status === 'critical' ? 'red' :
              check.status === 'warning' ? 'yellow' :
              'green'
            ]}
            showXAxis={false}
            showYAxis={false}
            showLegend={false}
          />
        </div>

        {/* Actions */}
        <div className="mt-4 flex justify-end space-x-2">
          <Button variant="secondary" size="sm">
            <ArrowPathIcon className="h-4 w-4 mr-1" />
            Recheck
          </Button>
          <Button variant="primary" size="sm">
            View Details
          </Button>
        </div>
      </div>
    </Card>
  );

  const RuleCard = ({ rule }: { rule: MonitoringRule }) => (
    <Card>
      <div className="p-4">
        <div className="flex items-start justify-between">
          <div>
            <Text className="font-medium">{rule.name}</Text>
            <Text className="text-sm text-gray-600 mt-1">
              {rule.description}
            </Text>
          </div>
          <Switch
            checked={rule.enabled}
            onChange={() => {
              setRules(prev => prev.map(r => 
                r.id === rule.id ? { ...r, enabled: !r.enabled } : r
              ));
            }}
          />
        </div>

        <div className="mt-4 flex items-center space-x-4">
          <Badge color="blue">{rule.type}</Badge>
          <Text className="text-sm">
            Warning: {rule.threshold.warning}h
          </Text>
          <Text className="text-sm">
            Critical: {rule.threshold.critical}h
          </Text>
        </div>

        <div className="mt-4 flex justify-end">
          <Button variant="secondary" size="sm">
            Configure
          </Button>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <Title>Monitoring Alerts</Title>
          <Text className="text-gray-500">
            Proactive monitoring and system health checks
          </Text>
        </div>
        <div className="flex space-x-4">
          <Select
            value={timeRange}
            onValueChange={setTimeRange}
            className="w-36"
          >
            <SelectItem value="24h">Last 24h</SelectItem>
            <SelectItem value="7d">Last 7 days</SelectItem>
            <SelectItem value="30d">Last 30 days</SelectItem>
          </Select>
          <Button variant="primary">
            Configure Monitors
          </Button>
        </div>
      </div>

      {/* Monitoring Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {[
          { label: 'Active Monitors', value: 24, trend: 2, icon: SignalIcon, color: 'blue' },
          { label: 'Critical Alerts', value: 3, trend: -1, icon: ExclamationTriangleIcon, color: 'red' },
          { label: 'Warning Alerts', value: 8, trend: 1, icon: BoltIcon, color: 'yellow' },
          { label: 'Healthy Checks', value: 13, trend: 4, icon: ShieldCheckIcon, color: 'green' }
        ].map((stat) => (
          <Card key={stat.label}>
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <stat.icon className={`h-5 w-5 text-${stat.color}-500`} />
                  <Text>{stat.label}</Text>
                </div>
                <Badge color={stat.color as any}>
                  {stat.trend > 0 ? '+' : ''}{stat.trend}
                </Badge>
              </div>
              <Text className="text-2xl font-bold mt-2">
                {stat.value}
              </Text>
            </div>
          </Card>
        ))}
      </div>

      {/* Main Content */}
      <TabGroup>
        <TabList>
          <Tab>Active Monitors</Tab>
          <Tab>Monitoring Rules</Tab>
          <Tab>Health Checks</Tab>
        </TabList>

        <TabPanels>
          {/* Active Monitors Panel */}
          <TabPanel>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {activeChecks.map(check => (
                <MonitoringCard key={check.id} check={check} />
              ))}
            </div>
          </TabPanel>

          {/* Monitoring Rules Panel */}
          <TabPanel>
            <div className="p-4 space-y-4">
              {rules.map(rule => (
                <RuleCard key={rule.id} rule={rule} />
              ))}
            </div>
          </TabPanel>

          {/* Health Checks Panel */}
          <TabPanel>
            {/* Implement health checks view */}
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}