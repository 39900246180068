// src/components/deals/management/views/SupportView.tsx

import { useState } from 'react';
import {
 Card,
 Title,
 Text,
 Badge,
 ProgressBar,
 AreaChart,
 TabGroup,
 Tab,
 TabList,
 TabPanels,
 TabPanel
} from '@tremor/react';
import {
 ShieldCheckIcon,
 WrenchScrewdriverIcon,
 ExclamationTriangleIcon,
 SignalIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

const supportData = {
 systemHealth: {
   integrationStatus: 94,
   dspConnections: {
     active: 42,
     issues: 3
   },
   qps: {
     current: 2500,
     capacity: 3000,
     trend: [2200, 2300, 2500]
   }
 },
 activeIssues: [
   {
     id: 'ISS1',
     title: 'DSP Connection Timeout',
     priority: 'high',
     affectedDeals: 3,
     timeElapsed: '2h',
     status: 'investigating'
   },
   {
     id: 'ISS2',
     title: 'Bid Response Latency',
     priority: 'medium',
     affectedDeals: 8,
     timeElapsed: '1h',
     status: 'monitoring'
   }
 ],
 setupValidation: {
   pendingReviews: 5,
   recentValidations: 12,
   accuracyRate: 96
 },
 performance: {
   responseTime: 45, // ms
   uptime: 99.9,
   errorRate: 0.2
 }
};

export function SupportView() {
 return (
   <div className="space-y-6">
     {/* System Health Metrics */}
     <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
       <Card>
         <div className="p-4">
           <Text>Integration Health</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{supportData.systemHealth.integrationStatus}%</Text>
             <ProgressBar 
               value={supportData.systemHealth.integrationStatus}
               color={supportData.systemHealth.integrationStatus > 90 ? 'green' : 'yellow'}
             />
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>DSP Connections</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{supportData.systemHealth.dspConnections.active}</Text>
             <Badge color={supportData.systemHealth.dspConnections.issues === 0 ? 'green' : 'red'}>
               {supportData.systemHealth.dspConnections.issues} issues
             </Badge>
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>Current QPS</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{supportData.systemHealth.qps.current}</Text>
             <Text className="text-sm text-gray-500">
               of {supportData.systemHealth.qps.capacity}
             </Text>
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>System Uptime</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{supportData.performance.uptime}%</Text>
             <Badge color="green">Healthy</Badge>
           </div>
         </div>
       </Card>
     </div>

     <TabGroup>
       <TabList>
         <Tab>Active Issues</Tab>
         <Tab>Setup Validation</Tab>
         <Tab>Performance</Tab>
       </TabList>

       <TabPanels>
         <TabPanel>
           <div className="mt-4 space-y-4">
             {supportData.activeIssues.map(issue => (
               <Card key={issue.id}>
                 <div className="p-4">
                   <div className="flex items-center justify-between">
                     <div>
                       <div className="flex items-center space-x-2">
                         <ExclamationTriangleIcon 
                           className={`h-5 w-5 ${
                             issue.priority === 'high' ? 'text-red-500' : 'text-yellow-500'
                           }`} 
                         />
                         <Title>{issue.title}</Title>
                         <Badge color={issue.priority === 'high' ? 'red' : 'yellow'}>
                           {issue.priority}
                         </Badge>
                       </div>
                       <div className="mt-2 flex items-center space-x-4">
                         <Text>Affected Deals: {issue.affectedDeals}</Text>
                         <Text>Time Elapsed: {issue.timeElapsed}</Text>
                         <Badge color="blue">{issue.status}</Badge>
                       </div>
                     </div>
                     <Button variant="primary" size="sm">
                       Investigate
                     </Button>
                   </div>
                 </div>
               </Card>
             ))}
           </div>
         </TabPanel>

         <TabPanel>
           <div className="mt-4 grid grid-cols-2 gap-6">
             <Card>
               <div className="p-4">
                 <Title>Setup Validation Stats</Title>
                 <div className="mt-4 space-y-4">
                   <div>
                     <Text>Pending Reviews</Text>
                     <div className="mt-2 flex items-center space-x-2">
                       <Text className="text-2xl font-bold">
                         {supportData.setupValidation.pendingReviews}
                       </Text>
                       <Badge color="yellow">Need Action</Badge>
                     </div>
                   </div>
                   <div>
                     <Text>Accuracy Rate</Text>
                     <div className="mt-2">
                       <ProgressBar 
                         value={supportData.setupValidation.accuracyRate}
                         color="green"
                       />
                     </div>
                   </div>
                 </div>
               </div>
             </Card>

             <Card>
               <div className="p-4">
                 <Title>Recent Validations</Title>
                 <div className="mt-4">
                   <Text className="text-2xl font-bold">
                     {supportData.setupValidation.recentValidations}
                   </Text>
                   <Text className="text-gray-500">completed today</Text>
                 </div>
               </div>
             </Card>
           </div>
         </TabPanel>

         <TabPanel>
           <div className="mt-4 grid grid-cols-2 gap-6">
             <Card>
               <div className="p-4">
                 <Title>Response Time</Title>
                 <div className="mt-4">
                   <Text className="text-2xl font-bold">
                     {supportData.performance.responseTime}ms
                   </Text>
                   <ProgressBar 
                     value={(supportData.performance.responseTime / 100) * 100}
                     color={supportData.performance.responseTime < 50 ? 'green' : 'yellow'}
                   />
                 </div>
               </div>
             </Card>

             <Card>
               <div className="p-4">
                 <Title>Error Rate</Title>
                 <div className="mt-4">
                   <Text className="text-2xl font-bold">
                     {supportData.performance.errorRate}%
                   </Text>
                   <Badge color={supportData.performance.errorRate < 1 ? 'green' : 'red'}>
                     {supportData.performance.errorRate < 1 ? 'Healthy' : 'Investigation Needed'}
                   </Badge>
                 </div>
               </div>
             </Card>
           </div>
         </TabPanel>
       </TabPanels>
     </TabGroup>
   </div>
 );
}