import { createContext, useContext, useState, useEffect } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (password: string) => boolean;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

// This is an encoded list of valid passwords
// Format: Base64 encoded string of comma-separated SHA-256 hashes
// const ENCODED_VALID_PASSWORDS = 'ZjZlOGYyZjJmMWEyYjBiMmU1ZjNjMWVlZjFlNGVmZjk=';
const ENCODED_VALID_PASSWORDS = 'YTJiMGY2ZThmMmYyZjFlNGYz';

// Utility function to decode and check password
const isValidPassword = (input: string): boolean => {
  try {
    // Decode the encoded password list
    const decodedPasswords = atob(ENCODED_VALID_PASSWORDS);
    
    // XOR the input with a simple key (additional obfuscation)
    const simpleHash = input.split('')
      .map(char => char.charCodeAt(0) ^ 129)
      .map(num => num.toString(16).padStart(2, '0'))
      .join('');
    
    // Check if the hashed input matches any valid password
    return decodedPasswords.includes(simpleHash);
  } catch {
    return false;
  }
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const hasSession = localStorage.getItem('auth_session');
    if (hasSession) {
      const session = JSON.parse(hasSession);
      if (session.expires > new Date().getTime()) {
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('auth_session');
      }
    }
  }, []);

  const login = (password: string): boolean => {
    if (isValidPassword(password)) {
      setIsAuthenticated(true);
      const session = {
        timestamp: new Date().getTime(),
        expires: new Date().getTime() + (24 * 60 * 60 * 1000)
      };
      localStorage.setItem('auth_session', JSON.stringify(session));
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('auth_session');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}