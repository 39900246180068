// src/components/deals/management/alerts/AlertSystem.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  TabGroup,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Select,
  SelectItem,
  ProgressBar
} from '@tremor/react';
import {
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
  BellAlertIcon,
  ClockIcon,
  CheckCircleIcon,
  ArrowPathIcon,
  SparklesIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Define alert types and interfaces
interface Alert {
  id: string;
  type: 'health' | 'performance' | 'setup' | 'system';
  severity: 'critical' | 'high' | 'medium' | 'low';
  status: 'new' | 'acknowledged' | 'in_progress' | 'resolved';
  title: string;
  message: string;
  timestamp: string;
  entity: {
    type: 'deal' | 'account' | 'brand';
    id: string;
    name: string;
  };
  metrics?: {
    name: string;
    value: number;
    threshold: number;
  }[];
  aiRecommendation?: string;
  assignee?: string;
}

// Sample alerts data
const alertsData: Alert[] = [
  {
    id: 'ALT-001',
    type: 'health',
    severity: 'critical',
    status: 'new',
    title: 'Zero Spend Alert',
    message: 'No spend recorded in the last 48 hours',
    timestamp: '2024-03-26T10:00:00Z',
    entity: {
      type: 'deal',
      id: 'DEAL-123',
      name: 'Premium Auto Campaign'
    },
    metrics: [
      {
        name: 'Spend',
        value: 0,
        threshold: 1000
      }
    ],
    aiRecommendation: 'Check DSP setup and bid response rates'
  },
  {
    id: 'ALT-002',
    type: 'performance',
    severity: 'high',
    status: 'acknowledged',
    title: 'High Discard Rate',
    message: 'Discard rate exceeds 25% threshold',
    timestamp: '2024-03-26T09:30:00Z',
    entity: {
      type: 'deal',
      id: 'DEAL-124',
      name: 'Retail Performance'
    },
    metrics: [
      {
        name: 'Discard Rate',
        value: 28,
        threshold: 25
      }
    ],
    aiRecommendation: 'Review bid price and targeting settings'
  }
  // Add more sample alerts...
];

// Alert filtering and grouping functions
const filterAlerts = (alerts: Alert[], filters: any) => {
  return alerts.filter(alert => {
    if (filters.type && !filters.type.includes(alert.type)) return false;
    if (filters.severity && !filters.severity.includes(alert.severity)) return false;
    if (filters.status && !filters.status.includes(alert.status)) return false;
    return true;
  });
};

const groupAlertsByEntity = (alerts: Alert[]) => {
  return alerts.reduce((acc, alert) => {
    const key = `${alert.entity.type}-${alert.entity.id}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(alert);
    return acc;
  }, {} as Record<string, Alert[]>);
};

export function AlertSystem() {
  const [alerts, setAlerts] = useState<Alert[]>(alertsData);
  const [filters, setFilters] = useState({
    type: [] as string[],
    severity: [] as string[],
    status: [] as string[]
  });
  const [selectedView, setSelectedView] = useState('all');

  const handleAlertAction = (alertId: string, action: 'acknowledge' | 'resolve') => {
    setAlerts(prevAlerts => 
      prevAlerts.map(alert => {
        if (alert.id === alertId) {
          return {
            ...alert,
            status: action === 'acknowledge' ? 'acknowledged' : 'resolved'
          };
        }
        return alert;
      })
    );
  };

  const AlertCard = ({ alert }: { alert: Alert }) => (
    <Card className={`${
      alert.severity === 'critical' ? 'border-red-200 bg-red-50' :
      alert.severity === 'high' ? 'border-orange-200 bg-orange-50' :
      'border-gray-200'
    }`}>
      <div className="p-4">
        {/* Alert Header */}
        <div className="flex items-start justify-between">
          <div className="flex items-start space-x-3">
            <div className={`p-2 rounded-lg ${
              alert.severity === 'critical' ? 'bg-red-100' :
              alert.severity === 'high' ? 'bg-orange-100' :
              'bg-gray-100'
            }`}>
              <ExclamationTriangleIcon className={`h-5 w-5 ${
                alert.severity === 'critical' ? 'text-red-600' :
                alert.severity === 'high' ? 'text-orange-600' :
                'text-gray-600'
              }`} />
            </div>
            <div>
              <div className="flex items-center space-x-2">
                <Text className="font-medium">{alert.title}</Text>
                <Badge color={
                  alert.severity === 'critical' ? 'red' :
                  alert.severity === 'high' ? 'orange' :
                  'gray'
                }>
                  {alert.severity}
                </Badge>
                <Badge color={
                  alert.status === 'new' ? 'blue' :
                  alert.status === 'acknowledged' ? 'yellow' :
                  alert.status === 'resolved' ? 'green' : 'gray'
                }>
                  {alert.status}
                </Badge>
              </div>
              <Text className="text-sm text-gray-600 mt-1">
                {alert.message}
              </Text>
            </div>
          </div>
          <Text className="text-sm text-gray-500">
            {new Date(alert.timestamp).toLocaleString()}
          </Text>
        </div>

        {/* Entity Info */}
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Badge color="blue">{alert.entity.type}</Badge>
            <Text>{alert.entity.name}</Text>
          </div>
          {alert.assignee && (
            <Badge color="gray">
              Assigned: {alert.assignee}
            </Badge>
          )}
        </div>

        {/* Metrics */}
        {alert.metrics && (
          <div className="mt-4 space-y-3">
            {alert.metrics.map((metric, index) => (
              <div key={index} className="space-y-1">
                <div className="flex justify-between text-sm">
                  <Text>{metric.name}</Text>
                  <div className="flex items-center space-x-2">
                    <Text>{metric.value}</Text>
                    <Text className="text-gray-500">vs {metric.threshold}</Text>
                  </div>
                </div>
                <ProgressBar
                  value={(metric.value / metric.threshold) * 100}
                  color={
                    metric.value > metric.threshold ? 'red' :
                    metric.value > metric.threshold * 0.9 ? 'yellow' : 'green'
                  }
                />
              </div>
            ))}
          </div>
        )}

        {/* AI Recommendation */}
        {alert.aiRecommendation && (
          <div className="mt-4 flex items-start space-x-2 bg-blue-50 p-3 rounded-lg">
            <SparklesIcon className="h-5 w-5 text-blue-500 mt-0.5" />
            <Text className="text-sm text-blue-700">
              {alert.aiRecommendation}
            </Text>
          </div>
        )}

        {/* Actions */}
        <div className="mt-4 flex justify-end space-x-2">
          {alert.status === 'new' && (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleAlertAction(alert.id, 'acknowledge')}
            >
              Acknowledge
            </Button>
          )}
          {alert.status !== 'resolved' && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleAlertAction(alert.id, 'resolve')}
            >
              Resolve
            </Button>
          )}
        </div>
      </div>
    </Card>
  );

  return (
    <div className="space-y-6">
      {/* Header with Controls */}
      <div className="flex justify-between items-center">
        <Title>Alert Center</Title>
        <div className="flex space-x-4">
          <Select
            value={selectedView}
            onValueChange={setSelectedView}
            className="w-40"
          >
            <SelectItem value="all">All Alerts</SelectItem>
            <SelectItem value="critical">Critical Only</SelectItem>
            <SelectItem value="unresolved">Unresolved</SelectItem>
          </Select>
          <Button variant="secondary">
            <BellAlertIcon className="h-5 w-5 mr-2" />
            Configure Alerts
          </Button>
        </div>
      </div>

      {/* Alert Summary */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {[
          { label: 'Critical', count: 3, icon: ShieldExclamationIcon, color: 'red' },
          { label: 'High', count: 5, icon: BellAlertIcon, color: 'orange' },
          { label: 'Medium', count: 8, icon: ExclamationTriangleIcon, color: 'yellow' },
          { label: 'Low', count: 12, icon: ClockIcon, color: 'gray' }
        ].map((summary) => (
          <Card key={summary.label}>
            <div className="p-4 flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <summary.icon className={`h-5 w-5 text-${summary.color}-500`} />
                <Text>{summary.label}</Text>
              </div>
              <Badge color={summary.color as any}>{summary.count}</Badge>
            </div>
          </Card>
        ))}
      </div>

      {/* Main Content */}
      <Card>
        <TabGroup>
          <TabList>
            <Tab>All Alerts</Tab>
            <Tab>By Entity</Tab>
            <Tab>By Type</Tab>
          </TabList>

          <TabPanels>
            {/* All Alerts Panel */}
            <TabPanel>
              <div className="p-4 space-y-4">
                {filterAlerts(alerts, filters).map(alert => (
                  <AlertCard key={alert.id} alert={alert} />
                ))}
              </div>
            </TabPanel>

            {/* By Entity Panel */}
            <TabPanel>
              <div className="p-4 space-y-6">
                {Object.entries(groupAlertsByEntity(alerts)).map(([entityKey, entityAlerts]) => (
                  <div key={entityKey} className="space-y-4">
                    <Title className="text-lg">
                      {entityAlerts[0].entity.name}
                    </Title>
                    {entityAlerts.map(alert => (
                      <AlertCard key={alert.id} alert={alert} />
                    ))}
                  </div>
                ))}
              </div>
            </TabPanel>

            {/* By Type Panel */}
            <TabPanel>
              <div className="p-4 space-y-6">
                {['health', 'performance', 'setup', 'system'].map(type => {
                  const typeAlerts = alerts.filter(a => a.type === type);
                  if (typeAlerts.length === 0) return null;
                  
                  return (
                    <div key={type} className="space-y-4">
                      <Title className="text-lg capitalize">{type} Alerts</Title>
                      {typeAlerts.map(alert => (
                        <AlertCard key={alert.id} alert={alert} />
                      ))}
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Card>
    </div>
  );
}