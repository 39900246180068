// src/components/deals/management/metrics/OperationalMetrics.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  AreaChart,
  TabGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Select,
  SelectItem,
  DonutChart
} from '@tremor/react';
import {
  ClockIcon,
  CheckCircleIcon,
  RocketLaunchIcon,
  UserGroupIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  SparklesIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample operational data
const operationalData = {
  setupMetrics: {
    accuracy: {
      overall: 92,
      byComponent: {
        targeting: 94,
        pricing: 90,
        creative: 93,
        tracking: 91
      },
      trend: 3
    },
    launchSuccess: {
      rate: 88,
      avgTime: 4.2, // hours
      issues: [
        { type: 'targeting', count: 5 },
        { type: 'pricing', count: 3 },
        { type: 'creative', count: 4 }
      ]
    },
    trends: [
      {
        date: '2024-01',
        'Setup Accuracy': 89,
        'Launch Success': 85,
        'Response Time': 5.1
      },
      {
        date: '2024-02',
        'Setup Accuracy': 91,
        'Launch Success': 87,
        'Response Time': 4.5
      },
      {
        date: '2024-03',
        'Setup Accuracy': 92,
        'Launch Success': 88,
        'Response Time': 4.2
      }
    ]
  },
  responseMetrics: {
    current: {
      avgResponse: 4.2, // hours
      slaCompliance: 94,
      firstTimeResolution: 85,
      clientSatisfaction: 4.5
    },
    byPriority: [
      { priority: 'Critical', avg: 2.1, sla: 98 },
      { priority: 'High', avg: 4.0, sla: 95 },
      { priority: 'Normal', avg: 6.5, sla: 92 }
    ],
    trends: [
      // ... similar format as setupMetrics.trends
    ]
  },
  teamMetrics: {
    utilization: 82,
    efficiency: 88,
    backlog: {
      total: 45,
      critical: 5,
      high: 15,
      normal: 25
    },
    distribution: [
      { team: 'Deal Ops', load: 85, capacity: 100 },
      { team: 'Support', load: 78, capacity: 100 },
      { team: 'Account Mgmt', load: 82, capacity: 100 }
    ]
  },
  alerts: [
    {
      id: 'ALT001',
      type: 'Response Time',
      message: 'Higher than average response times for retail vertical',
      impact: 'medium',
      recommendation: 'Consider redistributing workload among teams'
    },
    {
      id: 'ALT002',
      type: 'Setup Accuracy',
      message: 'Recent decline in pricing setup accuracy',
      impact: 'high',
      recommendation: 'Review pricing validation workflow'
    }
  ]
};

export function OperationalMetrics() {
  const [timeframe, setTimeframe] = useState('7d');

  const MetricCard = ({ 
    title, 
    value, 
    target, 
    trend, 
    icon: Icon,
    suffix = '%'
  }: {
    title: string;
    value: number;
    target?: number;
    trend?: number;
    icon: any;
    suffix?: string;
  }) => (
    <Card className="p-4">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <Icon className="h-5 w-5 text-gray-500" />
            <Text>{title}</Text>
          </div>
          <div className="mt-2 flex items-baseline space-x-2">
            <Metric>{value}{suffix}</Metric>
            {trend && (
              <Badge 
                color={trend > 0 ? 'green' : 'red'}
                icon={trend > 0 ? ArrowTrendingUpIcon : ArrowTrendingDownIcon}
              >
                {trend > 0 ? '+' : ''}{trend}%
              </Badge>
            )}
          </div>
        </div>
      </div>
      {target && (
        <div className="mt-4">
          <div className="flex justify-between text-sm mb-1">
            <Text>vs Target</Text>
            <Text>{target}%</Text>
          </div>
          <ProgressBar
            value={(value / target) * 100}
            color="blue"
          />
        </div>
      )}
    </Card>
  );

  return (
    <div className="space-y-6">
      {/* Header with Time Range Selector */}
      <div className="flex justify-between items-center">
        <Title>Operational Metrics</Title>
        <Select 
          value={timeframe} 
          onValueChange={setTimeframe}
          className="w-32"
        >
          <SelectItem value="1d">Last 24h</SelectItem>
          <SelectItem value="7d">Last 7 days</SelectItem>
          <SelectItem value="30d">Last 30 days</SelectItem>
        </Select>
      </div>

      {/* AI Insights */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="p-4 flex items-start space-x-3">
          <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
          <div>
            <Title className="text-blue-800">Operational Insights</Title>
            <Text className="text-blue-700 mt-1">
              Setup accuracy has improved by 3% this period. Consider addressing 
              the slight increase in response times for retail vertical deals.
            </Text>
            <div className="mt-4 flex space-x-4">
              <Button variant="secondary" size="sm">
                View Details
              </Button>
              <Button variant="primary" size="sm">
                Take Action
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Main Content */}
      <TabGroup>
        <TabList>
          <Tab>Setup Metrics</Tab>
          <Tab>Response Times</Tab>
          <Tab>Team Performance</Tab>
        </TabList>

        <TabPanels>
          {/* Setup Metrics Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* Key Metrics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <MetricCard
                  title="Setup Accuracy"
                  value={operationalData.setupMetrics.accuracy.overall}
                  target={95}
                  trend={operationalData.setupMetrics.accuracy.trend}
                  icon={CheckCircleIcon}
                />
                <MetricCard
                  title="Launch Success"
                  value={operationalData.setupMetrics.launchSuccess.rate}
                  target={90}
                  trend={2}
                  icon={RocketLaunchIcon}
                />
                <MetricCard
                  title="Avg Launch Time"
                  value={operationalData.setupMetrics.launchSuccess.avgTime}
                  trend={-0.3}
                  icon={ClockIcon}
                  suffix="h"
                />
              </div>

              {/* Setup Accuracy by Component */}
              <Card>
                <div className="p-4">
                  <Title>Setup Accuracy by Component</Title>
                  <div className="grid grid-cols-2 gap-6 mt-4">
                    <div className="space-y-4">
                      {Object.entries(operationalData.setupMetrics.accuracy.byComponent)
                        .map(([component, value]) => (
                          <div key={component} className="space-y-2">
                            <div className="flex justify-between">
                              <Text className="capitalize">{component}</Text>
                              <Text>{value}%</Text>
                            </div>
                            <ProgressBar
                              value={value}
                              color={value >= 90 ? 'green' : value >= 85 ? 'yellow' : 'red'}
                            />
                          </div>
                        ))}
                    </div>
                    <DonutChart
                      className="h-52"
                      data={Object.entries(operationalData.setupMetrics.accuracy.byComponent)
                        .map(([key, value]) => ({
                          name: key,
                          value: value
                        }))}
                      category="value"
                      index="name"
                      colors={['blue', 'cyan', 'indigo', 'violet']}
                    />
                  </div>
                </div>
              </Card>

              {/* Launch Issues */}
              <Card>
                <div className="p-4">
                  <Title>Launch Issues Distribution</Title>
                  <div className="mt-4 space-y-4">
                    {operationalData.setupMetrics.launchSuccess.issues.map((issue) => (
                      <div key={issue.type} className="flex items-center justify-between">
                        <Text className="capitalize">{issue.type} Issues</Text>
                        <Badge color={
                          issue.count > 4 ? 'red' :
                          issue.count > 2 ? 'yellow' : 'green'
                        }>
                          {issue.count} issues
                        </Badge>
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>

          {/* Response Times Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* Response Metrics */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <MetricCard
                  title="Avg Response Time"
                  value={operationalData.responseMetrics.current.avgResponse}
                  trend={-0.3}
                  icon={ClockIcon}
                  suffix="h"
                />
                <MetricCard
                  title="SLA Compliance"
                  value={operationalData.responseMetrics.current.slaCompliance}
                  target={95}
                  trend={2}
                  icon={CheckCircleIcon}
                />
                <MetricCard
                  title="First Resolution"
                  value={operationalData.responseMetrics.current.firstTimeResolution}
                  target={90}
                  trend={1.5}
                  icon={CheckCircleIcon}
                />
                <MetricCard
                  title="Client Satisfaction"
                  value={operationalData.responseMetrics.current.clientSatisfaction}
                  target={4.8}
                  trend={0.2}
                  icon={UserGroupIcon}
                  suffix=""
                />
              </div>

              {/* Response Times by Priority */}
              <Card>
                <div className="p-4">
                  <Title>Response Times by Priority</Title>
                  <div className="mt-4 space-y-4">
                    {operationalData.responseMetrics.byPriority.map((priority) => (
                      <Card key={priority.priority}>
                        <div className="p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <Text className="font-medium">{priority.priority}</Text>
                              <Text className="text-sm text-gray-500 mt-1">
                                Avg Response: {priority.avg}h
                              </Text>
                            </div>
                            <div className="text-right">
                              <Badge color={priority.sla >= 95 ? 'green' : 'yellow'}>
                                {priority.sla}% SLA
                              </Badge>
                            </div>
                          </div>
                          <ProgressBar
                            value={priority.sla}
                            color={priority.sla >= 95 ? 'green' : 'yellow'}
                            className="mt-3"
                          />
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>

          {/* Team Performance Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* Team Metrics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <MetricCard
                  title="Team Utilization"
                  value={operationalData.teamMetrics.utilization}
                  target={85}
                  trend={2}
                  icon={UserGroupIcon}
                />
                <MetricCard
                  title="Efficiency Score"
                  value={operationalData.teamMetrics.efficiency}
                  target={90}
                  trend={3}
                  icon={ArrowTrendingUpIcon}
                />
                <Card className="p-4">
                  <div className="flex items-center justify-between">
                    <Text>Backlog</Text>
                    <Badge color={
                      operationalData.teamMetrics.backlog.critical > 0 ? 'red' : 'green'
                    }>
                      {operationalData.teamMetrics.backlog.total} items
                    </Badge>
                  </div>
                  <div className="mt-4 space-y-2">
                    {[
                      { label: 'Critical', value: operationalData.teamMetrics.backlog.critical, color: 'red' },
                      { label: 'High', value: operationalData.teamMetrics.backlog.high, color: 'yellow' },
                      { label: 'Normal', value: operationalData.teamMetrics.backlog.normal, color: 'green' }
                    ].map(item => (
                      <div key={item.label} className="flex justify-between items-center">
                        <Text className="text-sm">{item.label}</Text>
                        <Badge color={item.color as any}>{item.value}</Badge>
                      </div>
                    ))}
                  </div>
                </Card>
              </div>

              {/* Team Distribution */}
              <Card>
                <div className="p-4">
                  <Title>Team Workload Distribution</Title>
                  <div className="mt-4 space-y-4">
                    {operationalData.teamMetrics.distribution.map((team) => (
                      <div key={team.team} className="space-y-2">
                        <div className="flex justify-between">
                          <Text>{team.team}</Text>
                          <Text>{team.load}%</Text>
                        </div>
                        <ProgressBar
                          value={team.load}
                          color={
                            team.load > 80 ? 'yellow' : 'green'
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Card>

              {/* Alerts and Recommendations */}
              <Card>
                <div className="p-4">
                  <Title>Operational Alerts</Title>
                  <div className="mt-4 space-y-4">
                    {operationalData.alerts.map((alert) => (
                      <Card key={alert.id} className={`bg-${
                        alert.impact === 'high' ? 'red' : 'yellow'
                      }-50`}>
                        <div className="p-4">
                          <div className="flex items-start justify-between">
                            <div>
                              <div className="flex items-center space-x-2">
                                <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
                                <Text className="font-medium">{alert.type}</Text>
                                <Badge color={alert.impact === 'high' ? 'red' : 'yellow'}>
                                  {alert.impact} impact
                                </Badge>
                              </div>
                              <Text className="mt-2 text-gray-600">
                                {alert.message}
                              </Text>
                              <div className="mt-2 flex items-center space-x-2">
                                <SparklesIcon className="h-4 w-4 text-blue-500" />
                                <Text className="text-sm text-blue-600">
                                  {alert.recommendation}
                                </Text>
                              </div>
                            </div>
                            <Button variant="secondary" size="sm">
                              Take Action
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}

