import { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import {
  MagnifyingGlassIcon,
  AdjustmentsHorizontalIcon,
  ClockIcon,
  SparklesIcon,
  XMarkIcon,
  FunnelIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { Badge, Text } from '@tremor/react';
import { EntityType } from '../../../types/smartFinder';

interface SearchSuggestion {
  type: 'recent' | 'suggestion' | 'filter';
  text: string;
  category?: string;
  value?: string | number;
  icon?: React.ComponentType<any>;
  field?: string;  
}

interface ActiveFilter {
  field: string;
  value: string | number;
  label: string;
}

interface SearchToolbarProps {
  entityType: EntityType;
  value: string;
  onChange: (value: string) => void;
  onPath?: (path: string[]) => void;
  initialFilters?: ActiveFilter[];
}

// Entity-specific search configurations
const searchConfigs: Record<EntityType, {
  placeholder: string;
  suggestions: SearchSuggestion[];
  filters: Array<{
    field: string;
    label: string;
    type: 'select' | 'range' | 'boolean';
    options?: Array<{ value: string; label: string }>;
  }>;
}> = {
  deals: {
    placeholder: 'Search by brand, agency, campaign name...',
    suggestions: [
      { type: 'recent', text: 'Active automotive campaigns', category: 'Recent' },
      { type: 'suggestion', text: 'High performing retail deals', category: 'Suggested' },
      { type: 'filter', text: 'Budget > $50,000', field: 'budget', value: 50000 }
    ],
    filters: [
      {
        field: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'pending', label: 'Pending' },
          { value: 'completed', label: 'Completed' }
        ]
      },
      {
        field: 'type',
        label: 'Deal Type',
        type: 'select',
        options: [
          { value: 'pmp', label: 'PMP' },
          { value: 'programmatic', label: 'Programmatic' },
          { value: 'preferred', label: 'Preferred' }
        ]
      }
    ]
  },
  audiences: {
    placeholder: 'Search audience segments, demographics...',
    suggestions: [
      { type: 'recent', text: 'High income auto intenders', category: 'Recent' },
      { type: 'suggestion', text: 'Segments with >80% match rate', category: 'Suggested' }
    ],
    filters: [
      {
        field: 'type',
        label: 'Audience Type',
        type: 'select',
        options: [
          { value: 'first_party', label: 'First Party' },
          { value: 'third_party', label: 'Third Party' },
          { value: 'contextual', label: 'Contextual' }
        ]
      },
      {
        field: 'match_rate',
        label: 'Match Rate',
        type: 'range'
      }
    ]
  },
  domains: {
    placeholder: 'Search domains, categories, publishers...',
    suggestions: [
      { type: 'recent', text: 'Premium news sites', category: 'Recent' },
      { type: 'suggestion', text: 'Sports content >75% viewability', category: 'Suggested' }
    ],
    filters: [
      {
        field: 'category',
        label: 'Category',
        type: 'select',
        options: [
          { value: 'news', label: 'News' },
          { value: 'sports', label: 'Sports' },
          { value: 'entertainment', label: 'Entertainment' }
        ]
      },
      {
        field: 'brand_safety',
        label: 'Brand Safety',
        type: 'select',
        options: [
          { value: 'high', label: 'High' },
          { value: 'medium', label: 'Medium' },
          { value: 'standard', label: 'Standard' }
        ]
      }
    ]
  }
};

export function SearchToolbar({
  entityType,
  value,
  onChange,
  onPath,
  initialFilters = []
}: SearchToolbarProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>(initialFilters);
  const inputRef = useRef<HTMLInputElement>(null);
  const config = searchConfigs[entityType];

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        inputRef.current?.focus();
      }
      if (e.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Handle suggestion selection
  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    if (suggestion.type === 'filter' && suggestion.field) {
      setActiveFilters(prev => [
        ...prev,
        {
          field: suggestion.field!,
          value: suggestion.value!,
          label: suggestion.text
        }
      ]);
    } else {
      onChange(suggestion.text);
    }
    setIsFocused(false);
  };

  return (
    <div className="relative">
      {/* Search Input */}
      <div className="relative">
        <MagnifyingGlassIcon 
          className="absolute left-3 top-1/2 -mt-2.5 h-5 w-5 text-gray-400" 
        />
        <input
          ref={inputRef}
          type="text"
          className="block w-full rounded-lg border border-gray-300 pl-10 pr-36 py-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
          placeholder={config.placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          {/* Active Filters Count */}
          {activeFilters.length > 0 && (
            <Badge color="blue">
              {activeFilters.length} filters
            </Badge>
          )}
          
          {/* Keyboard Shortcut */}
          <kbd className="ml-2 hidden sm:inline-flex items-center rounded border border-gray-200 px-2 text-xs text-gray-400">
            ⌘K
          </kbd>
        </div>
      </div>

      {/* Active Filters */}
      {activeFilters.length > 0 && (
        <div className="flex items-center gap-2 mt-2">
          <Text className="text-sm text-gray-500">Filters:</Text>
          <div className="flex flex-wrap gap-2">
            {activeFilters.map((filter, index) => (
              <Badge
                key={`${filter.field}-${index}`}
                color="blue"
                className="flex items-center gap-1"
              >
                {filter.label}
                <button
                  onClick={() => setActiveFilters(prev => 
                    prev.filter((_, i) => i !== index)
                  )}
                >
                  <XMarkIcon className="h-3 w-3" />
                </button>
              </Badge>
            ))}
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setActiveFilters([])}
            >
              Clear all
            </Button>
          </div>
        </div>
      )}

      {/* Suggestions Dropdown */}
      <Transition
        show={isFocused && (value.length > 0 || config.suggestions.length > 0)}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute left-0 right-0 mt-2 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
      >
        <div className="py-2">
          {/* Recent Searches */}
          <div className="px-4 py-2">
            <Text className="text-xs font-medium text-gray-500">Recent Searches</Text>
            {config.suggestions
              .filter(s => s.type === 'recent')
              .map((suggestion, index) => (
                <button
                  key={index}
                  className="flex items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <ClockIcon className="h-4 w-4 mr-2 text-gray-400" />
                  {suggestion.text}
                </button>
              ))}
          </div>

          {/* Suggestions */}
          <div className="px-4 py-2">
            <Text className="text-xs font-medium text-gray-500">Suggestions</Text>
            {config.suggestions
              .filter(s => s.type === 'suggestion')
              .map((suggestion, index) => (
                <button
                  key={index}
                  className="flex items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <SparklesIcon className="h-4 w-4 mr-2 text-gray-400" />
                  {suggestion.text}
                </button>
              ))}
          </div>

          {/* Quick Filters */}
          <div className="px-4 py-2">
            <Text className="text-xs font-medium text-gray-500">Quick Filters</Text>
            {config.suggestions
              .filter(s => s.type === 'filter')
              .map((suggestion, index) => (
                <button
                  key={index}
                  className="flex items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <FunnelIcon className="h-4 w-4 mr-2 text-gray-400" />
                  {suggestion.text}
                </button>
              ))}
          </div>
        </div>
      </Transition>

      {/* Filter Panel */}
      <Transition
        show={showFilters}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute right-0 mt-2 w-80 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
      >
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <Text className="font-medium">Filters</Text>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setShowFilters(false)}
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>

          <div className="space-y-4">
            {config.filters.map((filter) => (
              <div key={filter.field}>
                <Text className="text-sm font-medium mb-1">{filter.label}</Text>
                {filter.type === 'select' && filter.options && (
                  <select
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
                    onChange={(e) => {
                      if (e.target.value) {
                        setActiveFilters(prev => [
                          ...prev,
                          {
                            field: filter.field,
                            value: e.target.value,
                            label: `${filter.label}: ${
                              filter.options?.find(o => o.value === e.target.value)?.label
                            }`
                          }
                        ]);
                      }
                    }}
                  >
                    <option value="">Select {filter.label}</option>
                    {filter.options.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
                {filter.type === 'range' && (
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Min"
                    />
                    <span className="text-gray-500">-</span>
                    <input
                      type="number"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Max"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="mt-4 flex justify-end space-x-2">
            <Button
              variant="secondary"
              onClick={() => setShowFilters(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => setShowFilters(false)}
            >
              Apply Filters
            </Button>
          </div>
        </div>
      </Transition>
    </div>
  );
}