import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link, NavLink } from 'react-router-dom';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { navigation } from '../config/routes';
import { GlobalSearch } from '../components/search/GlobalSearch';
import { CommandPalette } from '../components/search/CommandPalette';
import { Notifications } from '../components/navigation/Notifications';
import { RoleSwitcher } from '../components/navigation/RoleSwitcher';

function Logo() {
  return (
    <Link to="/" className="flex items-center">
      <img
        className="h-8 w-auto"
        // src="/openx-logo.svg"
        // alt="OpenX"
      />
    </Link>
  );
}

function NavigationItem({ item }: { item: typeof navigation[0] }) {
  const Icon = item.icon;
  
  return (
    <NavLink
      to={item.path}
      className={({ isActive }) =>
        `group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${
          isActive
            ? 'bg-gray-50 text-blue-600'
            : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50'
        }`
      }
    >
      <Icon className="h-6 w-6 shrink-0" aria-hidden="true" />
      {item.name}
    </NavLink>
  );
}

export default function MainLayout({ children }: { children: React.ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <Logo />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <NavigationItem item={item} />
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop sidebar */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <Logo />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              {navigation.map((item) => (
                <li key={item.name}>
                  <NavigationItem item={item} />
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Main content area */}
      <div className="lg:pl-72">
        {/* Top navigation */}
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          {/* Mobile menu button */}
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Mobile logo (shows only on mobile) */}
          <div className="lg:hidden">
            <Logo />
          </div>

          {/* Desktop navigation items */}
          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            {/* Global Search */}
            <GlobalSearch onOpen={() => setIsSearchOpen(true)} />

            {/* Right navigation items */}
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <Notifications />
              
              {/* Separator */}
              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />
              
              {/* Role Switcher */}
              <RoleSwitcher />
            </div>
          </div>
        </div>

        {/* Main content */}
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>

      {/* Command Palette for global search */}
      <CommandPalette 
        isOpen={isSearchOpen} 
        onClose={() => setIsSearchOpen(false)} 
      />


        {/* Add this new footer section */}
        <footer className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-3">
          <div className="container mx-auto px-4 text-center text-sm text-gray-600">
            Powered by{' '}
            <span className="font-semibold" style={{ color: '#c02f5a' }}>
              WISSP.AI
            </span>
          </div>
        </footer>
    </div>
  );
}