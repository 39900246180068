// src/components/smartFinder/shared/MetricsPanel.tsx

import { useState, useMemo } from 'react';
import {
 Card,
 Title,
 Text,
 Badge,
 ProgressBar,
 AreaChart,
 DonutChart,
 BarChart  
} from '@tremor/react';
import {
 ArrowUpIcon,
 ArrowDownIcon,
 ChartBarIcon,
 DeviceTabletIcon,
 GlobeAltIcon,
 ViewColumnsIcon,
 XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { FilterPopover } from '../filters/FilterPopover'; 

interface FilterType {
    field: string;
    operator: string;
    value: any;
  }

  
  interface MetricData {
    name: string;
    value: number;
    change?: number;
    benchmark?: number;
    icon?: React.ComponentType<any>;
  }

const formatMetric = (metric: MetricData) => ({
    name: metric.name,
    value: metric.value,
    change: metric.change || undefined
  });

  
interface MetricsPanelProps {
 metrics: {
   primary: MetricData[];
   performance?: {
     viewability: number;
     ctr?: number;
     completion?: number;
   };
   distribution?: Array<{
     name: string;
     value: number;
   }>;
   trends?: Array<{
     date: string;
     value: number;
   }>;
 };
 type: 'deals' | 'audiences' | 'domains';
 onClose?: () => void;
 onExport?: () => void;
}

export function MetricsPanel({
 metrics,
 type,
 onClose,
 onExport
}: MetricsPanelProps) {

const [visibleSections, setVisibleSections] = useState(['primary', 'performance', 'distribution', 'trends']);


 const processedMetrics = useMemo(() => {
   if (!metrics) return null;

   const secondary = [];
   if (metrics.performance) {
     if (metrics.performance.viewability) {
       secondary.push({
         name: 'Viewability',
         value: metrics.performance.viewability
       });
     }
     if (metrics.performance.ctr) {
       secondary.push({
         name: 'CTR',
         value: metrics.performance.ctr * 100
       });
     }
     if (metrics.performance.completion) {
       secondary.push({
         name: 'Completion Rate',
         value: metrics.performance.completion
       });
     }
   }

   return {
     primary: metrics.primary || [],
     secondary
   };
 }, [metrics]);

 if (!processedMetrics) return null;

 return (
    <div>
      <div className="flex items-center justify-between p-4 border-b">
        <Title>Metrics Analysis</Title>
        <div className="flex items-center space-x-2">
        <FilterPopover
            field="sections"
            type="select"
            options={[
                { value: 'primary', label: 'Primary Metrics' },
                { value: 'performance', label: 'Performance' },
                { value: 'distribution', label: 'Distribution' },
                { value: 'trends', label: 'Trends' }
            ]}
            currentFilter={{ field: 'sections', operator: 'in', value: visibleSections }}
            onFilter={(field: string, filter: any) => {
                if (filter && Array.isArray(filter.value)) {
                setVisibleSections(filter.value);
                }
            }}
            />

          {onClose && (
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XMarkIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>

     {/* Primary Metrics */}
     <div className="grid grid-cols-1 gap-4">
       {processedMetrics.primary.map((metric, index) => (
         <Card key={index} className="space-y-2">
           <div className="flex items-start justify-between">
             <div>
               <Text className="text-gray-500">{metric?.name}</Text>
               <div className="flex items-baseline space-x-2">
                 <Text className="text-2xl font-semibold">{metric?.value}</Text>
                 {metric?.change !== undefined && (
                   <Badge
                     color={metric.change > 0 ? 'emerald' : 'red'}
                     className="flex items-center"
                   >
                     {metric.change > 0 ? (
                       <ArrowUpIcon className="h-3 w-3 mr-1" />
                     ) : (
                       <ArrowDownIcon className="h-3 w-3 mr-1" />
                     )}
                     {Math.abs(metric.change)}%
                   </Badge>
                 )}
               </div>
             </div>
             {metric?.icon && (
               <div className="p-2 bg-gray-50 rounded-lg">
                 <metric.icon className="h-5 w-5 text-gray-400" />
               </div>
             )}
           </div>
           {metric?.benchmark !== undefined && (
             <div className="space-y-1">
               <div className="flex justify-between text-sm">
                 <Text className="text-gray-500">vs Benchmark</Text>
                 <Text>{metric.benchmark}%</Text>
               </div>
               <ProgressBar value={Number(metric.value)} color="blue" />
             </div>
           )}
         </Card>
       ))}
     </div>

     {/* Secondary Metrics */}
     <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
     {processedMetrics.secondary.map((metric: MetricData, index) => (
        <Card key={index}>
            <Text className="text-gray-500">{metric.name}</Text>
            <div className="flex items-baseline space-x-2 mt-1">
            <Text className="text-lg font-semibold">{metric.value}%</Text>
            {metric.change !== undefined && (
                <Text
                className={
                    metric.change > 0 ? 'text-emerald-600' : 'text-red-600'
                }
                >
                {metric.change > 0 ? '+' : ''}{metric.change}%
                </Text>
            )}
            </div>
        </Card>
        ))}
     </div>

     {/* Distribution Chart */}
     {metrics.distribution && (
       <Card>
         <Title>Distribution</Title>
         <DonutChart
           className="mt-6"
           data={metrics.distribution}
           category="value"
           index="name"
           colors={["blue", "cyan", "indigo"]}
           valueFormatter={(value: number) => `${value}%`}
         />
       </Card>
     )}

     {/* Trends Chart */}
     {metrics.trends && (
       <Card>
         <Title>Performance Trends</Title>
         <AreaChart
           className="mt-6 h-48"
           data={metrics.trends}
           index="date"
           categories={["value"]}
           colors={["blue"]}
           valueFormatter={(value: number) => `${value}%`}
         />
       </Card>
     )}
   </div>
 );
}

