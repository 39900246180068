// src/components/smartFinder/entityViews/deals/DealListView.tsx
import { useState, useMemo, useCallback } from 'react';
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Badge, Text, ProgressBar, Card } from '@tremor/react';
import { ChevronUpDownIcon, EyeIcon, ChartBarIcon, ShoppingBagIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';
import { PreviewPane } from '../../shared/PreviewPane';
import { FilterPopover } from '../../filters/FilterPopover';
import { ActiveFilters } from '../../filters/ActiveFilters';
import { ColumnCustomizer } from '../../filters/ColumnCustomizer';
import { ViewMode, UniversalFilters, Deal, PreviewData, EntityType } from '../../../../types/smartFinder';
import { enhancedMockDeals as mockDeals } from '../../../../data/mockData/combinedMockData';
import { useBasket } from '../../basket/BasketContext';
import { MetricsPanel } from '../../shared/MetricsPanel';

const getValue = (deal: any, field: string) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      return deal[parent]?.[child];
    }
    return deal[field];
  };

  interface DealColumn {
    id: string;
    label: string;
    type: 'text' | 'select' | 'number';
    sortable: boolean;
    filterable: boolean;
    options?: Array<{ value: string; label: string }>;
  }
  

  
// Column configuration
const dealColumns: DealColumn[] = [
  {
    id: 'name',
    label: 'Deal Name',
    type: 'text',
    sortable: true,
    filterable: true
  },
  {
    id: 'type',
    label: 'Type',
    type:'select' as const,
    sortable: true,
    filterable: true,
    options: [
      { value: 'PMP', label: 'PMP' },
      { value: 'Preferred', label: 'Preferred' },
      { value: 'Programmatic Guaranteed', label: 'Programmatic Guaranteed' }
    ]
  },
  {
    id: 'status',
    label: 'Status',
    type: 'select',
    sortable: true,
    filterable: true,
    options: [
      { value: 'active', label: 'Active' },
      { value: 'paused', label: 'Paused' },
      { value: 'completed', label: 'Completed' }
    ]
  },
  {
    id: 'performance',
    label: 'Performance',
    type: 'number',
    sortable: true,
    filterable: true
  }
];


  
interface DealListViewProps {
  selectedIds: string[];
  onSelectionChange: (id: string) => void;
  onViewDetail?: (id: string) => void;
  onAnalyze?: (id: string) => void;
  viewMode: ViewMode;
  universalFilters: UniversalFilters;
  onPreview: (data: PreviewData) => void;
}

export function DealListView({
  selectedIds,
  onSelectionChange,
  onViewDetail,
  onAnalyze,
  viewMode,
  universalFilters,
  onPreview
}: DealListViewProps) {
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
//   const [previewDeal, setPreviewDeal] = useState<string | null>(null);
  const [hoveredDeal, setHoveredDeal] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Array<{
    field: string;
    operator: string;
    value: any;
  }>>([]);
  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(
    new Set(dealColumns.map(col => col.id))
  );
  const { addItem, removeItem, isInBasket } = useBasket();
  const [showMetrics, setShowMetrics] = useState(true);


  const handlePreviewClick = (deal: Deal) => {
    onPreview({
      type: 'deals' as EntityType,
      id: deal.id,
      name: deal.name,
      metrics: {
        primary: [
          {
            name: 'Budget',
            value: deal.budget.total,
            change: ((deal.budget.spent / deal.budget.total) * 100),
            benchmark: 80,
            icon: ChartBarIcon
          },
          {
            name: 'Performance',
            value: deal.performance.viewability,
            change: deal.performance.pacing.trend[6] - deal.performance.pacing.trend[0],
            benchmark: 75,
            icon: ArrowTrendingUpIcon
          }
        ],
        performance: {
          viewability: deal.performance.viewability,
          ctr: deal.performance.ctr,
          completion: deal.performance.completion
        },
        distribution: [
          { name: 'Display', value: 60 },
          { name: 'Video', value: 25 },
          { name: 'Native', value: 15 }
        ],
        trends: deal.performance.pacing.trend.map((value, index) => ({
          date: `Day ${index + 1}`,
          value
        }))
      },
      details: {
        advertiser: deal.advertiser,
        type: deal.type,
        status: deal.status,
        health: deal.health.score,
        budget: deal.budget,
        performance: deal.performance,
        setup: deal.setup
      }
    });
  };

  const handleAddToBasket = (deal: any) => {
    addItem({
      id: deal.id,
      type: 'deals' as EntityType,
      name: deal.name,
      metrics: {
        viewability: deal.performance.viewability,
        completion: deal.performance.completion,
        ctr: deal.performance.ctr,
        budget: deal.budget.total
      }
    });
  };


  const filteredAndSortedDeals = useMemo(() => {
    let results = [...mockDeals];

    columnFilters.forEach(filter => {
      results = results.filter(deal => {
        const value = getValue(deal, filter.field);

        switch (filter.operator) {
          case 'equals':
            return value === filter.value;
          case 'contains':
            return String(value).toLowerCase().includes(String(filter.value).toLowerCase());
          case 'greater':
            return Number(value) > Number(filter.value);
          case 'less':
            return Number(value) < Number(filter.value);
          default:
            return true;
        }
      });
    });

    return results.sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      const aValue = getValue(a, sortField);
      const bValue = getValue(b, sortField);
      return direction * String(aValue).localeCompare(String(bValue));
    });
  }, [mockDeals, columnFilters, sortField, sortDirection]);

  const handleSort = useCallback((field: string) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  }, [sortField]);

  const handleAddFilter = useCallback((field: string, filter: any) => {
    setColumnFilters(prev => [...prev, { ...filter, field }]);
  }, []);

  const handleRemoveFilter = useCallback((index: number) => {
    setColumnFilters(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleClearFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);


  
  function renderDealContent(deal: any, column: DealColumn) {
    const value = getValue(deal, column.id);
  
    switch (column.id) {
      case 'name':
        return (
          <div className="space-y-1">
            <Text className="font-medium">{String(value)}</Text>
            <Text className="text-sm text-gray-500">{deal.advertiser}</Text>
          </div>
        );
      
      case 'status':
        return (
          <Badge color={
            value === 'active' ? 'emerald' :
            value === 'paused' ? 'yellow' : 'gray'
          }>
            {String(value)}
          </Badge>
        );
  
      case 'performance':
        return (
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Viewability</span>
              <span>{deal.performance.bidResponses}%</span>
            </div>
            <ProgressBar value={deal.performance.viewability} color="blue" />
          </div>
        );
      
      default:
        return <Text>{String(value)}</Text>;
    }
  }
  
  interface FilterType {
    type: 'text' | 'select' | 'number';
    field: string;
    operator: string;
    value: any;
  }
  

  const metrics = useMemo(() => {
    const selectedDeals = filteredAndSortedDeals.filter(d => selectedIds.includes(d.id));
    return {
      primary: [
        {
          label: 'Total Budget',
          value: selectedDeals.reduce((sum, d) => sum + d.budget.total, 0),
          trend: {
            direction: 'up',
            value: 15
          }
        },
        {
          label: 'Avg Viewability',
          value: selectedDeals.reduce((sum, d) => sum + d.performance.viewability, 0) / selectedDeals.length,
        }
      ],
      performance: {
        viewability: selectedDeals.reduce((sum, d) => sum + d.performance.viewability, 0) / selectedDeals.length,
        ctr: selectedDeals.reduce((sum, d) => sum + d.performance.ctr, 0) / selectedDeals.length,
        completion: selectedDeals.reduce((sum, d) => sum + d.performance.completion, 0) / selectedDeals.length
      }
    };
  }, [selectedIds, filteredAndSortedDeals]);

return (
   <div className="flex flex-col h-full">
     <ActiveFilters
       filters={columnFilters}
       onRemove={handleRemoveFilter}
       onClear={handleClearFilters}
     />

     <div className="flex-1 flex">
       <div className={`flex-1 overflow-auto ${showMetrics ? 'pr-4' : ''}`}>
         <div className="flex justify-between mb-4">
           <div className="flex space-x-2">
             {selectedIds.length > 0 && (
               <Text className="text-sm text-gray-500">
                 {selectedIds.length} deals selected
               </Text>
             )}
           </div>
           <div className="flex space-x-2">
             <Button 
               variant="secondary"
               onClick={() => setShowMetrics(!showMetrics)}
             >
               {showMetrics ? 'Hide' : 'Show'} Metrics
             </Button>
           </div>
         </div>

         <Table>
           <TableHead>
             <TableRow>
               <TableHeaderCell className="w-12">
                 <input
                   type="checkbox"
                   className="rounded border-gray-300"
                   checked={selectedIds.length === filteredAndSortedDeals.length}
                   onChange={() => {
                     const allIds = filteredAndSortedDeals.map(d => d.id);
                     allIds.forEach(id => onSelectionChange(id));
                   }}
                 />
               </TableHeaderCell>

               {dealColumns.map(column => (
                 visibleColumns.has(column.id) && (
                   <TableHeaderCell key={column.id}>
                     <div className="flex items-center justify-between">
                       <button
                         className="flex items-center space-x-1"
                         onClick={() => column.sortable && handleSort(column.id)}
                       >
                         <span>{column.label}</span>
                         {column.sortable && (
                           <ChevronUpDownIcon className="h-4 w-4 text-gray-400" />
                         )}
                       </button>
                       {column.filterable && (
                        <FilterPopover
                            field={column.id}
                            type={column.type as FilterType['type']}
                            options={column.options}
                            onFilter={handleAddFilter}
                            currentFilter={columnFilters.find(f => f.field === column.id)}
                            onRemove={() => handleRemoveFilter(
                                columnFilters.findIndex(f => f.field === column.id)
                            )}
                            />
                       )}
                     </div>
                   </TableHeaderCell>
                 )
               ))}

               <TableHeaderCell>
                 <div className="flex justify-end">
                   <ColumnCustomizer
                     columns={dealColumns}
                     visibleColumns={visibleColumns}
                     onChange={setVisibleColumns}
                     entityType="deals"
                   />
                 </div>
               </TableHeaderCell>
             </TableRow>
           </TableHead>

           <TableBody>
             {filteredAndSortedDeals.map(deal => (
               <TableRow
                 key={deal.id}
                 className={`
                   ${selectedIds.includes(deal.id) ? 'bg-blue-50' : ''}
                   ${hoveredDeal === deal.id ? 'bg-gray-50' : ''}
                 `}
                 onMouseEnter={() => setHoveredDeal(deal.id)}
                 onMouseLeave={() => setHoveredDeal(null)}
               >
                 <TableCell>
                   <input
                     type="checkbox"
                     className="rounded border-gray-300"
                     checked={selectedIds.includes(deal.id)}
                     onChange={() => onSelectionChange(deal.id)}
                   />
                 </TableCell>

                 {dealColumns.map(column => (
                   visibleColumns.has(column.id) && (
                     <TableCell key={column.id}>
                       {renderDealContent(deal, column)}
                     </TableCell>
                   )
                 ))}

                 <TableCell>
                   <div className="flex justify-end space-x-2">
                     <Button
                       variant={isInBasket(deal.id) ? 'primary' : 'secondary'}
                       size="sm"
                       onClick={() => handleAddToBasket(deal as any)}
                     >
                       <ShoppingBagIcon className="h-4 w-4" />
                     </Button>
                     <Button
  onClick={() => onPreview({
    type: 'deals' as EntityType,
    id: deal.id,
    name: deal.name,
    metrics: {
      primary: [
        {
          label: 'Budget',
          value: deal.budget.total,
          trend: {
            direction: deal.budget.pacing === 'ahead' ? 'up' : 'down',
            value: deal.performance.pacing.trend[6] - deal.performance.pacing.trend[0]
          }
        },
        {
          label: 'Performance',
          value: deal.performance.viewability,
          trend: {
            direction: deal.health.status === 'healthy' ? 'up' : 'down',
            value: deal.performance.pacing.trend[6]
          }
        }
      ],
      performance: {
        viewability: deal.performance.viewability,
        ctr: deal.performance.ctr,
        completion: deal.performance.completion
      }
    },
    details: {
      advertiser: deal.advertiser,
      type: deal.type,
      status: deal.status,
      health: deal.health.score
    }
  })}
>
  <EyeIcon className="h-4 w-4" />
</Button>
                     <Button
                       variant="ghost"
                       size="sm"
                       onClick={() => onAnalyze?.(deal.id)}
                     >
                       <ChartBarIcon className="h-4 w-4" />
                     </Button>
                   </div>
                 </TableCell>
               </TableRow>
             ))}
           </TableBody>
         </Table>
       </div>

       {/* {showMetrics && selectedIds.length > 0 && (
         <div className="w-80 border-l border-gray-200">
           <MetricsPanel 
             metrics={metrics}
             type="deals"
             onClose={() => setShowMetrics(false)}
           />
         </div>
       )} */}
     </div>

     {/* {previewDeal && (
       <PreviewPane
         data={{
           type: 'deal',
           id: previewDeal,
           name: filteredAndSortedDeals.find(d => d.id === previewDeal)?.name || '',
           metrics: {
             primary: [],
             performance: {
               viewability: filteredAndSortedDeals.find(d => d.id === previewDeal)?.performance.viewability || 0,
               ctr: filteredAndSortedDeals.find(d => d.id === previewDeal)?.performance.ctr || 0,
               completion: filteredAndSortedDeals.find(d => d.id === previewDeal)?.performance.completion || 0
             }
           },
           details: {}
         }}
         onClose={() => setPreviewDeal(null)}
         onViewDetail={onViewDetail || (() => {})}
         onAnalyze={onAnalyze || (() => {})}
       />
     )} */}
   </div>
 );
}