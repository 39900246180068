// src/components/deals/management/alerts/AlertIntegration.tsx

import { useState, useEffect, createContext, useContext } from 'react';
import { Card, Title, Text } from '@tremor/react';
import { AlertSystem } from './AlertSystem';
import { MonitoringAlerts } from './MonitoringAlerts';
import { HealthChecks } from './HealthChecks';
import { AlertNotification } from './AlertNotification';
import { Button } from '../../../ui/Button';

// Define types for the alert context
interface AlertContextType {
  addAlert: (alert: Alert) => void;
  removeAlert: (id: string) => void;
  updateAlertStatus: (id: string, status: AlertStatus) => void;
  triggerHealthCheck: (checkId: string) => Promise<void>;
  clearAlerts: () => void;
}

export interface Alert {
  id: string;
  type: 'health' | 'monitoring' | 'system';
  severity: 'critical' | 'high' | 'medium' | 'low';
  status: AlertStatus;
  title: string;
  message: string;
  timestamp: string;
  entity?: {
    type: string;
    id: string;
    name: string;
  };
  metrics?: Record<string, any>;
  aiRecommendation?: string;
}

type AlertStatus = 'new' | 'acknowledged' | 'in_progress' | 'resolved';

// Create context
const AlertContext = createContext<AlertContextType | undefined>(undefined);

// Custom hook for using alerts
export const useAlerts = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlerts must be used within an AlertProvider');
  }
  return context;
};

// Provider component
export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [notifications, setNotifications] = useState<Alert[]>([]);

  // Alert management functions
  const addAlert = (alert: Alert) => {
    setAlerts(prev => [...prev, alert]);
    // Add to notifications if critical or high severity
    if (['critical', 'high'].includes(alert.severity)) {
      setNotifications(prev => [...prev, alert]);
    }
  };

  const removeAlert = (id: string) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
    setNotifications(prev => prev.filter(alert => alert.id !== id));
  };

  const updateAlertStatus = (id: string, status: AlertStatus) => {
    setAlerts(prev => prev.map(alert => 
      alert.id === id ? { ...alert, status } : alert
    ));
  };

  const clearAlerts = () => {
    setAlerts([]);
    setNotifications([]);
  };

  // Health check integration
  const triggerHealthCheck = async (checkId: string) => {
    // Simulate health check
    await new Promise(resolve => setTimeout(resolve, 2000));
    // Add results to alerts if issues found
    // This would be replaced with actual health check logic
  };

  // Monitor for critical changes
  useEffect(() => {
    // Setup WebSocket or polling for real-time alerts
    const interval = setInterval(() => {
      // Check for new alerts
      // This would be replaced with actual monitoring logic
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AlertContext.Provider value={{
      addAlert,
      removeAlert,
      updateAlertStatus,
      triggerHealthCheck,
      clearAlerts
    }}>
      {children}
      {/* Notifications */}
      <div className="fixed bottom-4 right-4 z-50 space-y-2">
        {notifications.map(notification => (
          <AlertNotification
            key={notification.id}
            {...notification}
            onDismiss={() => removeAlert(notification.id)}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
}

// Main integration component
export function AlertIntegrationContainer() {
  const alertContext = useAlerts();
  const [activeView, setActiveView] = useState<'system' | 'monitoring' | 'health'>('system');

  return (
    <div className="space-y-6">
      {/* View Selection */}
      <Card>
        <div className="p-4 flex items-center justify-between">
          <div>
            <Title>Alert Management</Title>
            <Text className="text-gray-500">
              Comprehensive alert and monitoring system
            </Text>
          </div>
          <div className="flex space-x-2">
            <Button
              variant={activeView === 'system' ? 'primary' : 'secondary'}
              onClick={() => setActiveView('system')}
            >
              System Alerts
            </Button>
            <Button
              variant={activeView === 'monitoring' ? 'primary' : 'secondary'}
              onClick={() => setActiveView('monitoring')}
            >
              Monitoring
            </Button>
            <Button
              variant={activeView === 'health' ? 'primary' : 'secondary'}
              onClick={() => setActiveView('health')}
            >
              Health Checks
            </Button>
          </div>
        </div>
      </Card>

      {/* Active View */}
      <div>
        {activeView === 'system' && <AlertSystem />}
        {activeView === 'monitoring' && <MonitoringAlerts />}
        {activeView === 'health' && <HealthChecks />}
      </div>
    </div>
  );
}