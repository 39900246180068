// src/components/smartFinder/basket/BasketContext.tsx
import { createContext, useContext, useState, ReactNode } from 'react';
import { BasketItem } from '../../../types/smartFinder';

interface BasketContextType {
  items: BasketItem[];
  addItem: (item: BasketItem) => void;
  removeItem: (id: string) => void;
  clearBasket: () => void;
  isInBasket: (id: string) => boolean;
}

const BasketContext = createContext<BasketContextType | undefined>(undefined);

export function BasketProvider({ children }: { children: ReactNode }) {
  const [items, setItems] = useState<BasketItem[]>([]);

  const addItem = (item: BasketItem) => {
    setItems(prev => {
      // Don't add if already exists
      if (prev.some(i => i.id === item.id)) return prev;
      return [...prev, item];
    });
  };
  
  const removeItem = (id: string) => {
    setItems(prev => prev.filter(item => item.id !== id));
  };

  const clearBasket = () => setItems([]);
  
  const isInBasket = (id: string) => items.some(item => item.id === id);

  return (
    <BasketContext.Provider value={{ items, addItem, removeItem, clearBasket, isInBasket }}>
      {children}
    </BasketContext.Provider>
  );
}

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) throw new Error('useBasket must be used within BasketProvider');
  return context;
};