// src/components/smartFinder/entityViews/domains/DomainListView.tsx

import { useState, useMemo, useCallback } from 'react';
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Badge, Text, ProgressBar, Card } from '@tremor/react';
import { ChevronUpDownIcon, EyeIcon, ChartBarIcon,ShoppingBagIcon, ShieldCheckIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';
import { PreviewPane } from '../../shared/PreviewPane';
import { FilterPopover } from '../../filters/FilterPopover';
import { ActiveFilters } from '../../filters/ActiveFilters';
import { ColumnCustomizer } from '../../filters/ColumnCustomizer';
import { ViewMode, UniversalFilters, Domain, PreviewData, Deal } from '../../../../types/smartFinder';
import { enhancedMockDomains } from '../../../../data/mockData/combinedMockData';
import { useBasket } from '../../basket/BasketContext';
import { EntityType } from '../../../../types/smartFinder';
const domainColumns = [
  {
    id: 'url',
    label: 'Domain',
    type: 'text',
    sortable: true,
    filterable: true
  },
  {
    id: 'publisher',
    label: 'Publisher',
    type: 'text',
    sortable: true,
    filterable: true
  },
  {
    id: 'categories',
    label: 'Categories',
    type: 'text',
    sortable: true,
    filterable: true,
    options: [
      { value: 'News', label: 'News' },
      { value: 'Sports', label: 'Sports' },
      { value: 'Technology', label: 'Technology' }
    ]
  },
  {
    id: 'quality',
    label: 'Quality Score',
    type: 'number',
    sortable: true,
    filterable: true
  },
  {
    id: 'reach',
    label: 'Monthly Reach',
    type: 'number',
    sortable: true,
    filterable: true
  }
];

interface DomainListViewProps {
  selectedIds: string[];
  onSelectionChange: (id: string) => void;
  onViewDetail?: (id: string) => void;
  onAnalyze?: (id: string) => void;
  viewMode: ViewMode;
  universalFilters: UniversalFilters;
  onPreview: (data: PreviewData) => void;
}

const getValue = (domain: Domain, field: string) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      return (domain as any)[parent]?.[child];
    }
    return (domain as any)[field];
  };

export function DomainListView({
  selectedIds,
  onSelectionChange,
  onViewDetail,
  onAnalyze,
  viewMode,
  universalFilters,
  onPreview
}: DomainListViewProps) {
  const [sortField, setSortField] = useState<string>('url');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [previewDomain, setPreviewDomain] = useState<string | null>(null);
  const [hoveredDomain, setHoveredDomain] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Array<{
    field: string;
    operator: string;
    value: any;
  }>>([]);
  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(
    new Set(domainColumns.map(col => col.id))
  );

  const { addItem, removeItem, isInBasket } = useBasket();
  
  const handleAddToBasket = (domain: Domain) => {
    addItem({
      id: domain.id,
      type: 'domains' as EntityType,
      name: domain.url,
      metrics: {
        reach: domain.reach.monthly,
        viewability: domain.quality.viewability,
        completion: domain.quality.completion
      }
    });
  };

  const handlePreviewClick = (domain: Domain) => {
    onPreview({
      type: 'domains' as EntityType,
      id: domain.id,
      name: domain.url,
      metrics: {
        primary: [
          {
            name: 'Quality Score',
            value: domain.quality.score,
            benchmark: 90,
            icon: ShieldCheckIcon
          },
          {
            name: 'Monthly Reach',
            value: domain.reach.monthly / 1000000,
            change: domain.reach.trend.value,
            icon: GlobeAltIcon
          }
        ],
        performance: {
          viewability: domain.quality.viewability,
          completion: domain.quality.completion
        },
        distribution: [
          { name: 'Brand Safety', value: domain.quality.brand_safety },
          { name: 'Viewability', value: domain.quality.viewability },
          { name: 'Completion', value: domain.quality.completion }
        ]
      },
      details: {
        publisher: domain.publisher,
        categories: domain.categories,
        quality: {
          score: domain.quality.score,
          brandSafety: domain.quality.brand_safety,
          viewability: domain.quality.viewability,
          completion: domain.quality.completion
        },
        reach: {
          monthly: domain.reach.monthly,
          trend: domain.reach.trend
        },
        path: domain.path
      }
    });
   };
  
  const filteredAndSortedDomains = useMemo(() => {
    let results = [...enhancedMockDomains];

    if (universalFilters.region.length) {
      results = results.filter(domain => 
        universalFilters.region.some(region => 
          domain.path.includes(region)
        )
      );
    }

    columnFilters.forEach(filter => {
      results = results.filter(domain => {
        const value = getValue(domain, filter.field);

        switch (filter.operator) {
          case 'equals':
            return value === filter.value;
          case 'contains':
            return String(value).toLowerCase().includes(String(filter.value).toLowerCase());
          case 'greater':
            return Number(value) > Number(filter.value);
          case 'less':
            return Number(value) < Number(filter.value);
          default:
            return true;
        }
      });
    });

    return results.sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      let aValue = getValue(a, sortField);
      let bValue = getValue(b, sortField);

      if (sortField === 'quality') {
        aValue = a.quality.score;
        bValue = b.quality.score;
      }

      if (typeof aValue === 'number') {
        return direction * (aValue - bValue);
      }
      return direction * String(aValue).localeCompare(String(bValue));
    });
  }, [enhancedMockDomains, universalFilters, columnFilters, sortField, sortDirection]);

  const handleSort = useCallback((field: string) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  }, [sortField]);

  const handleAddFilter = useCallback((field: string, filter: any) => {
    setColumnFilters(prev => [...prev, { ...filter, field }]);
  }, []);

  const handleRemoveFilter = useCallback((index: number) => {
    setColumnFilters(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleClearFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);

  // Grid view implementation...
  if (viewMode === 'grid') {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {/* Grid view JSX */}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <ActiveFilters
        filters={columnFilters}
        onRemove={handleRemoveFilter}
        onClear={handleClearFilters}
      />

      <div className="flex-1 overflow-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell className="w-12">
                <input
                  type="checkbox"
                  className="rounded border-gray-300"
                  checked={selectedIds.length === filteredAndSortedDomains.length}
                  onChange={() => {
                    const allIds = filteredAndSortedDomains.map(d => d.id);
                    allIds.forEach(id => onSelectionChange(id));
                  }}
                />
              </TableHeaderCell>

              {domainColumns.map(column => (
                visibleColumns.has(column.id) && (
                  <TableHeaderCell key={column.id}>
                    <div className="flex items-center justify-between">
                      <button
                        className="flex items-center space-x-1"
                        onClick={() => column.sortable && handleSort(column.id)}
                      >
                        <span>{column.label}</span>
                        {column.sortable && (
                          <ChevronUpDownIcon className="h-4 w-4 text-gray-400" />
                        )}
                      </button>
                      {column.filterable && (
                        <FilterPopover
                          field={column.id}
                          type={column.type as 'text' | 'number' | 'select'}
                          options={column.options}
                          onFilter={handleAddFilter}
                          currentFilter={columnFilters.find(f => f.field === column.id)}
                          onRemove={() => handleRemoveFilter(
                            columnFilters.findIndex(f => f.field === column.id)
                          )}
                        />
                      )}
                    </div>
                  </TableHeaderCell>
                )
              ))}

              <TableHeaderCell>
                <div className="flex justify-end">
                  <ColumnCustomizer
                    columns={domainColumns}
                    visibleColumns={visibleColumns}
                    onChange={setVisibleColumns}
                    entityType="domains"
                  />
                </div>
              </TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredAndSortedDomains.map(domain => (
              <TableRow
                key={domain.id}
                className={`
                  ${selectedIds.includes(domain.id) ? 'bg-blue-50' : ''}
                  ${hoveredDomain === domain.id ? 'bg-gray-50' : ''}
                `}
                onMouseEnter={() => setHoveredDomain(domain.id)}
                onMouseLeave={() => setHoveredDomain(null)}
              >
                <TableCell>
                  <input
                    type="checkbox"
                    className="rounded border-gray-300"
                    checked={selectedIds.includes(domain.id)}
                    onChange={() => onSelectionChange(domain.id)}
                  />
                </TableCell>

                {domainColumns.map(column => (
                  visibleColumns.has(column.id) && (
                    <TableCell key={column.id}>
                      {renderDomainCellContent(domain, column)}
                    </TableCell>
                  )
                ))}

                <TableCell>
                  <div className="flex justify-end space-x-2">
                  <Button
                       variant={isInBasket(domain.id) ? 'primary' : 'secondary'}
                       size="sm"
                       onClick={() => handleAddToBasket(domain)}
                     >
                       <ShoppingBagIcon className="h-4 w-4" />
                     </Button>
<Button
  onClick={() => onPreview({
    type: 'domains' as EntityType,
    id: domain.id,
    name: domain.url,
    metrics: {
      primary: [
        {
          label: 'Quality Score',
          value: domain.quality.score
        },
        {
          label: 'Monthly Reach',
          value: domain.reach.monthly,
          trend: {
            direction: domain.reach.trend.direction as 'up' | 'down' | 'stable',
            value: domain.reach.trend.value
          }
        }
      ],
      performance: {
        viewability: domain.quality.viewability
      }
    },
    details: {
      publisher: domain.publisher,
      categories: domain.categories,
      brandSafety: domain.quality.brand_safety
    }
  })}
>
  <EyeIcon className="h-4 w-4" />
</Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => onAnalyze?.(domain.id)}
                    >
                      <ChartBarIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {previewDomain && (
        <PreviewPane
          data={{
            type: 'domain',
            id: previewDomain,
            name: filteredAndSortedDomains.find(d => d.id === previewDomain)?.url || '',
            metrics: {
              primary: [],
              performance: {
                viewability: filteredAndSortedDomains.find(d => d.id === previewDomain)?.quality.viewability || 0
              }
            },
            details: {}
          }}
          onClose={() => setPreviewDomain(null)}
          onViewDetail={onViewDetail || (() => {})}
          onAnalyze={onAnalyze || (() => {})}
        />
      )}
    </div>
  );
}

function renderDomainCellContent(domain: Domain, column: any) {
  switch (column.id) {
    case 'url':
      return (
        <div>
          <Text className="font-medium">{domain.url}</Text>
          <Text className="text-sm text-gray-500">{domain.publisher}</Text>
        </div>
      );

    case 'categories':
      return (
        <div className="flex flex-wrap gap-1">
          {domain.categories.map(category => (
            <Badge key={category} color="gray" size="sm">
              {category}
            </Badge>
          ))}
        </div>
      );

    case 'quality':
      return (
        <div className="space-y-2">
          <div className="flex justify-between">
            <Text>{domain.quality.score}</Text>
            <Badge color={
              domain.quality.score >= 90 ? 'emerald' :
              domain.quality.score >= 80 ? 'blue' : 'yellow'
            }>
              {domain.quality.score >= 90 ? 'Premium' :
               domain.quality.score >= 80 ? 'High' : 'Standard'}
            </Badge>
          </div>
          <ProgressBar
            value={domain.quality.score}
            color={
              domain.quality.score >= 90 ? 'emerald' :
              domain.quality.score >= 80 ? 'blue' : 'yellow'
            }
          />
        </div>
      );

    case 'reach':
      return (
        <div>
          <Text className="font-medium">
            {(domain.reach.monthly / 1000000).toFixed(1)}M
          </Text>
          {domain.reach.trend.direction !== 'stable' && (
            <Badge color={domain.reach.trend.direction === 'up' ? 'emerald' : 'red'} size="sm">
              {domain.reach.trend.direction === 'up' ? '+' : '-'}{domain.reach.trend.value}%
            </Badge>
          )}
        </div>
      );

    default:
      return <Text>{getValue(domain, column.id)}</Text>;
  }
}