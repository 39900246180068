// src/hooks/useAlertMonitoring.ts

import { useState, useEffect } from 'react';
import { useAlerts } from '../components/deals/management/alerts/AlertIntegration';
import type {Alert } from '../components/deals/management/alerts/AlertIntegration';

interface UseAlertMonitoringOptions {
  entity?: {
    type: string;
    id: string;
  };
  autoRefresh?: boolean;
  refreshInterval?: number;
}


export function useAlertMonitoring(options: UseAlertMonitoringOptions = {}) {
  const alertContext = useAlerts();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Alert management
  const createAlert = async (alertData: Omit<Alert, 'id' | 'timestamp' | 'status'>) => {
    try {
      const newAlert = {
        ...alertData,
        id: `ALT-${Date.now()}`,
        timestamp: new Date().toISOString(),
        status: 'new' as const
      };
      alertContext.addAlert(newAlert);
      return newAlert;
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to create alert'));
      throw err;
    }
  };

  // Health check integration
  const runHealthCheck = async (checkId: string) => {
    setLoading(true);
    try {
      await alertContext.triggerHealthCheck(checkId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Health check failed'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Auto-refresh monitoring
  useEffect(() => {
    if (!options.autoRefresh) return;

    const interval = setInterval(() => {
      // Refresh monitoring data
      // This would be replaced with actual refresh logic
    }, options.refreshInterval || 30000);

    return () => clearInterval(interval);
  }, [options.autoRefresh, options.refreshInterval]);

  return {
    loading,
    error,
    createAlert,
    runHealthCheck,
    ...alertContext
  };
}