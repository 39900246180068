import { useMemo } from 'react';
import { Card, Text, Badge, ProgressBar } from '@tremor/react';
import { XMarkIcon, EyeIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

interface EntityMetrics {
  reach?: number;
  performance?: {
    viewability: number;
    completion?: number;
    ctr?: number;
  };
  budget?: {
    total: number;
    spent: number;
  };
}

interface SelectionSummaryProps {
  selectedIds: string[];
  metrics: EntityMetrics | null;
  onClear: () => void;
  onRemove: (id: string) => void;
  onPreview?: (id: string) => void;
  onAnalyze?: (ids: string[]) => void;
}

export function SelectionSummary({
  selectedIds,
  metrics,
  onClear,
  onRemove,
  onPreview,
  onAnalyze
}: SelectionSummaryProps) {
  // Calculate aggregated metrics
  const aggregatedMetrics = useMemo(() => {
    if (!metrics) return null;

    return {
      totalReach: metrics.reach?.toLocaleString(),
      avgViewability: metrics.performance?.viewability.toFixed(1) + '%',
      avgCompletion: metrics.performance?.completion?.toFixed(1) + '%',
      avgCTR: metrics.performance?.ctr ? (metrics.performance.ctr * 100).toFixed(2) + '%' : undefined,
      totalBudget: metrics.budget?.total ? '$' + metrics.budget.total.toLocaleString() : undefined
    };
  }, [metrics]);

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Text className="font-medium">Selected Items</Text>
          <Badge color="blue">{selectedIds.length}</Badge>
        </div>
        <div className="flex items-center space-x-2">
          {onAnalyze && (
            <Button variant="secondary" size="sm" onClick={() => onAnalyze(selectedIds)}>
              <ChartBarIcon className="h-4 w-4 mr-1" />
              Analyze
            </Button>
          )}
          <Button variant="ghost" size="sm" onClick={onClear}>
            Clear all
          </Button>
        </div>
      </div>

      {/* Aggregated Metrics */}
      {aggregatedMetrics && (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
          {aggregatedMetrics.totalReach && (
            <div>
              <Text className="text-sm text-gray-500">Total Reach</Text>
              <Text className="font-medium">{aggregatedMetrics.totalReach}</Text>
            </div>
          )}
          {aggregatedMetrics.avgViewability && (
            <div>
              <Text className="text-sm text-gray-500">Avg. Viewability</Text>
              <div className="space-y-1">
                <Text className="font-medium">{aggregatedMetrics.avgViewability}</Text>
                <ProgressBar 
                  value={parseFloat(aggregatedMetrics.avgViewability)} 
                  color="blue"
                />
              </div>
            </div>
          )}
          {aggregatedMetrics.avgCompletion && (
            <div>
              <Text className="text-sm text-gray-500">Avg. Completion</Text>
              <div className="space-y-1">
                <Text className="font-medium">{aggregatedMetrics.avgCompletion}</Text>
                <ProgressBar 
                  value={parseFloat(aggregatedMetrics.avgCompletion)} 
                  color="emerald"
                />
              </div>
            </div>
          )}
          {aggregatedMetrics.avgCTR && (
            <div>
              <Text className="text-sm text-gray-500">Avg. CTR</Text>
              <Text className="font-medium">{aggregatedMetrics.avgCTR}</Text>
            </div>
          )}
          {aggregatedMetrics.totalBudget && (
            <div>
              <Text className="text-sm text-gray-500">Total Budget</Text>
              <Text className="font-medium">{aggregatedMetrics.totalBudget}</Text>
            </div>
          )}
        </div>
      )}

      {/* Selected Items List */}
      <div className="space-y-2">
        {selectedIds.map((id) => (
          <Card key={id} className="!p-2">
            <div className="flex items-center justify-between">
              <Text>{id}</Text>
              <div className="flex items-center space-x-2">
                {onPreview && (
                  <button
                    className="p-1 hover:bg-gray-100 rounded-full"
                    onClick={() => onPreview(id)}
                  >
                    <EyeIcon className="h-4 w-4 text-gray-500" />
                  </button>
                )}
                <button
                  className="p-1 hover:bg-gray-100 rounded-full"
                  onClick={() => onRemove(id)}
                >
                  <XMarkIcon className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {/* Summary Actions */}
      <div className="flex justify-between items-center pt-4 border-t border-gray-200">
        <Text className="text-sm text-gray-500">
          {selectedIds.length} items selected
        </Text>
        <div className="flex space-x-2">
          <Button variant="secondary" size="sm" onClick={onClear}>
            Clear Selection
          </Button>
          {onAnalyze && (
            <Button variant="primary" size="sm" onClick={() => onAnalyze(selectedIds)}>
              Analyze Selection
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}