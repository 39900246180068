import { 
    HomeIcon, 
    ChevronRightIcon 
  } from '@heroicons/react/24/outline';
  
  interface BreadcrumbItem {
    id: string;
    label: string;
    type?: 'folder' | 'category' | 'root';
  }
  
  interface BreadcrumbNavProps {
    items: BreadcrumbItem[];
    onNavigate: (item: BreadcrumbItem) => void;
    entityType: 'deals' | 'audiences' | 'domains';
  }
  
  export function BreadcrumbNav({
    items,
    onNavigate,
    entityType
  }: BreadcrumbNavProps) {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2">
          {/* Home/Root */}
          <li>
            <button
              onClick={() => onNavigate({ id: 'root', label: 'All', type: 'root' })}
              className="text-gray-500 hover:text-gray-700 flex items-center"
            >
              <HomeIcon className="h-4 w-4" />
              <span className="sr-only">Home</span>
            </button>
          </li>
  
          {/* Items */}
          {items.map((item, index) => (
            <li key={item.id} className="flex items-center">
              <ChevronRightIcon className="h-4 w-4 text-gray-400 flex-shrink-0" />
              <button
                onClick={() => onNavigate(item)}
                className={`ml-2 text-sm font-medium ${
                  index === items.length - 1
                    ? 'text-gray-900'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ol>
  
        {/* Current count */}
        {items.length > 0 && (
          <div className="ml-4 flex items-center">
            <div className="text-xs text-gray-500 bg-gray-100 rounded-full px-2 py-0.5">
              {entityType === 'deals' ? 'Deals' :
               entityType === 'audiences' ? 'Segments' :
               'Domains'
              }
            </div>
          </div>
        )}
      </nav>
    );
  }