// src/components/deals/management/views/BrandView.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  AreaChart,
  DonutChart,
  Grid,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@tremor/react';
import {
  BuildingOfficeIcon,
  SparklesIcon,
  ShieldCheckIcon,
  ChartBarIcon,
  BanknotesIcon,
  RocketLaunchIcon,
  CheckCircleIcon,
  ClockIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample brand metrics data
const brandMetrics = {
  brands: [
    {
      id: 'BRD001',
      name: 'AutoMax Corp',
      agency: 'MediaCom',
      vertical: 'Automotive',
      metrics: {
        activeDeals: 12,
        totalBudget: 1500000,
        spentBudget: 850000,
        healthScore: 88,
        activeOpportunities: 3,
        dealSuccess: 92
      },
      performance: {
        deals: {
          active: 8,
          pending: 2,
          completed: 2
        },
        kpis: {
          viewability: 85,
          completion: 78,
          ctrs: 0.12
        },
        trends: [
          { date: '2024-01', health: 85, spend: 250000, deals: 10 },
          { date: '2024-02', health: 87, spend: 280000, deals: 11 },
          { date: '2024-03', health: 88, spend: 320000, deals: 12 }
        ]
      },
      opportunities: [
        {
          id: 'OPP001',
          name: 'Q2 Luxury Campaign',
          status: 'active',
          budget: 500000,
          progress: 65,
          dealCount: 3,
          launchDate: '2024-04-01'
        },
        {
          id: 'OPP002',
          name: 'Summer Performance',
          status: 'pending',
          budget: 750000,
          progress: 25,
          dealCount: 4,
          launchDate: '2024-06-01'
        }
      ]
    },
    // More brands...
  ],
  insights: [
    {
      id: 'INS001',
      type: 'Performance',
      brand: 'AutoMax Corp',
      message: 'Above average performance in premium inventory',
      recommendation: 'Consider expanding premium deal coverage',
      impact: 'high'
    },
    {
      id: 'INS002',
      type: 'Setup',
      brand: 'AutoMax Corp',
      message: 'New opportunity launch approaching',
      recommendation: 'Review launch checklist for summer campaign',
      impact: 'medium'
    }
  ]
};

const dealTypes = {
  pmp: { count: 15, revenue: 800000 },
  preferred: { count: 22, revenue: 1200000 },
  guaranteed: { count: 8, revenue: 500000 }
};

export function BrandView() {
  const [selectedBrand, setSelectedBrand] = useState(brandMetrics.brands[0]);
  const [selectedTab, setSelectedTab] = useState('overview');

  return (
    <div className="space-y-6">
      {/* AI Recommendations Banner */}
      <Card className="bg-gradient-to-r from-indigo-50 to-blue-50 border-blue-100">
        <div className="flex items-start space-x-4">
          <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
          <div className="flex-1">
            <Title className="text-blue-800">AI-Powered Brand Insights</Title>
            <Text className="text-blue-700 mt-1">
              {selectedBrand.name} shows strong performance in premium inventory. 
              Consider expanding premium deal coverage for upcoming campaigns.
            </Text>
            <div className="mt-4 flex flex-wrap gap-4">
              <Button variant="secondary" size="sm">
                View Recommendations
              </Button>
              <Button variant="primary" size="sm">
                Generate Strategy
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Brand Performance Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Active Deals</Text>
              <Metric>{selectedBrand.metrics.activeDeals}</Metric>
              <div className="mt-2 flex items-center space-x-2">
                <Text className="text-sm text-gray-500">Completion Rate</Text>
                <Badge color="green">92%</Badge>
              </div>
            </div>
            <RocketLaunchIcon className="h-6 w-6 text-blue-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Budget Utilization</Text>
              <Metric>
                ${(selectedBrand.metrics.spentBudget / 1000000).toFixed(1)}M
              </Metric>
              <div className="mt-2">
                <ProgressBar 
                  value={(selectedBrand.metrics.spentBudget / selectedBrand.metrics.totalBudget) * 100} 
                  color="blue" 
                />
              </div>
            </div>
            <BanknotesIcon className="h-6 w-6 text-green-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Health Score</Text>
              <Metric>{selectedBrand.metrics.healthScore}</Metric>
              <div className="mt-2 flex items-center space-x-2">
                <Text className="text-sm text-gray-500">Trending</Text>
                <Badge color="green">↑ 3 pts</Badge>
              </div>
            </div>
            <ShieldCheckIcon className="h-6 w-6 text-emerald-500" />
          </div>
        </Card>

        <Card className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <Text>Deal Success Rate</Text>
              <Metric>{selectedBrand.metrics.dealSuccess}%</Metric>
              <div className="mt-2 flex items-center space-x-2">
                <Text className="text-sm text-gray-500">vs Target</Text>
                <Badge color="green">+5%</Badge>
              </div>
            </div>
            <CheckCircleIcon className="h-6 w-6 text-blue-500" />
          </div>
        </Card>
      </div>

      {/* Main Content */}
      <Card>
        <TabGroup>
          <TabList className="p-4">
            <Tab>Overview</Tab>
            <Tab>Opportunities</Tab>
            <Tab>Performance</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <div className="p-4 space-y-6">
                {/* Deal Distribution */}
                <Card>
                  <div className="p-4">
                    <Title>Deal Type Distribution</Title>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                      <DonutChart
                        data={[
                          { name: 'PMP', value: dealTypes.pmp.count },
                          { name: 'Preferred', value: dealTypes.preferred.count },
                          { name: 'Guaranteed', value: dealTypes.guaranteed.count }
                        ]}
                        category="value"
                        index="name"
                        colors={['blue', 'green', 'amber']}
                        className="h-40"
                      />
                      <div className="space-y-4">
                        {Object.entries(dealTypes).map(([type, data]) => (
                          <div key={type} className="flex justify-between items-center">
                            <div>
                              <Text className="capitalize">{type}</Text>
                              <Text className="text-sm text-gray-500">
                                {data.count} deals
                              </Text>
                            </div>
                            <Text className="font-medium">
                              ${(data.revenue / 1000000).toFixed(1)}M
                            </Text>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>

                {/* Performance Trends */}
                <Card>
                  <div className="p-4">
                    <Title>Performance Trends</Title>
                    <AreaChart
                      className="h-72 mt-4"
                      data={selectedBrand.performance.trends}
                      index="date"
                      categories={['health', 'deals']}
                      colors={['blue', 'green']}
                      valueFormatter={(number: number) =>
                        typeof number === 'number' ? number.toString() : ''
                      }
                    />
                  </div>
                </Card>
              </div>
            </TabPanel>

            {/* Opportunities Panel */}
            <TabPanel>
              <div className="p-4 space-y-4">
                {selectedBrand.opportunities.map((opp) => (
                  <Card key={opp.id} className="p-4">
                    <div className="flex items-start justify-between">
                      <div>
                        <div className="flex items-center space-x-2">
                          <Title>{opp.name}</Title>
                          <Badge color={
                            opp.status === 'active' ? 'green' : 
                            opp.status === 'pending' ? 'yellow' : 'gray'
                          }>
                            {opp.status}
                          </Badge>
                        </div>
                        <div className="mt-2 space-y-2">
                          <div className="flex items-center space-x-4">
                            <Text className="text-sm text-gray-500">
                              Budget: ${(opp.budget / 1000000).toFixed(1)}M
                            </Text>
                            <Text className="text-sm text-gray-500">
                              Deals: {opp.dealCount}
                            </Text>
                            <Text className="text-sm text-gray-500">
                              Launch: {new Date(opp.launchDate).toLocaleDateString()}
                            </Text>
                          </div>
                          <ProgressBar 
                            value={opp.progress} 
                            color="blue"
                            className="mt-2" 
                          />
                          <Text className="text-sm text-gray-500">
                            Setup Progress: {opp.progress}%
                          </Text>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <Button variant="secondary" size="sm">
                          <ChartBarIcon className="h-4 w-4 mr-1" />
                          View Details
                        </Button>
                        <Button variant="primary" size="sm">
                          <RocketLaunchIcon className="h-4 w-4 mr-1" />
                          {opp.status === 'pending' ? 'Launch' : 'Manage'}
                        </Button>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </TabPanel>

            {/* Performance Panel */}
            <TabPanel>
              <div className="p-4 space-y-6">
                {/* KPI Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {Object.entries(selectedBrand.performance.kpis).map(([key, value]) => (
                    <Card key={key} className="p-4">
                      <Text className="capitalize">{key}</Text>
                      <div className="mt-2 flex items-baseline space-x-2">
                        <Metric>
                          {typeof value === 'number' 
                            ? value < 1 
                              ? `${(value * 100).toFixed(2)}%`
                              : `${value}%`
                            : value}
                        </Metric>
                        <Badge color="green">↑ 2.5%</Badge>
                      </div>
                    </Card>
                  ))}
                </div>

                {/* Insights */}
                <div className="space-y-4">
                  <Title>AI-Generated Insights</Title>
                  {brandMetrics.insights.map((insight) => (
                    <Card key={insight.id} className="p-4">
                      <div className="flex items-start justify-between">
                        <div>
                          <div className="flex items-center space-x-2">
                            <SparklesIcon className="h-5 w-5 text-blue-500" />
                            <Text className="font-medium">{insight.type}</Text>
                            <Badge color={
                              insight.impact === 'high' ? 'red' : 
                              insight.impact === 'medium' ? 'yellow' : 'blue'
                            }>
                              {insight.impact} impact
                            </Badge>
                          </div>
                          <Text className="mt-2">{insight.message}</Text>
                          <Text className="mt-1 text-blue-600">
                            Recommendation: {insight.recommendation}
                          </Text>
                        </div>
                        <Button variant="secondary" size="sm">
                          Take Action
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Card>
    </div>
  );
}