// src/components/deals/management/metrics/PerformanceMetrics.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Metric,
  Badge,
  ProgressBar,
  AreaChart,
  BarChart,
  TabGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Select,
  SelectItem
} from '@tremor/react';
import {
  BanknotesIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  BuildingLibraryIcon,
  SignalIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

// Sample performance data
const performanceData = {
  bidding: {
    current: {
      avgBidCPM: 12.50,
      avgWinCPM: 8.75,
      spendCPM: 9.20,
      requestVolume: 1500000,
      activeDomainsRatio: 85,
      dspMetrics: {
        responseTime: 45, // ms
        qps: 2500,
        timeout: 2.5 // percentage
      }
    },
    trends: [
      {
        date: '2024-01',
        'Bid CPM': 11.80,
        'Win CPM': 8.20,
        'Spend CPM': 8.80,
        'Request Volume': 1200000
      },
      {
        date: '2024-02',
        'Bid CPM': 12.20,
        'Win CPM': 8.50,
        'Spend CPM': 9.00,
        'Request Volume': 1350000
      },
      {
        date: '2024-03',
        'Bid CPM': 12.50,
        'Win CPM': 8.75,
        'Spend CPM': 9.20,
        'Request Volume': 1500000
      }
    ]
  },
  domains: {
    status: [
      { name: 'Active', value: 850 },
      { name: 'Inactive', value: 150 },
      { name: 'Blocked', value: 50 }
    ],
    topPerforming: [
      {
        domain: 'premium-news.com',
        impressions: 250000,
        winRate: 35,
        viewability: 92
      },
      {
        domain: 'top-media.com',
        impressions: 180000,
        winRate: 32,
        viewability: 88
      }
      // Add more domains...
    ]
  },
  kpis: {
    clientMetrics: {
      viewability: { value: 85, target: 80, trend: 3 },
      completion: { value: 78, target: 75, trend: 2 },
      ctr: { value: 0.12, target: 0.10, trend: 0.02 }
    },
    predictions: [
      {
        metric: 'Expected Revenue',
        value: 450000,
        trend: 8,
        confidence: 85
      },
      {
        metric: 'Projected Win Rate',
        value: 25,
        trend: 3,
        confidence: 92
      }
    ]
  }
};

export function PerformanceMetrics() {
  const [timeframe, setTimeframe] = useState('7d');
  const [selectedMetric, setSelectedMetric] = useState('bidding');

  const MetricCard = ({ 
    title, 
    value, 
    trend, 
    icon: Icon,
    prefix = '',
    suffix = ''
  }: {
    title: string;
    value: number;
    trend?: number;
    icon: any;
    prefix?: string;
    suffix?: string;
  }) => (
    <Card className="p-4">
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-center space-x-2">
            <Icon className="h-5 w-5 text-gray-500" />
            <Text>{title}</Text>
          </div>
          <div className="mt-2 flex items-baseline space-x-2">
            <Metric>
              {prefix}{typeof value === 'number' && value < 1 
                ? (value * 100).toFixed(2) 
                : value.toFixed(2)}{suffix}
            </Metric>
            {trend && (
              <Badge 
                color={trend > 0 ? 'green' : 'red'}
                icon={trend > 0 ? ArrowTrendingUpIcon : ArrowTrendingDownIcon}
              >
                {trend > 0 ? '+' : ''}{trend}%
              </Badge>
            )}
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="space-y-6">
      {/* Time Range Selector */}
      <div className="flex justify-between items-center">
        <Title>Performance Metrics</Title>
        <Select 
          value={timeframe} 
          onValueChange={setTimeframe}
          className="w-32"
        >
          <SelectItem value="1d">Last 24h</SelectItem>
          <SelectItem value="7d">Last 7 days</SelectItem>
          <SelectItem value="30d">Last 30 days</SelectItem>
          <SelectItem value="90d">Last 90 days</SelectItem>
        </Select>
      </div>

      {/* AI Insights Banner */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="p-4 flex items-start space-x-3">
          <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
          <div>
            <Title className="text-blue-800">Performance Insights</Title>
            <Text className="text-blue-700 mt-1">
              CPM efficiency has improved by 12% this period. Consider expanding budget 
              allocation for high-performing domains showing consistent viewability above 85%.
            </Text>
          </div>
        </div>
      </Card>

      {/* Main Content */}
      <TabGroup>
        <TabList>
          <Tab>Bidding Metrics</Tab>
          <Tab>Domain Performance</Tab>
          <Tab>Client KPIs</Tab>
        </TabList>

        <TabPanels>
          {/* Bidding Metrics Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* CPM Metrics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <MetricCard
                  title="Avg Bid CPM"
                  value={performanceData.bidding.current.avgBidCPM}
                  trend={5}
                  icon={BanknotesIcon}
                  prefix="$"
                />
                <MetricCard
                  title="Avg Win CPM"
                  value={performanceData.bidding.current.avgWinCPM}
                  trend={3}
                  icon={BanknotesIcon}
                  prefix="$"
                />
                <MetricCard
                  title="Spend CPM"
                  value={performanceData.bidding.current.spendCPM}
                  trend={4}
                  icon={BanknotesIcon}
                  prefix="$"
                />
              </div>

              {/* CPM Trends */}
              <Card>
                <div className="p-4">
                  <Title>CPM Trends</Title>
                  <AreaChart
                    className="h-72 mt-4"
                    data={performanceData.bidding.trends}
                    index="date"
                    categories={['Bid CPM', 'Win CPM', 'Spend CPM']}
                    colors={['blue', 'green', 'yellow']}
                    valueFormatter={(value) => `$${value.toFixed(2)}`}
                  />
                </div>
              </Card>

              {/* DSP Metrics */}
              <Card>
                <div className="p-4">
                  <Title>DSP Performance</Title>
                  <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <MetricCard
                      title="Response Time"
                      value={performanceData.bidding.current.dspMetrics.responseTime}
                      trend={-10}
                      icon={SignalIcon}
                      suffix="ms"
                    />
                    <MetricCard
                      title="QPS"
                      value={performanceData.bidding.current.dspMetrics.qps}
                      trend={15}
                      icon={ChartBarIcon}
                    />
                    <MetricCard
                      title="Timeout Rate"
                      value={performanceData.bidding.current.dspMetrics.timeout}
                      trend={-0.5}
                      icon={SignalIcon}
                      suffix="%"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>

          {/* Domain Performance Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* Domain Status */}
              <Card>
                <div className="p-4">
                  <Title>Domain Status</Title>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                    <BarChart
                      className="h-64"
                      data={performanceData.domains.status}
                      index="name"
                      categories={['value']}
                      colors={['blue']}
                    />
                    <div className="space-y-4">
                      {performanceData.domains.status.map((status) => (
                        <div key={status.name} className="flex justify-between items-center">
                          <Text>{status.name}</Text>
                          <div className="flex items-center space-x-2">
                            <Text className="font-medium">{status.value}</Text>
                            <Text className="text-gray-500">domains</Text>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>

              {/* Top Performing Domains */}
              <Card>
                <div className="p-4">
                  <Title>Top Performing Domains</Title>
                  <div className="mt-4 space-y-4">
                    {performanceData.domains.topPerforming.map((domain) => (
                      <Card key={domain.domain}>
                        <div className="p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <Text className="font-medium">{domain.domain}</Text>
                              <div className="mt-2 flex items-center space-x-4">
                                <Text className="text-sm text-gray-500">
                                  Impressions: {domain.impressions.toLocaleString()}
                                </Text>
                                <Text className="text-sm text-gray-500">
                                  Win Rate: {domain.winRate}%
                                </Text>
                                <Text className="text-sm text-gray-500">
                                  Viewability: {domain.viewability}%
                                </Text>
                              </div>
                            </div>
                            <Button variant="secondary" size="sm">
                              View Details
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>

          {/* Client KPIs Panel */}
          <TabPanel>
            <div className="mt-4 space-y-6">
              {/* Current KPIs */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {Object.entries(performanceData.kpis.clientMetrics).map(([key, data]) => (
                  <Card key={key} className="p-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <Text className="capitalize">{key}</Text>
                        <div className="mt-2 flex items-baseline space-x-2">
                          <Metric>
                            {typeof data.value === 'number' && data.value < 1 
                              ? (data.value * 100).toFixed(2) 
                              : data.value}%
                          </Metric>
                          <Badge color={data.trend > 0 ? 'green' : 'red'}>
                            {data.trend > 0 ? '+' : ''}{data.trend}%
                          </Badge>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between text-sm mb-1">
                        <Text>Target</Text>
                        <Text>{data.target}%</Text>
                      </div>
                      <ProgressBar
                        value={(data.value / data.target) * 100}
                        color="blue"
                      />
                    </div>
                  </Card>
                ))}
              </div>

              {/* Predictions */}
              <Card>
                <div className="p-4">
                  <Title>AI-Powered Predictions</Title>
                  <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    {performanceData.kpis.predictions.map((prediction) => (
                      <Card key={prediction.metric}>
                        <div className="p-4">
                          <div className="flex justify-between items-start">
                            <div>
                              <Text>{prediction.metric}</Text>
                              <div className="mt-2 flex items-baseline space-x-2">
                                <Metric>
                                  {typeof prediction.value === 'number' && prediction.value > 1000 
                                    ? `$${(prediction.value / 1000).toFixed(1)}K`
                                    : `${prediction.value}%`}
                                </Metric>
                                <Badge color={prediction.trend > 0 ? 'green' : 'red'}>
                                  {prediction.trend > 0 ? '+' : ''}{prediction.trend}%
                                </Badge>
                              </div>
                            </div>
                            <Badge color="blue">
                              {prediction.confidence}% confidence
                            </Badge>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}