// src/components/smartFinder/core/SmartFinderDialog.tsx

import { useState, useEffect, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  TableCellsIcon,
  Squares2X2Icon,
  ShoppingBagIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { Badge, Text } from '@tremor/react';
import { NavigationTree } from './NavigationTree';
import { SearchToolbar } from './SearchToolbar';
import { UniversalFilterBar } from './UniversalFilterBar';
import { BasketPanel } from '../basket/BasketPanel';
import { SelectionSummary } from '../shared/SelectionSummary';
import { MetricsPanel } from '../shared/MetricsPanel';
import { BreadcrumbNav } from './BreadcrumbNav';
import { PreviewPane } from '../shared/PreviewPane'

// Entity-specific views
import { DealListView } from '../entityViews/deals/DealListView';
import { AudienceListView } from '../entityViews/audiences/AudienceListView';
import { DomainListView } from '../entityViews/domains/DomainListView';

// Types
import { EntityType, ViewMode } from '../../../types/smartFinder';
import { EntityMetrics, UniversalFilters, PreviewData } from '../../../types/smartFinder';

import { BasketProvider } from '../basket/BasketContext';
import { BasketItem } from '../../../types/smartFinder';
import { Selection } from '../../../types/selections';


  
interface FilteredItem {
    id: string;
    name: string;
    reach?: number;
    viewability?: number;
    performance?: {
      viewability: number;
      completion: number;
      ctr: number;
    };
    budget?: {
      total: number;
    };
  }
  

interface BasketPanelProps {
    selectedItems: BasketItem[];  // Change type from string[] to BasketItem[]
    entityType: EntityType;
    onClose: () => void;
    onRemove: (id: string) => void;
  }

export type AnalysisTab = 'overview' | 'breakdown' | 'comparison' | 'trends';

export interface SmartFinderProps {
  isOpen: boolean;
  onClose: () => void;
  entityType: EntityType;
  onSelect: (selections: Selection[]) => void;
  initialSelections?: string[];
  mode?: 'single' | 'multiple';
  initialFilters?: UniversalFilters;
  onFilterChange?: (filters: UniversalFilters) => void;
}

export function SmartFinderDialog({
  isOpen,
  onClose,
  entityType,
  onSelect,
  initialSelections = [],
  mode = 'multiple',
  initialFilters,
  onFilterChange
}: SmartFinderProps) {
  // State management
  const [viewMode, setViewMode] = useState<ViewMode>('list');
  const [selectedIds, setSelectedIds] = useState<string[]>(initialSelections);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<AnalysisTab>('overview');
  const [showBasket, setShowBasket] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(true);
  const [previewData, setPreviewData] = useState<{type: string; data: any} | null>(null);
  const [universalFilters, setUniversalFilters] = useState<UniversalFilters>(
    initialFilters || {
      accountManager: [],
      vertical: [],
      agency: {},
      region: [],
      opportunityId: undefined,
      status: ['active']
    }
  );
  const [aggregatedMetrics, setAggregatedMetrics] = useState<EntityMetrics | null>(null);
//   const [basketItems, setBasketItems] = useState<BasketItem[]>([]);
const [showFilters, setShowFilters] = useState(false);
//   const handleAddToBasket = (item: BasketItem) => {
//     setBasketItems(prev => [...prev, item]);
//   };
const [showMetrics, setShowMetrics] = useState(false);
const [selectedMetrics, setSelectedMetrics] = useState<any>(null);
const [filteredItems, setFilteredItems] = useState<FilteredItem[]>([]);

const calculateAggregatedMetrics = (items: any[]): EntityMetrics | null => {
    if (items.length === 0) return null;
  
    return {
      reach: items.reduce((sum, item) => sum + (item.reach || 0), 0),
      performance: {
        viewability: items.reduce((sum, item) => sum + (item.performance?.viewability || 0), 0) / items.length,
        completion: items.reduce((sum, item) => sum + (item.performance?.completion || 0), 0) / items.length,
        ctr: items.reduce((sum, item) => sum + (item.performance?.ctr || 0), 0) / items.length,
      }
    };
  };

  const transformToBasketItems = (ids: string[]): BasketItem[] => {
    return ids.map(id => {
      const item = filteredItems.find((i: FilteredItem) => i.id === id);
      return {
        id,
        type: entityType,
        name: item?.name || id,
        metrics: {
          reach: item?.reach || 0,
          viewability: item?.performance?.viewability || 0,
          completion: item?.performance?.completion || 0,
          ctr: item?.performance?.ctr || 0,
          budget: item?.budget?.total || 0
        }
      };
    });
  };
  

  const handleSelect = () => {
    const selections: Selection[] = selectedIds.map(id => {
      // Find the corresponding item from your data source
      const item = filteredItems.find(item => item.id === id);
      
      return {
        id,
        type: entityType,
        data: {
          name: item?.name || id,
          metrics: {
            reach: item?.reach || 0,
            viewability: item?.viewability || 0,
            completion: item?.performance?.completion || 0,
            ctr: item?.performance?.ctr || 0,
            budget: item?.budget?.total || 0,
            performance: {
              viewability: item?.performance?.viewability || 0,
              completion: item?.performance?.completion || 0,
              ctr: item?.performance?.ctr || 0
            }
          }
        }
      };
    });
  
    onSelect(selections);
    onClose();
  };
  
const handleMetricsView = (data: PreviewData) => {
  setShowMetrics(true);
  setSelectedMetrics({
    primary: data.metrics.primary,
    performance: data.metrics.performance,
    distribution: data.metrics.distribution,
    trends: data.metrics.trends
  });
};
  
  // Reset state when dialog closes
  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
      setCurrentTab('overview');
    }
  }, [isOpen]);

  
  // Handle selection changes
  const handleSelectionChange = useCallback((id: string) => {
    setSelectedIds(prev => {
      if (mode === 'single') {
        return [id];
      }
      return prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id];
    });
  }, [mode]);

  const handlePreview = (type: string, data: any) => {
    setPreviewData({ type, data });
  };
  
  // Handle universal filter changes
  const handleFilterChange = (newFilters: UniversalFilters) => {
    setUniversalFilters(newFilters);
    onFilterChange?.(newFilters);
  };

  // Calculate main content width classes based on panels
  const getMainContentClasses = () => {
    let classes = 'flex-1 flex flex-col overflow-hidden';
    if (showBasket && showAnalysis) {
      classes += ' lg:w-1/2'; // Narrower when both panels are open
    } else if (showBasket || showAnalysis) {
      classes += ' lg:w-2/3'; // Wider when one panel is open
    }
    return classes;
  };

  return (
    <BasketProvider>
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-50"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="flex w-full transform flex-col justify-between bg-white h-screen max-w-8xl">
                {/* Header */}
                <div className="px-4 py-3 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <div>
                      <Dialog.Title className="text-lg font-semibold text-gray-900">
                        {entityType.charAt(0).toUpperCase() + entityType.slice(1)}
                      </Dialog.Title>
                      <div>
                           <BreadcrumbNav
                             items={currentPath.map(p => ({ id: p, label: p }))}
                             onNavigate={item => setCurrentPath(prev => [...prev, item.id])}
                             entityType={entityType}
                           />
                         </div>
                      <Text className="text-gray-500">
                        {selectedIds.length} items selected
                      </Text>
                    </div>
                    <div className="flex items-center space-x-4">

                    <Button
          variant="secondary"
          onClick={() => setShowFilters(!showFilters)}
        >
          <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2" />
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>

                      {/* View Toggle */}
                      <div className="flex rounded-lg shadow-sm">
                        <button
                          className={`px-3 py-2 text-sm font-medium rounded-l-lg ${
                            viewMode === 'list'
                              ? 'bg-blue-50 text-blue-600'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                          onClick={() => setViewMode('list')}
                        >
                          <TableCellsIcon className="h-5 w-5" />
                        </button>
                        <button
                          className={`px-3 py-2 text-sm font-medium rounded-r-lg ${
                            viewMode === 'grid'
                              ? 'bg-blue-50 text-blue-600'
                              : 'text-gray-500 hover:text-gray-700'
                          }`}
                          onClick={() => setViewMode('grid')}
                        >
                          <Squares2X2Icon className="h-5 w-5" />
                        </button>
                      </div>

                      {/* Basket Toggle */}
                      <Button
                        variant={showBasket ? 'primary' : 'secondary'}
                        onClick={() => setShowBasket(!showBasket)}
                      >
                        <ShoppingBagIcon className="h-5 w-5 mr-2" />
                        Basket
                        {selectedIds.length > 0 && (
                          <Badge color="white" className="ml-2">
                            {selectedIds.length}
                          </Badge>
                        )}
                      </Button>

                      <Button variant="ghost" onClick={onClose}>
                        <XMarkIcon className="h-5 w-5" />
                      </Button>
                    </div>
                  </div>

                  {/* Universal Filters */}
                  <div className="mt-4">
                  {showFilters && (
                    <UniversalFilterBar
                      filters={universalFilters}
                      onFilterChange={handleFilterChange}
                    />
                )}

                  </div>

                  {/* Search */}
                  <div className="mt-4">
                    <SearchToolbar
                      entityType={entityType}
                      value={searchQuery}
                      onChange={setSearchQuery}
                      onPath={setCurrentPath}
                    />
                  </div>
                </div>

                {/* Main Content */}
                <div className="flex flex-1 overflow-hidden">
                  {/* Left Sidebar - Navigation */}
                  <div className="w-64 border-r border-gray-200 overflow-y-auto">
                    <NavigationTree
                      entityType={entityType}
                      currentPath={currentPath}
                      onNavigate={setCurrentPath}
                      selectedIds={selectedIds}
                      onSelectionChange={handleSelectionChange}
                    />
                  </div>

                  {/* Main Content Area */}
                  <div className={getMainContentClasses()}>
                    {/* Results List/Grid */}
                    <div className="flex-1 overflow-y-auto">
                    {entityType === 'deals' && (
                        <DealListView
                            selectedIds={selectedIds}
                            onSelectionChange={handleSelectionChange}
                            viewMode={viewMode}
                            universalFilters={universalFilters}
                            onPreview={(data) => handlePreview('deal', data)}  // Add this
                        />
                        )}
                        {entityType === 'audiences' && (
                        <AudienceListView
                            selectedIds={selectedIds}
                            onSelectionChange={handleSelectionChange}
                            viewMode={viewMode}
                            universalFilters={universalFilters}
                            onPreview={(data) => handlePreview('audience', data)}  // Add this
                        />
                        )}
                        {entityType === 'domains' && (
                        <DomainListView
                            selectedIds={selectedIds}
                            onSelectionChange={handleSelectionChange}
                            viewMode={viewMode}
                            universalFilters={universalFilters}
                            onPreview={(data) => handlePreview('domain', data)}  // Add this
                        />
                        )}

                    </div>

                    {/* Selection Summary */}
                    {selectedIds.length > 0 && (
                      <div className="border-t border-gray-200 p-4">
                        <SelectionSummary
                          selectedIds={selectedIds}
                          onClear={() => setSelectedIds([])}
                          onRemove={(id) => setSelectedIds(prev => prev.filter(i => i !== id))}
                          metrics={aggregatedMetrics}
                        />
                      </div>
                    )}
                  </div>

                  {/* Right Sidebars */}
                  {previewData && (
                           <div className="w-96 border-l border-gray-200">
                             <PreviewPane
                               data={previewData.data}
                               onClose={() => setPreviewData(null)}
                               onViewDetail={() => {}}
                               onAnalyze={() => {}}
                               onMetricsView={() => handleMetricsView(previewData.data)}
                             />
                           </div>
                         )}
                         

                         {showBasket && (
  <div className="w-80 border-l border-gray-200 overflow-y-auto">
    <BasketPanel
      selectedItems={transformToBasketItems(selectedIds)}
      entityType={entityType}
      onClose={() => setShowBasket(false)}
      onRemove={(id) => setSelectedIds(prev => prev.filter(i => i !== id))}
    />
  </div>
)}
                </div>

                {/* Footer */}
                <div className="border-t border-gray-200 px-4 py-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-4">
                      <Badge color="gray">
                        {mode === 'single' ? 'Single Select' : 'Multi Select'}
                      </Badge>
                      {selectedIds.length > 0 && (
                        <Text>{selectedIds.length} items selected</Text>
                      )}
                    </div>
                    <div className="flex space-x-4">
                      <Button variant="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleSelect}
                        disabled={selectedIds.length === 0}
                    >
                        {mode === 'single' ? 'Select Item' : `Select ${selectedIds.length} Items`}
                    </Button>
                    </div>
                  </div>


                      {/* Metrics Dialog */}
                    {showMetrics && (
                    <Dialog 
                        open={showMetrics} 
                        onClose={() => setShowMetrics(false)}
                        className="relative z-50"
                    >
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                        <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="mx-auto max-w-3xl w-full bg-white rounded-xl shadow-xl">
                            <MetricsPanel
                            metrics={selectedMetrics}
                            type={entityType}
                            onClose={() => setShowMetrics(false)}
                            />
                        </Dialog.Panel>
                        </div>
                    </Dialog>
                    )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </BasketProvider>
  );
}