// src/components/smartFinder/entityViews/audiences/AudienceListView.tsx

import { useState, useMemo, useCallback } from 'react';
import {
 Table,
 TableHead,
 TableRow,
 TableHeaderCell,
 TableBody,
 TableCell,
 Badge,
 Text,
 ProgressBar,
 Card
} from '@tremor/react';
import {
 ChevronUpDownIcon,
 EyeIcon,
 ChartBarIcon,
 ShoppingBagIcon,
 UsersIcon,
 CheckCircleIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';
import { PreviewPane } from '../../shared/PreviewPane';
import { FilterPopover } from '../../filters/FilterPopover';
import { ActiveFilters } from '../../filters/ActiveFilters';
import { ColumnCustomizer } from '../../filters/ColumnCustomizer';
import { ViewMode, UniversalFilters, Audience, PreviewData, Deal } from '../../../../types/smartFinder';
import { enhancedMockAudiences } from '../../../../data/mockData/combinedMockData';
import { useBasket } from '../../basket/BasketContext';
import { EntityType } from '../../../../types/smartFinder';
const audienceColumns = [
 {
   id: 'name',
   label: 'Audience Name',
   type: 'text' as const,
   sortable: true,
   filterable: true
 },
 {
   id: 'type',
   label: 'Type',
   type: 'select' as const,
   sortable: true,
   filterable: true,
   options: [
     { value: 'first_party', label: 'First Party' },
     { value: 'third_party', label: 'Third Party' },
     { value: 'lookalike', label: 'Lookalike' }
   ]
 },
 {
   id: 'size',
   label: 'Size',
   type: 'number' as const,
   sortable: true,
   filterable: true
 },
 {
   id: 'coverage',
   label: 'Coverage',
   type: 'number' as const,
   sortable: true,
   filterable: true
 },
 {
   id: 'match_rate',
   label: 'Match Rate',
   type: 'number' as const,
   sortable: true,
   filterable: true
 },
 {
   id: 'performance',
   label: 'Performance',
   type: 'number' as const,
   sortable: true,
   filterable: true
 }
];

interface AudienceColumn {
    id: string;
    label: string;
    type: 'text' | 'select' | 'number';
    sortable: boolean;
    filterable: boolean;
    options?: Array<{ value: string; label: string }>;
  }

  
interface AudienceListViewProps {
 selectedIds: string[];
 onSelectionChange: (id: string) => void;
 onViewDetail?: (id: string) => void;
 onAnalyze?: (id: string) => void;
 viewMode: ViewMode;
 universalFilters: UniversalFilters;
 onPreview: (data: PreviewData) => void;
}

export function AudienceListView({
 selectedIds,
 onSelectionChange,
 onViewDetail,
 onAnalyze,
 viewMode,
 universalFilters,
 onPreview
}: AudienceListViewProps) {
 const [sortField, setSortField] = useState<string>('name');
 const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
 const [previewAudience, setPreviewAudience] = useState<string | null>(null);
 const [hoveredAudience, setHoveredAudience] = useState<string | null>(null);
 const [columnFilters, setColumnFilters] = useState<Array<{
   field: string;
   operator: string;
   value: any;
 }>>([]);
 const [visibleColumns, setVisibleColumns] = useState<Set<string>>(
   new Set(audienceColumns.map(col => col.id))
 );
 const { addItem, removeItem, isInBasket } = useBasket();


const getValue = (obj: any, field: string) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      return obj[parent]?.[child];
    }
    return obj[field];
  };

 const filteredAndSortedAudiences = useMemo(() => {
   let results = [...enhancedMockAudiences];

   if (universalFilters.vertical.length) {
     results = results.filter(audience => 
       universalFilters.vertical.some(vertical => 
         (audience.composition.interests as unknown as Record<string, number>)[vertical] > 50
       )
     );
   }

   const handlePreviewClick = (audience: Audience) => {
    onPreview({
      type: 'audiences' as EntityType,
      id: audience.id,
      name: audience.name,
      metrics: {
        primary: [
          {
            name: 'Total Size',
            value: audience.size,
            change: audience.recentActivity,
            icon: UsersIcon
          },
          {
            name: 'Match Rate',
            value: audience.metrics.match_rate,
            benchmark: 85,
            icon: CheckCircleIcon
          }
        ],
        performance: {
          viewability: audience.metrics.performance.viewability,
          ctr: audience.metrics.performance.ctr,
          completion: audience.metrics.performance.conversion
        },
        distribution: Object.entries(audience.composition.demographics).map(([name, value]) => ({
          name,
          value
        }))
      },
      details: {
        type: audience.type,
        coverage: audience.coverage,
        matchRate: audience.metrics.match_rate,
        demographics: audience.composition.demographics,
        interests: audience.composition.interests,
        path: audience.path
      }
    });
   };

   columnFilters.forEach(filter => {
     results = results.filter(audience => {
       const value = getValue(audience, filter.field);
       
       switch (filter.operator) {
         case 'equals':
           return value === filter.value;
         case 'contains':
           return String(value).toLowerCase().includes(String(filter.value).toLowerCase());
         case 'greater':
           return Number(value) > Number(filter.value);
         case 'less':
           return Number(value) < Number(filter.value);
         case 'between':
           const [min, max] = filter.value;
           return Number(value) >= min && Number(value) <= max;
         default:
           return true;
       }
     });
   });

   return results.sort((a, b) => {
     const direction = sortDirection === 'asc' ? 1 : -1;
     const aValue = getValue(a, sortField);
     const bValue = getValue(b, sortField);
     return direction * String(aValue).localeCompare(String(bValue));
   });
 }, [enhancedMockAudiences, universalFilters, columnFilters, sortField, sortDirection]);

 const handleSort = useCallback((field: string) => {
   if (sortField === field) {
     setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
   } else {
     setSortField(field);
     setSortDirection('asc');
   }
 }, [sortField]);

 const handleAddFilter = useCallback((field: string, filter: any) => {
   setColumnFilters(prev => [...prev, { ...filter, field }]);
 }, []);

 const handleRemoveFilter = useCallback((index: number) => {
   setColumnFilters(prev => prev.filter((_, i) => i !== index));
 }, []);

 const handleClearFilters = useCallback(() => {
   setColumnFilters([]);
 }, []);

 const handleAddToBasket = (audience: any) => {
    addItem({
      id: audience.id,
      type: 'audiences' as EntityType,
      name: audience.name,
      metrics: {
        reach: audience.size,
        viewability: audience.metrics.performance.viewability,
        completion: audience.metrics.performance.conversion
      }
    });
  };
  


 if (viewMode === 'grid') {
   return (
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
       {filteredAndSortedAudiences.map(audience => (
         <Card key={audience.id} className="p-4">
           <div className="flex justify-between items-start">
             <div>
               <Text className="font-medium">{audience.name}</Text>
               <Badge color="blue" size="sm">
                 {audience.type}
               </Badge>
             </div>
             <input
               type="checkbox"
               className="rounded border-gray-300"
               checked={selectedIds.includes(audience.id)}
               onChange={() => onSelectionChange(audience.id)}
             />
           </div>
           <div className="mt-4 space-y-2">
             <div className="flex justify-between text-sm">
               <Text>Size</Text>
               <Text>{audience.size.toLocaleString()}</Text>
             </div>
             <div className="flex justify-between text-sm">
               <Text>Match Rate</Text>
               <div className="flex items-center">
                 <Text>{audience.metrics.match_rate}%</Text>
                 <ProgressBar
                   value={audience.metrics.match_rate}
                   className="w-20 ml-2"
                   color="blue"
                 />
               </div>
             </div>
           </div>
         </Card>
       ))}
     </div>
   );
 }

 return (
   <div className="flex flex-col h-full">
     <ActiveFilters
       filters={columnFilters}
       onRemove={handleRemoveFilter}
       onClear={handleClearFilters}
     />

     <div className="flex-1 overflow-auto">
       <Table>
         <TableHead>
           <TableRow>
             <TableHeaderCell className="w-12">
               <input
                 type="checkbox"
                 className="rounded border-gray-300"
                 checked={selectedIds.length === filteredAndSortedAudiences.length}
                 onChange={() => {
                   const allIds = filteredAndSortedAudiences.map(a => a.id);
                   allIds.forEach(id => onSelectionChange(id));
                 }}
               />
             </TableHeaderCell>

             {audienceColumns.map(column => (
               visibleColumns.has(column.id) && (
                 <TableHeaderCell key={column.id}>
                   <div className="flex items-center justify-between">
                     <button
                       className="flex items-center space-x-1"
                       onClick={() => column.sortable && handleSort(column.id)}
                     >
                       <span>{column.label}</span>
                       {column.sortable && (
                         <ChevronUpDownIcon className="h-4 w-4 text-gray-400" />
                       )}
                     </button>
                     {column.filterable && (
                       <FilterPopover
                         field={column.id}
                         type={column.type}
                         options={column.options}
                         onFilter={handleAddFilter}
                         currentFilter={columnFilters.find(f => f.field === column.id)}
                         onRemove={() => handleRemoveFilter(
                           columnFilters.findIndex(f => f.field === column.id)
                         )}
                       />
                     )}
                   </div>
                 </TableHeaderCell>
               )
             ))}

             <TableHeaderCell>
               <div className="flex justify-end">
                 <ColumnCustomizer
                   columns={audienceColumns}
                   visibleColumns={visibleColumns}
                   onChange={setVisibleColumns}
                   entityType="audiences"
                 />
               </div>
             </TableHeaderCell>
           </TableRow>
         </TableHead>

         <TableBody>
           {filteredAndSortedAudiences.map(audience => (
             <TableRow
               key={audience.id}
               className={`
                 ${selectedIds.includes(audience.id) ? 'bg-blue-50' : ''}
                 ${hoveredAudience === audience.id ? 'bg-gray-50' : ''}
               `}
               onMouseEnter={() => setHoveredAudience(audience.id)}
               onMouseLeave={() => setHoveredAudience(null)}
             >
               <TableCell>
                 <input
                   type="checkbox"
                   className="rounded border-gray-300"
                   checked={selectedIds.includes(audience.id)}
                   onChange={() => onSelectionChange(audience.id)}
                 />
               </TableCell>

               {audienceColumns.map(column => (
                 visibleColumns.has(column.id) && (
                   <TableCell key={column.id}>
                     {renderAudienceCellContent(audience, column)}
                   </TableCell>
                 )
               ))}

               <TableCell>
                 <div className="flex justify-end space-x-2">
                      <Button
                       variant={isInBasket(audience.id) ? 'primary' : 'secondary'}
                       size="sm"
                       onClick={() => handleAddToBasket(audience)}
                     >
                       <ShoppingBagIcon className="h-4 w-4" />
                     </Button>
                     <Button
onClick={() => onPreview({
    type: 'audiences',
    id: audience.id,
    name: audience.name,
    metrics: {
      primary: [
        {
          name: 'Size',
          value: audience.size,
          change: 0
        },
        {
          name: 'Match Rate',
          value: audience.metrics.match_rate,
          benchmark: 85
        }
      ],
      performance: {
        viewability: audience.metrics.performance.viewability,
        ctr: audience.metrics.performance.ctr
      },
      // Transform composition data into a distribution array
      distribution: [
        ...Object.entries(audience.composition.demographics).map(([name, value]) => ({
          name: `Demographics: ${name}`,
          value: Number(value)
        })),
        ...Object.entries(audience.composition.interests).map(([name, value]) => ({
          name: `Interests: ${name}`,
          value: Number(value)
        }))
      ]
    },
    details: {
      type: audience.type,
      coverage: audience.coverage,
      matchRate: audience.metrics.match_rate,
      // Format composition data as strings for details
      demographics: Object.entries(audience.composition.demographics)
        .map(([key, value]) => `${key}: ${value}%`)
        .join(', '),
      interests: Object.entries(audience.composition.interests)
        .map(([key, value]) => `${key}: ${value}%`)
        .join(', ')
    }
  })}
>
  <EyeIcon className="h-4 w-4" />
</Button>
                   <Button
                     variant="ghost"
                     size="sm"
                     onClick={() => onAnalyze?.(audience.id)}
                   >
                     <ChartBarIcon className="h-4 w-4" />
                   </Button>
                 </div>
               </TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </div>


   </div>
 );
}

function renderAudienceCellContent(audience: any, column: AudienceColumn) {
    switch (column.id) {
      case 'name':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Text className="font-medium">{audience.name}</Text>
              {audience.similarityScore && (
                <Badge color="blue" size="sm">
                  {audience.similarityScore}% Match
                </Badge>
              )}
            </div>
            <div className="flex flex-wrap gap-1">
              {Object.entries(audience.composition.interests)
                .sort(([,a], [,b]) => (b as number) - (a as number))
                .slice(0, 2)
                .map(([interest, score]) => (
                  <Badge key={interest} color="gray" size="sm">
                    {`${interest} (${score}%)`}
                  </Badge>
                ))}
            </div>
          </div>
        );
  
      case 'type':
        return (
          <Badge color={
            audience.type === 'first_party' ? 'blue' :
            audience.type === 'third_party' ? 'purple' :
            'emerald'
          }>
            {audience.type.replace('_', ' ')}
          </Badge>
        );
  
      case 'size':
        return (
          <div className="space-y-1">
            <Text className="font-medium">
              {audience.size.toLocaleString()}
            </Text>
            {audience?.recentActivity && audience.recentActivity > 0 && (
              <Badge color="emerald" size="sm">
                +{audience.recentActivity}%
              </Badge>
            )}
          </div>
        );
  
      case 'performance':
        return (
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>CTR</span>
              <span>{audience.metrics.performance.ctr.toFixed(2)}%</span>
            </div>
            <ProgressBar
              value={audience.metrics.performance.viewability}
              color="blue"
            />
          </div>
        );
  
      default:
        if (column.id === 'match_rate') {
          return <Text>{audience.metrics.match_rate}%</Text>;
        }
        return <Text>{String(audience[column.id] || '')}</Text>;
    }
  }