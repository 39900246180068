// src/data/mockData/combinedMockData.ts

// import { Deal, AudienceSegment, Domain } from '../../types';


export const enhancedMockDeals = [
  {
    id: 'deal-1',
    name: 'Premium Auto Campaign',
    advertiser: 'AutoMax Inc',
    type: 'PMP',
    status: 'active',
    health: {
      score: 85,
      status: 'healthy',
      lastChecked: '2024-01-20T10:00:00Z',
      issues: []
    },
    performance: {
      bidResponses: 92,
      winRate: 45,
      viewability: 82,
      completion: 75,
      ctr: 0.12,
      targetCpm: 12.50,
      actualCpm: 11.80,
      targetSpend: 500000,
      actualSpend: 285000,
      pacing: {
        daily: 95,
        overall: 92,
        trend: [88, 90, 92, 95, 93, 95, 92]
      }
    },
    budget: {
      total: 500000,
      spent: 285000,
      remaining: 215000,
      pacing: 'on_track'
    },
    setup: {
      audiences: ['Auto Intenders', 'High Income'],
      domains: ['premium-news.com', 'auto-enthusiast.com'],
      formats: ['Display', 'Native'],
      targeting: {
        geo: ['US'],
        devices: ['Desktop', 'Mobile']
      }
    }
  },
  {
    id: 'deal-2',
    name: 'Retail Performance Bundle',
    advertiser: 'MegaMart',
    type: 'Preferred',
    status: 'warning',
    health: {
      score: 65,
      status: 'warning',
      lastChecked: '2024-01-20T09:00:00Z',
      issues: [
        {
          type: 'pacing',
          severity: 'medium',
          message: 'Behind daily pacing target'
        }
      ]
    },
    performance: {
      bidResponses: 78,
      winRate: 32,
      viewability: 78,
      completion: 72,
      ctr: 0.15,
      targetCpm: 8.00,
      actualCpm: 7.50,
      targetSpend: 350000,
      actualSpend: 175000,
      pacing: {
        daily: 82,
        overall: 85,
        trend: [80, 82, 81, 82, 83, 82, 85]
      }
    },
    budget: {
      total: 350000,
      spent: 175000,
      remaining: 175000,
      pacing: 'behind'
    },
    setup: {
      audiences: ['Shopping Intenders', 'Value Seekers'],
      domains: ['retail-news.com', 'shopping-guide.com'],
      formats: ['Display', 'Video'],
      targeting: {
        geo: ['US', 'CA'],
        devices: ['All']
      }
    }
  },
  {
    id: 'deal-3',
    name: 'Finance Professional Network',
    advertiser: 'Global Banking Corp',
    type: 'Programmatic Guaranteed',
    status: 'active',
    health: {
      score: 92,
      status: 'healthy',
      lastChecked: '2024-01-20T11:00:00Z',
      issues: []
    },
    performance: {
      bidResponses: 95,
      winRate: 52,
      viewability: 85,
      completion: 80,
      ctr: 0.18,
      targetCpm: 25.00,
      actualCpm: 24.50,
      targetSpend: 250000,
      actualSpend: 150000,
      pacing: {
        daily: 98,
        overall: 95,
        trend: [92, 94, 95, 98, 97, 98, 95]
      }
    },
    budget: {
      total: 250000,
      spent: 150000,
      remaining: 100000,
      pacing: 'ahead'
    },
    setup: {
      audiences: ['Finance Professionals', 'High Net Worth'],
      domains: ['finance-news.com', 'investment-daily.com'],
      formats: ['Display'],
      targeting: {
        geo: ['US', 'UK', 'HK'],
        devices: ['Desktop']
      }
    }
  },
  {
    id: 'deal-4',
    name: 'Tech Enthusiast Campaign',
    advertiser: 'TechGiant',
    type: 'PMP',
    status: 'critical',
    health: {
      score: 45,
      status: 'critical',
      lastChecked: '2024-01-20T08:00:00Z',
      issues: [
        {
          type: 'viewability',
          severity: 'high',
          message: 'Viewability below threshold'
        },
        {
          type: 'spend',
          severity: 'high',
          message: 'Significant underspend'
        }
      ]
    },
    performance: {
      bidResponses: 65,
      winRate: 25,
      viewability: 65,
      completion: 60,
      ctr: 0.08,
      targetCpm: 15.00,
      actualCpm: 12.50,
      targetSpend: 400000,
      actualSpend: 120000,
      pacing: {
        daily: 65,
        overall: 60,
        trend: [75, 70, 68, 65, 62, 60, 65]
      }
    },
    budget: {
      total: 400000,
      spent: 120000,
      remaining: 280000,
      pacing: 'behind'
    },
    setup: {
      audiences: ['Tech Enthusiasts', 'Early Adopters'],
      domains: ['tech-news.com', 'gadget-review.com'],
      formats: ['Display', 'Video', 'Native'],
      targeting: {
        geo: ['US', 'UK', 'DE', 'JP'],
        devices: ['Desktop', 'Mobile', 'Tablet']
      }
    }
  },
  {
    id: 'deal-5',
    name: 'Luxury Travel Package',
    advertiser: 'Premium Travel Co',
    type: 'Programmatic Guaranteed',
    status: 'active',
    health: {
      score: 88,
      status: 'healthy',
      lastChecked: '2024-01-20T12:00:00Z',
      issues: []
    },
    performance: {
      bidResponses: 88,
      winRate: 48,
      viewability: 90,
      completion: 85,
      ctr: 0.22,
      targetCpm: 35.00,
      actualCpm: 34.80,
      targetSpend: 600000,
      actualSpend: 300000,
      pacing: {
        daily: 92,
        overall: 90,
        trend: [88, 89, 90, 92, 91, 92, 90]
      }
    },
    budget: {
      total: 600000,
      spent: 300000,
      remaining: 300000,
      pacing: 'on_track'
    },
    setup: {
      audiences: ['Luxury Travelers', 'High Income', 'Frequent Flyers'],
      domains: ['travel-luxury.com', 'premium-destinations.com'],
      formats: ['Video', 'Native'],
      targeting: {
        geo: ['US', 'UK', 'EU', 'AE'],
        devices: ['Desktop', 'Tablet']
      }
    }
  }
 ];

export const enhancedMockAudiences = [
  {
    id: 'aud-1',
    name: 'Auto Intenders',
    type: 'first_party',
    size: 2500000,
    coverage: 85,
    metrics: {
      match_rate: 88,
      performance: {
        viewability: 82,
        ctr: 0.15,
        conversion: 2.8
      }
    },
    composition: {
      demographics: {
        '25-34': 30,
        '35-44': 40,
        '45-54': 30
      },
      interests: {
        'Automotive': 100,
        'Technology': 75,
        'Travel': 60
      }
    },
    path: ['Demographics', 'Age Groups']
  },
  {
    id: 'aud-2',
    name: 'High Income Shoppers',
    type: 'third_party',
    size: 1800000,
    coverage: 78,
    metrics: {
      match_rate: 82,
      performance: {
        viewability: 80,
        ctr: 0.12,
        conversion: 3.2
      }
    },
    composition: {
      demographics: {
        '35-44': 35,
        '45-54': 45,
        '55+': 20
      },
      interests: {
        'Luxury': 90,
        'Travel': 80,
        'Finance': 70
      }
    },
    path: ['Interests', 'Shopping']
  },
  {
    id: 'aud-3',
    name: 'Tech Enthusiasts',
    type: 'lookalike',
    size: 3200000,
    coverage: 92,
    metrics: {
      match_rate: 85,
      performance: {
        viewability: 85,
        ctr: 0.18,
        conversion: 2.5
      }
    },
    composition: {
      demographics: {
        '18-24': 25,
        '25-34': 45,
        '35-44': 30
      },
      interests: {
        'Technology': 100,
        'Gaming': 85,
        'Entertainment': 75
      }
    },
    path: ['Interests', 'Technology']
  }
];

export const enhancedMockDomains = [
  {
    id: 'dom-1',
    url: 'premium-news.com',
    publisher: 'Premium News Network',
    categories: ['News', 'Business'],
    quality: {
      score: 92,
      brand_safety: 95,
      viewability: 88,
      completion: 82
    },
    reach: {
      monthly: 25000000,
      trend: {
        direction: 'up',
        value: 12
      }
    },
    path: ['Categories', 'News', 'Top Tier']
  },
  {
    id: 'dom-2',
    url: 'sports-central.com',
    publisher: 'Sports Media Group',
    categories: ['Sports', 'Entertainment'],
    quality: {
      score: 88,
      brand_safety: 90,
      viewability: 85,
      completion: 78
    },
    reach: {
      monthly: 18000000,
      trend: {
        direction: 'up',
        value: 8
      }
    },
    path: ['Categories', 'Sports', 'Premium']
  },
  {
    id: 'dom-3',
    url: 'tech-daily.com',
    publisher: 'Tech Media Corp',
    categories: ['Technology', 'Business'],
    quality: {
      score: 85,
      brand_safety: 92,
      viewability: 82,
      completion: 75
    },
    reach: {
      monthly: 12000000,
      trend: {
        direction: 'stable',
        value: 0
      }
    },
    path: ['Categories', 'Technology', 'News']
  }
];