// src/components/deals/management/DealManagementTiles.tsx

import { useState } from 'react';
import {
  Card,
  Title,
  Text,
  Badge,
  Metric
} from '@tremor/react';
import { Dialog } from '@headlessui/react';
import {
  ChartBarIcon,
  BuildingOfficeIcon,
  UsersIcon,
  ShieldCheckIcon,
  ClipboardDocumentCheckIcon,
  WrenchScrewdriverIcon,
  SparklesIcon,
  ArrowTopRightOnSquareIcon,
  FunnelIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';
import { HealthChecks } from './alerts/HealthChecks';
// import { QBRView } from './views/QBRView';
import { HierarchyFilter } from './filters/HierarchyFilter';
import { useRoleBasedAccess } from '../../../hooks/useRoleBasedAccess';
import { useAlertMonitoring } from '../../../hooks/useAlertMonitoring';
import { DealHealthMetrics } from './metrics/DealHealthMetrics';
import { PerformanceMetrics } from './metrics/PerformanceMetrics';
import { OperationalMetrics } from './metrics/OperationalMetrics';
import { AlertSystem } from './alerts/AlertSystem';
import { MonitoringAlerts } from './alerts/MonitoringAlerts';
import { BusinessView } from './views/BusinessView';
import { AccountView } from './views/AccountView';
import { BrandView } from './views/BrandView';
import { RoleBasedTiles } from './tiles/RoleBasedTiles';
import { RoleBasedContainer as RoleBasedViews } from './roles/RoleBasedViews';
import { DealOpsView } from './views/DealOpsView';
import { AccountManagerView } from './views/AccountManagerView';
import { SupportView } from './views/SupportView';
import { RoleView, RoleMetric, MetricValue } from '../../../types/dealManagement';
import { mockDeals } from '../../../data/mockDealsData';
import { EnhancedDeal } from '../../../types/deals';

interface AlertState {
  [key: string]: Array<{
    id: string;
    type: string;
    message: string;
  }>;
}

// interface RoleMetric {
//   label: string;
//   value: string | number;
//   target?: number;
// }

// interface RoleView {
//   id: string;
//   title: string;
//   description: string;
//   icon: React.ComponentType;
//   component: React.ComponentType;
//   metrics: {
//     primary: RoleMetric;
//     secondary: RoleMetric;
//   };
//   alerts: number;
//   gradient: string;
//   aiInsight: string;
// }

// type RoleViews = {
//   [key: string]: RoleView;
// };


const tiles = [
  {
    id: 'business',
    title: 'Business View',
    description: 'Portfolio performance and health metrics',
    icon: ChartBarIcon,
    component: BusinessView,
    metricsComponent: PerformanceMetrics,
    metrics: {
      primary: 'Active Deals: 328',
      secondary: 'Health Score: 88%'
    },
    alerts: 5,
    aiInsight: 'Optimization opportunity identified for automotive vertical',
    gradient: 'from-blue-50 to-indigo-50'
  },
  {
    id: 'account',
    title: 'Opportunity View',
    description: 'Agency and client performance tracking',
    icon: BuildingOfficeIcon,
    component: AccountView,
    metricsComponent: OperationalMetrics,
    metrics: {
      primary: 'Active Accounts: 45',
      secondary: 'QBRs Due: 8'
    },
    gradient: 'from-emerald-50 to-teal-50'
  },
  {
    id: 'brand',
    title: 'Brand View',
    description: 'Brand and opportunity tracking',
    icon: UsersIcon,
    component: BrandView,
    metricsComponent: PerformanceMetrics,
    metrics: {
      primary: 'Active Brands: 62',
      secondary: 'New Opportunities: 12'
    },
    gradient: 'from-purple-50 to-pink-50'
  },
  {
    id: 'health',
    title: 'Health & Monitoring',
    description: 'Real-time monitoring and alerts',
    icon: ShieldCheckIcon,
    component: MonitoringAlerts,
    metricsComponent: DealHealthMetrics,
    metrics: {
      primary: 'Overall Health: 92%',
      secondary: 'Active Alerts: 8'
    },
    gradient: 'from-red-50 to-orange-50'
  },
  // {
  //   id: 'qbr',
  //   title: 'QBR & Reviews',
  //   description: 'Quarterly business reviews',
  //   icon: ClipboardDocumentCheckIcon,
  //   component: QBRView,
  //   metrics: {
  //     primary: 'Upcoming QBRs: 12',
  //     secondary: 'Completed: 85%'
  //   },
  //   gradient: 'from-yellow-50 to-amber-50'
  // },
  {
    id: 'setup',
    title: 'Setup & Configuration',
    description: 'Deal setup and configuration',
    icon: WrenchScrewdriverIcon,
    component: HealthChecks,
    metricsComponent: OperationalMetrics,
    metrics: {
      primary: 'Pending Setup: 5',
      secondary: 'Success Rate: 94%'
    },
    gradient: 'from-cyan-50 to-sky-50'
  }
];

const roleViews: Record<string, RoleView> = {
  dealOps: {
    id: 'deal-ops',
    title: 'Deal Operations',
    description: 'Optimize and manage deal performance',
    icon: ChartBarIcon,
    component: DealOpsView,
    metrics: {
      primary: { label: 'Discard Rate', value: '18%', target: 25 },
      secondary: { label: 'Health Score', value: 84 }
    },
    alerts: 5,
    gradient: 'from-blue-50 to-indigo-50',
    aiInsight: 'High discard rates detected in automotive vertical'
  },
  accountManager: {
    id: 'account-manager',
    title: 'Account Management',
    description: 'Monitor client performance and QBRs',
    icon: UserGroupIcon,
    component: AccountManagerView,
    metrics: {
      primary: { label: 'Active Accounts', value: 45 },
      secondary: { label: 'QBRs Due', value: 8 }
    },
    alerts: 3,
    gradient: 'from-emerald-50 to-teal-50',
    aiInsight: 'Schedule QBR for top 3 accounts'
  },
  support: {
    id: 'support',
    title: 'Technical Support',
    description: 'Monitor system health and integrations',
    icon: ShieldCheckIcon,
    component: SupportView,
    metrics: {
      primary: { label: 'System Health', value: '94%' },
      secondary: { label: 'Active Issues', value: 5 }
    },
    alerts: 2,
    gradient: 'from-purple-50 to-pink-50',
    aiInsight: 'DSP connection issues detected'
  }
};


interface DealManagementTilesProps {
  onViewDetail: (viewId: string) => void;
  onHealthCheck: (dealId: string) => void;
  onOptimize: (dealId: string) => void;
  selectedDealId?: string; // Add this prop
}

export function DealManagementTiles({
  onViewDetail,
  onHealthCheck,
  onOptimize,
  selectedDealId
}: DealManagementTilesProps) {
  const [selectedTile, setSelectedTile] = useState<string | null>(null);
  const [showDetailView, setShowDetailView] = useState(false);
  const { hasPermission, canAccessMetric } = useRoleBasedAccess('current-role');
  const alertMonitoring = useAlertMonitoring();
  const [showFilters, setShowFilters] = useState(false);
  const [showRoleView, setShowRoleView] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const dealContext: EnhancedDeal | null = selectedDealId 
    ? mockDeals.find((d: EnhancedDeal) => d.id === selectedDealId) || null 
    : null;

    const getMetricsForDeal = (metrics: RoleMetric): MetricValue => {
      if (!dealContext || !metrics.key) return { value: metrics.value };
      
      const value = dealContext[metrics.key];
      const trend = dealContext.trends && metrics.key in dealContext.trends 
        ? (dealContext.trends as Record<string, number[]>)[metrics.key]
        : undefined;
    
      return {
        value: typeof value === 'object' ? metrics.value : value || metrics.value,
        trend
      };
    };
  

    // const SelectedViewComponent = selectedRole && roleViews[selectedRole]?.component;

  const handleRoleSelect = (roleId: string) => {
    setSelectedRole(roleId);
    setShowRoleView(true);
  };

  const SelectedViewComponent = selectedRole ? roleViews[selectedRole as keyof typeof roleViews]?.component : null;

  
  // Track alerts locally
  const [alertsByTile, setAlertsByTile] = useState<AlertState>({
    business: [],
    account: [],
    brand: [],
    health: [{ id: 'h1', type: 'warning', message: 'Health check needed' }],
    setup: []
  });

  // Handler for tile clicks
  const handleTileClick = (tileId: string) => {
    setSelectedTile(tileId);
    setShowDetailView(true);
  };

  const handleRoleViewSelect = (viewId: string) => {
    setSelectedTile(viewId);
    setShowRoleView(true);
  };


  
  // Render detail view content
  const renderDetailView = () => {
    if (!selectedTile) return null;

    const tile = tiles.find(t => t.id === selectedTile);
    if (!tile) return null;

    const MetricsComponent = tile.metricsComponent;
    const ViewComponent = tile.component;

    return (
      <div className="space-y-6">
        {/* Hierarchy Filter */}
        {/* <HierarchyFilter /> */}

        {/* Metrics Section */}
        {MetricsComponent && (
          <Card>
            <div className="p-4">
              <MetricsComponent />
            </div>
          </Card>
        )}

        {/* Main View Component */}
        {ViewComponent && (
          <Card>
            <div className="p-4">
              <ViewComponent />
            </div>
          </Card>
        )}

        {/* Alerts Section for Health view */}
        {selectedTile === 'health' && (
          <Card>
            <div className="p-4">
              <AlertSystem />
            </div>
          </Card>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* AI Assistant Banner */}
      <Card className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="p-4 flex items-start space-x-3">
          <SparklesIcon className="h-6 w-6 text-blue-600 mt-1" />
          <div>
            <Title className="text-blue-800">AI Insights</Title>
            <Text className="text-blue-700 mt-1">
            {dealContext 
                ? `Analysis for ${dealContext.name}`
              : '13 optimization opportunities identified across your portfolio'}
            </Text>
            <div className="mt-4 flex space-x-4">
              <Button variant="secondary" size="sm">
                View Insights
              </Button>
              <Button variant="primary" size="sm">
                Take Action
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Filter Toggle */}
      <div className="flex justify-end">
        <Button
          variant="secondary"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FunnelIcon className="h-5 w-5 mr-2" />
          Filters
        </Button>
      </div>

      {/* Hierarchy Filter - Only show when toggled */}
      {showFilters && <HierarchyFilter />}

      {/* Role-Based View Tiles */}
      <div className="mb-8">
        <Title className="mb-4">User Views</Title>
        {/* <RoleBasedTiles onSelectView={handleRoleViewSelect} /> */}
      </div>





      {/* Role Tiles - All visible but with deal context */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {Object.entries(roleViews).map(([key, role]) => (
          <Card 
            key={role.id}
            className={`cursor-pointer transform transition-all duration-200 hover:-translate-y-1 hover:shadow-lg bg-gradient-to-br ${role.gradient}`}
            onClick={() => handleRoleSelect(key)}
          >
            <div className="p-6 space-y-4">
              <div className="flex items-start justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-white rounded-lg">
                    <role.icon className="h-6 w-6 text-gray-600" />
                  </div>
                  <div>
                    <Title className="text-lg">{role.title}</Title>
                    <Text className="text-gray-600">{role.description}</Text>
                  </div>
                </div>
                {role.alerts > 0 && (
                  <Badge color="red">
                    {role.alerts} alerts
                  </Badge>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Text className="text-gray-600">{role.metrics.primary.label}</Text>
                  <div className="mt-1 flex items-baseline space-x-2">
                    <Metric>
                      {getMetricsForDeal(role.metrics.primary).value}
                    </Metric>
                    {role.metrics.primary.target && (
                      <Badge color="blue">
                        Target: {role.metrics.primary.target}
                      </Badge>
                    )}
                  </div>
                </div>
                <div>
                  <Text className="text-gray-600">{role.metrics.secondary.label}</Text>
                  <Metric>
                    {getMetricsForDeal(role.metrics.secondary).value}
                  </Metric>
                </div>
              </div>

              {role.aiInsight && (
                <div className="flex items-start space-x-2 bg-white/50 rounded-lg p-3">
                  <SparklesIcon className="h-5 w-5 text-blue-500 mt-0.5" />
                  <Text className="text-sm text-blue-700">
                    {dealContext ? role.aiInsight : role.aiInsight}
                  </Text>
                </div>
              )}
            </div>
          </Card>
        ))}
      </div>

      {/* Role View Dialog */}
      <Dialog
        open={showRoleView}
        onClose={() => setShowRoleView(false)}
        className="relative z-[70]"
      >
        <div className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-7xl bg-white rounded-xl p-6">
            <div className="absolute top-4 right-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowRoleView(false)}
              >
                ×
              </Button>
            </div>
            <div className="max-h-[80vh] overflow-y-auto">
              {SelectedViewComponent && <SelectedViewComponent />}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>




      {/* Tiles Grid */}
      <Title className="mb-4">Management Views</Title>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tiles.map((tile) => (
          <Card 
            key={tile.id}
            className={`cursor-pointer transform transition-all duration-200 hover:-translate-y-1 hover:shadow-lg bg-gradient-to-br ${tile.gradient}`}
            onClick={() => handleTileClick(tile.id)}
          >
            <div className="p-6 space-y-4">
              {/* Header */}
              <div className="flex items-start justify-between">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-white rounded-lg">
                    <tile.icon className="h-6 w-6 text-gray-600" />
                  </div>
                  <div>
                    <Title className="text-lg">{tile.title}</Title>
                    <Text className="text-gray-600">{tile.description}</Text>
                  </div>
                </div>
                {alertsByTile[tile.id]?.length > 0 && (
                  <Badge color="red">
                    {alertsByTile[tile.id].length} alerts
                  </Badge>
                )}
              </div>

              {/* Metrics */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Text className="text-sm text-gray-600">
                    {tile.metrics.primary}
                  </Text>
                </div>
                <div>
                  <Text className="text-sm text-gray-600">
                    {tile.metrics.secondary}
                  </Text>
                </div>
              </div>

              {/* AI Insight */}
              {tile.aiInsight && (
                <div className="flex items-start space-x-2 bg-white/50 rounded-lg p-3">
                  <SparklesIcon className="h-5 w-5 text-blue-500 mt-0.5" />
                  <Text className="text-sm text-blue-700">
                    {tile.aiInsight}
                  </Text>
                </div>
              )}

              {/* Action Button */}
              {/* <div className="flex justify-end">
                <Button 
                  variant="secondary" 
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTileClick(tile.id);
                  }}
                >
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 mr-1" />
                  View Details
                </Button>
              </div> */}
            </div>
          </Card>
        ))}
      </div>


      {/* Detail View Dialog */}
      <Dialog
        open={showDetailView}
        onClose={() => {
          setShowDetailView(false);
          setShowRoleView(false);
        }}
        className="relative z-[70]"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-xl bg-white p-6 shadow-xl">
              <div className="max-h-[80vh] overflow-y-auto">
                {showRoleView ? (
                  <RoleBasedViews />
                ) : (
                  renderDetailView()
                )}
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="absolute top-4 right-4"
                onClick={() => {
                  setShowDetailView(false);
                  setShowRoleView(false);
                }}
              >
                ×
              </Button>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>

    </div>
    // </div>
  );
}
