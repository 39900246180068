// src/components/deals/management/views/AccountManagerView.tsx

import { useState } from 'react';
import {
 Card,
 Title,
 Text,
 Badge,
 ProgressBar,
 AreaChart,
 DonutChart,
 Grid,
 Tab,
 TabGroup,
 TabList,
 TabPanels,
 TabPanel
} from '@tremor/react';
import {
 SparklesIcon,
 UserGroupIcon,
 BanknotesIcon,
 ChartBarIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../../ui/Button';

const accountData = {
 performance: {
   activeAccounts: 45,
   totalRevenue: 2500000,
   avgHealthScore: 88,
   qbrStatus: {
     completed: 12,
     pending: 4,
     upcoming: 8
   }
 },
 clientMetrics: {
   satisfaction: 4.5,
   retention: 92,
   dealSuccessRate: 88,
   revenueGrowth: 12
 },
 upcomingQBRs: [
   {
     client: 'AutoMax Corp',
     date: '2024-04-15',
     deals: 12,
     revenue: 850000,
     healthScore: 86
   },
   {
     client: 'TechGiant Inc',
     date: '2024-04-22',
     deals: 8,
     revenue: 620000,
     healthScore: 92
   }
 ],
 opportunities: [
   {
     id: 'OPP1',
     client: 'AutoMax Corp',
     type: 'Expansion',
     value: 250000,
     probability: 75
   },
   {
     id: 'OPP2',
     client: 'RetailPro',
     type: 'New Deal',
     value: 180000,
     probability: 60
   }
 ]
};

export function AccountManagerView() {
 return (
   <div className="space-y-6">
     {/* Key Metrics */}
     <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
       <Card>
         <div className="p-4">
           <Text>Active Accounts</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{accountData.performance.activeAccounts}</Text>
             <Badge color="green">+3 this quarter</Badge>
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>Total Revenue</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">${(accountData.performance.totalRevenue / 1000000).toFixed(1)}M</Text>
             <Badge color="green">+{accountData.clientMetrics.revenueGrowth}%</Badge>
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>Client Satisfaction</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{accountData.clientMetrics.satisfaction}/5</Text>
             <Badge color="green">Above Target</Badge>
           </div>
         </div>
       </Card>

       <Card>
         <div className="p-4">
           <Text>QBRs Due</Text>
           <div className="mt-2">
             <Text className="text-2xl font-bold">{accountData.performance.qbrStatus?.pending}</Text>
             <Badge color="yellow">This Month</Badge>
           </div>
         </div>
       </Card>
     </div>

     <TabGroup>
       <TabList>
         <Tab>Upcoming QBRs</Tab>
         <Tab>Client Performance</Tab>
         <Tab>Opportunities</Tab>
       </TabList>

       <TabPanels>
         <TabPanel>
           <div className="mt-4 space-y-4">
             {accountData.upcomingQBRs.map(qbr => (
               <Card key={qbr.client}>
                 <div className="p-4">
                   <div className="flex items-center justify-between">
                     <div>
                       <Title>{qbr.client}</Title>
                       <div className="mt-2 grid grid-cols-3 gap-4">
                         <Text>Active Deals: {qbr.deals}</Text>
                         <Text>Revenue: ${(qbr.revenue/1000).toFixed(0)}K</Text>
                         <Text>Health: {qbr.healthScore}</Text>
                       </div>
                     </div>
                     <div className="text-right">
                       <Text>{new Date(qbr.date).toLocaleDateString()}</Text>
                       <Button variant="secondary" size="sm" className="mt-2">
                         Prepare QBR
                       </Button>
                     </div>
                   </div>
                 </div>
               </Card>
             ))}
           </div>
         </TabPanel>

         <TabPanel>
           <div className="mt-4 grid grid-cols-2 gap-6">
             <Card>
               <div className="p-4">
                 <Title>Client Health Distribution</Title>
                 <DonutChart
                   className="mt-4 h-48"
                   data={[
                     { name: 'Healthy', value: 32 },
                     { name: 'Warning', value: 8 },
                     { name: 'Critical', value: 5 }
                   ]}
                   category="value"
                   index="name"
                   colors={['green', 'yellow', 'red']}
                 />
               </div>
             </Card>

             <Card>
               <div className="p-4">
                 <Title>Key Performance Indicators</Title>
                 <div className="mt-4 space-y-3">
                   {Object.entries(accountData.clientMetrics).map(([key, value]) => (
                     <div key={key}>
                       <div className="flex justify-between mb-1">
                         <Text>{key.replace(/([A-Z])/g, ' $1').trim()}</Text>
                         <Text>{typeof value === 'number' ? `${value}${value > 100 ? '' : '%'}` : value}</Text>
                       </div>
                       <ProgressBar
                         value={typeof value === 'number' ? (value > 100 ? (value/1000) * 100 : value) : 0}
                         color="blue"
                       />
                     </div>
                   ))}
                 </div>
               </div>
             </Card>
           </div>
         </TabPanel>

         <TabPanel>
           <div className="mt-4 space-y-4">
             {accountData.opportunities.map(opp => (
               <Card key={opp.id}>
                 <div className="p-4">
                   <div className="flex items-center justify-between">
                     <div>
                       <div className="flex items-center space-x-2">
                         <SparklesIcon className="h-5 w-5 text-blue-500" />
                         <Title>{opp.client}</Title>
                         <Badge color="blue">{opp.type}</Badge>
                       </div>
                       <div className="mt-2 flex items-center space-x-4">
                         <Text>Value: ${(opp.value/1000).toFixed(0)}K</Text>
                         <Text>Probability: {opp.probability}%</Text>
                       </div>
                     </div>
                     <Button variant="primary" size="sm">
                       Review
                     </Button>
                   </div>
                 </div>
               </Card>
             ))}
           </div>
         </TabPanel>
       </TabPanels>
     </TabGroup>
   </div>
 );
}