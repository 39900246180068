// src/hooks/useRoleBasedAccess.ts

import { useState, useEffect } from 'react';
import { useAlertMonitoring } from './useAlertMonitoring';

export interface RoleConfig {
    id: string;
    name: string;
    description: string;
    defaultView: string;
    permissions: string[];
    metrics: string[];
    features: string[];
  }
  
  const roleConfigs: Record<string, RoleConfig> = {
    dealOps: {
      id: 'dealOps',
      name: 'Deal Operations',
      description: 'Manage and optimize deal performance',
      defaultView: 'monitoring',
      permissions: ['create_deal', 'edit_deal', 'optimize_deal', 'run_health_check'],
      metrics: ['health_score', 'response_time', 'setup_accuracy', 'optimization_rate'],
      features: ['health_monitoring', 'optimization', 'setup_validation']
    },
    accountManager: {
      id: 'accountManager',
      name: 'Account Management',
      description: 'Monitor client performance and engagement',
      defaultView: 'performance',
      permissions: ['view_deal', 'request_optimization', 'client_reporting'],
      metrics: ['client_satisfaction', 'revenue_impact', 'deal_success_rate'],
      features: ['client_reporting', 'performance_monitoring', 'qbr_preparation']
    },
    support: {
      id: 'support',
      name: 'Technical Support',
      description: 'Handle technical setup and integrations',
      defaultView: 'health',
      permissions: ['technical_setup', 'integration_management', 'troubleshooting'],
      metrics: ['integration_health', 'system_performance', 'resolution_time'],
      features: ['technical_monitoring', 'integration_validation', 'issue_resolution']
    }
  };

interface RoleAccess {
  role: string;
  permissions: string[];
  features: string[];
  metrics: string[];
  defaultView: string;
}

export function useRoleBasedAccess(role: string) {
  const { createAlert } = useAlertMonitoring();
  const [access, setAccess] = useState<RoleAccess | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load role configuration
    const loadRoleAccess = async () => {
      setLoading(true);
      try {
        // This would typically come from an API
        const roleConfig = roleConfigs[role];
        if (roleConfig) {
          setAccess({
            role: roleConfig.id,
            permissions: roleConfig.permissions,
            features: roleConfig.features,
            metrics: roleConfig.metrics,
            defaultView: roleConfig.defaultView
          });
        }
      } catch (error) {
        console.error('Failed to load role access:', error);
        createAlert({
          type: 'system',
          severity: 'high',
          title: 'Role Access Error',
          message: 'Failed to load role configuration'
        });
      } finally {
        setLoading(false);
      }
    };

    loadRoleAccess();
  }, [role]);

  const hasPermission = (permission: string): boolean => {
    return access?.permissions.includes(permission) || false;
  };

  const hasFeature = (feature: string): boolean => {
    return access?.features.includes(feature) || false;
  };

  const canAccessMetric = (metric: string): boolean => {
    return access?.metrics.includes(metric) || false;
  };

  return {
    loading,
    access,
    hasPermission,
    hasFeature,
    canAccessMetric,
    defaultView: access?.defaultView
  };
}