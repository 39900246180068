
// src/components/smartFinder/filters/ActiveFilters.tsx
import { useState, useMemo, useCallback } from 'react';
import {
  Badge,
  Text,

} from '@tremor/react';
import {
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Button } from '../../ui/Button';

interface ActiveFilterProps {
    filters: Array<{
      field: string;
      operator: string;
      value: any;
    }>;
    onRemove: (index: number) => void;
    onClear: () => void;
  }
  
  export function ActiveFilters({ filters, onRemove, onClear }: ActiveFilterProps) {
    if (filters.length === 0) return null;
  
    return (
      <div className="px-4 py-2 border-b">
        <div className="flex items-center gap-2 flex-wrap">
          <Text className="text-sm text-gray-500">Active Filters:</Text>
          {filters.map((filter, index) => (
            <Badge
              key={`${filter.field}-${index}`}
              color="blue"
              className="flex items-center gap-1"
            >
              {filter.field}: {filter.operator} {filter.value}
              <button
                onClick={() => onRemove(index)}
                className="ml-1 hover:bg-blue-600 rounded"
              >
                <XMarkIcon className="h-3 w-3" />
              </button>
            </Badge>
          ))}
          <Button
            variant="ghost"
            size="sm"
            onClick={onClear}
          >
            Clear all
          </Button>
        </div>
      </div>
    );
  }